
import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { AccountModel } from "src/app/modules/accounts/models/account.model";
import { AppFacade } from "src/app/store/app.facade";

export const loggedUserResolver: ResolveFn<AccountModel> = () => {
    const appFacade = inject(AppFacade);

    appFacade.loadLoggedUser();
    return appFacade.loggedUser$;
}