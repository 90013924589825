import { MaterialModule } from 'src/app/shared/modules/material.module';

import { HttpClient } from '@angular/common/http';
import { httpTranslateLoader, TablesModule } from 'src/app/shared/components/tables/tables.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { BrandGeneralInfoTabComponent } from './components/brand-profile-modal/brand-general-info-tab/brand-general-info-tab.component';
import { BrandImagesTabComponent } from './components/brand-profile-modal/brand-images-tab/brand-images-tab.component';
import { BrandProfileModalComponent } from './components/brand-profile-modal/brand-profile-modal.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrandProfileComponent } from './brand-profile.component';

@NgModule({
    imports: [
        SharedComponentsModule,
        DirectivesModule,
        CommonModule,
        TablesModule,
        PipesModule,
        FormsModule,
        MaterialModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
    ],
    declarations: [
        BrandProfileComponent,
        BrandProfileModalComponent,
        BrandGeneralInfoTabComponent,
        BrandImagesTabComponent,
    ],
    exports: []
})

export class BrandModule { }