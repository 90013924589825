import { Component, Input, OnInit } from '@angular/core';
import { InvoiceModel } from '../../../models/invoice.model';

@Component({
    selector: 'app-invoice-services-tab',
    templateUrl: './invoice-services-tab.component.html',
    styleUrls: ['./invoice-services-tab.component.css']
})
export class InvoiceServicesTabComponent implements OnInit {

    @Input() data: InvoiceModel;

    constructor() { }

    ngOnInit(): void {
    }

}
