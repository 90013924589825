import { Component, Input, OnInit } from '@angular/core';
import { getValueFromObjectDecorator } from 'src/app/shared/decorators/get-value-from-object.decorator';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';

@getValueFromObjectDecorator
@Component({
    selector: 'app-table-cell',
    templateUrl: './table-cell.component.html',
    styleUrls: ['./table-cell.component.scss']
})
export class TableCellComponent implements OnInit {

    public tableCellType = TableCellType;

    @Input() cell;
    @Input() row;

    constructor() { }

    ngOnInit(): void { }

}
