import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiRequestService, apiEndpoints } from '../../../shared/services/api-request.service';
import { BaseService } from '../../../shared/services/base.service';
import { FormErrorService } from '../../../shared/services/form-error.service';
import { SettingModel } from '../models/settings.model';

@Injectable({
    providedIn: 'root'
})

export class SettingsService extends BaseService {
    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService) {
        super(_formErrorService);
    }

    getSettings$(): Observable<SettingModel[]> {
        return this.apiRequestService.submitGetRequest(apiEndpoints.settings).pipe(map(res => ([...res.data])));
    }
    getSetting$(id: number): Observable<SettingModel> {
        return this.apiRequestService.submitGetRequest(apiEndpoints.settings + `/${id}`).pipe(map(res => ({ ...res.data })));
    }

    updateSetting$(setting: SettingModel): Observable<SettingModel> {
        return this.apiRequestService.submitPutRequest(apiEndpoints.settings + '/' + setting.id, setting).pipe(map(res => ({ ...res.data })));
    }
}
