import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StockManagementsModel } from '../../models/stock-management.model';
import { StockManagementsFacade } from '../../store/stock-management.facade';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { filter, take } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StockManagementService } from '../../services/stock-management.service';
import { isNullOrUndefined } from 'src/app/shared/utils/isNullOrUndefined.utils';
@UntilDestroy()
@Component({
    selector: 'app-stock-management-modal',
    templateUrl: './stock-management-modal.component.html',
    styleUrls: ['./stock-management-modal.component.css']
})
export class StockManagementModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public isEdit = false;
    public form: FormGroup;
    public selectedStockManagement$ = this._stockManagementFacade.stockManagement$;
    public loading$ = this._stockManagementFacade.stockManagementLoading$;

    constructor(
        public dialogRef: MatDialogRef<StockManagementModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: StockManagementsModel,
        private _stockManagementService: StockManagementService,
        private _stockManagementFacade: StockManagementsFacade,
        private _formBuilder: FormBuilder,) {
        super(dialogRef, _stockManagementService);
        this.isEdit = !isNullOrUndefined(this.data?.id);
        this.data?.id && this._stockManagementFacade.loadItem(this.data.id);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.form = this._formBuilder.group({
            name: '',
            code: '',
            id: 0
        });
        this.selectedStockManagement$.pipe(filter(sm => !!sm), untilDestroyed(this)).subscribe((stockManagement: StockManagementsModel) => {
            this.form.patchValue(stockManagement);
            this.isEdit = true;
        });
    }

    ngOnDestroy(): void {
        this._stockManagementFacade.cleanItem();
    }

    saveAndClose() {
        if (this.form.valid) {
            this.onSubmit();
            this._stockManagementFacade.stockManagementSuccessResponse$.pipe(filter(res => !!res)).subscribe(data => {
                this.dialogRef.close();
                this._stockManagementFacade.setSaveAndCloseToFalse();
            })
        } else {
            this.form.markAllAsTouched();
        }
    }
    onSubmit() {
        let payload = {
            ...this.form.value,
            id: this.data?.id
        }
        !this.isEdit && delete payload.id;
        this.isEdit ? this._stockManagementFacade.updateItem(payload) : this._stockManagementFacade.createItem(payload);
    }

}
