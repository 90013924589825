export const ALLOWED_LETTERS = 'a-zâăîşșţțA-ZĂÂÎŞŢȚ';
export const ALLOWED_LETT_AND_NUM = 'a-zâăîşșţțA-ZĂÂÎŞŢȚ0-9';

export const RegexValidator = {
    REQUIRED: {
        errorMessage: 'regex.required.message'
    },
    CNP: {
        regex: /^[0-9]{13}$/,
        errorMessage: 'regex.cnp.message'
    },
    CUI: {
        regex: /^([A-Z|a-z]{2})?[0-9]{2,10}$/,
        errorMessage: 'regex.fiscal_code.message'
    },
    DOCUMENT_SERIES: {
        regex: /^[A-Za-z0-9_ -]*$/,
        errorMessage: 'regex.docSeries.message'
    },
    NR_REG_COM: {
        regex: /^(J|j|C|c|F|f)?[ ]?[0-9]{2}[/][0-9]{2,6}[/][0-9]{4}$/,
        errorMessage: 'regex.nrRegCom.message'
    },
    PERSON_NAME: {
        regex: `^[${ALLOWED_LETTERS}\s\-]{3,255}[ ]?$`,
        errorMessage: 'regex.personName.message'
    },
    ITEM_NAME: {
        regex: `^[${ALLOWED_LETT_AND_NUM}\-\_\>\<\/\ \+\(\)\.\%\#\?\!\=\|\'\"\%\&\*\(\(\r\n\),;:\/]{3,}$`,
        errorMessage: 'regex.itemName.message'
    },
    PHONE: {
        regex: /^[0-9]{10}$/,
        errorMessage: 'regex.phone.message'
    },
    EMAIL: {
        regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        errorMessage: 'regex.email.message'
    },
    NUMBER: {
        regex: /^[0-9]*$/,
        errorMessage: 'regex.number.message'
    },
    FLOAT: {
        regex: /^[0.01-9](\d?\d?\d?,?)?(\d{3}\,?)*(\.\d{1,2})?$/,
        errorMessage: 'regex.float.message'
    },
    VALUE: {
        regex: /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/,
        errorMessage: 'regex.value.message'
    },
    QUANTITY: {
        regex: /^[1-9][0-9]*$/,
        errorMessage: 'regex.quantity.message'
    },
    USERNAME: {
        regex: `^[${ALLOWED_LETTERS}]*$`,
        errorMessage: 'regex.username.message'
    },
    LINKEDIN: {
        regex: /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/,
        errorMessage: 'regex.linkedin.message'
    },
    WEBSITE: {
        regex: /((http|https):\/\/)?(www.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        errorMessage: 'regex.website.message'
    }
} as const;
