<div mat-mdc-dialog-content class="mh-100">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" appFormValidator>
        <div class="form-row">
            <div class="col-md-12">
                <mat-form-field class="customized">
                    <mat-label for="name">{{ 'Name' | translate }}:</mat-label>
                    <input type="text" matInput id="name" autocomplete="off" formControlName="name">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['name']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="col-12 col-md-4">
                <mat-form-field class="customized">
                    <mat-label for="city">{{ 'City' | translate }}:</mat-label>
                    <input type="text" matInput id="city" autocomplete="off" formControlName="city">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['city']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-8">
                <mat-form-field class="customized">
                    <mat-label for="address">{{ 'Address' | translate }}:</mat-label>
                    <input type="text" matInput id="address" autocomplete="off" formControlName="address">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['address']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="col-12 col-md-4">
                <mat-form-field class="customized">
                    <mat-label for="phone_fax">{{ 'PhoneFax' | translate }}:</mat-label>
                    <input type="text" matInput id="phone_fax" autocomplete="off" formControlName="phone_fax">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['phone_fax']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field class="customized">
                    <mat-label for="mobile1">{{ 'Mobile1' | translate }}:</mat-label>
                    <input type="text" matInput id="mobile1" autocomplete="off" formControlName="mobile1">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['mobile1']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field class="customized">
                    <mat-label for="mobile2">{{ 'Mobile2' | translate }}:</mat-label>
                    <input type="text" matInput id="mobile2" autocomplete="off" formControlName="mobile2">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['mobile2']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="col-12 col-md-4">
                <mat-form-field class="customized">
                    <mat-label for="email1">{{ 'Email1' | translate }}:</mat-label>
                    <input type="text" matInput id="email1" autocomplete="off" formControlName="email1">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['email1']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field class="customized">
                    <mat-label for="email2">{{ 'Email2' | translate }}:</mat-label>
                    <input type="text" matInput id="email2" autocomplete="off" formControlName="email2">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['email2']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field class="customized">
                    <mat-label for="website">{{ 'Website' | translate }}:</mat-label>
                    <input type="text" matInput id="website" autocomplete="off" formControlName="website">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['website']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="col-12 col-md-6">
                <mat-form-field class="customized">
                    <mat-label for="bank">{{ 'Bank' | translate }}:</mat-label>
                    <input type="text" matInput id="bank" autocomplete="off" formControlName="bank">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['bank']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="customized">
                    <mat-label for="bank_account">{{ 'BankAccount' | translate }}:</mat-label>
                    <input type="text" matInput id="bank_account" autocomplete="off" formControlName="bank_account">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['bank_account']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="col-12 col-md-6">
                <mat-form-field class="customized">
                    <mat-label for="fiscal_code">{{ 'Cui' | translate }}:</mat-label>
                    <input type="text" matInput id="fiscal_code" autocomplete="off" formControlName="fiscal_code">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['fiscal_code']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="customized">
                    <mat-label for="chamber_of_commerce_code">{{ 'NoCommerceReg' | translate }}:</mat-label>
                    <input type="text" matInput id="chamber_of_commerce_code" autocomplete="off"
                        formControlName="chamber_of_commerce_code">
                    <mat-error>
                        <app-form-field-error
                            [control]="form.controls['chamber_of_commerce_code']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div mat-dialog-actions class="mb-0">
            <div class="w-100 modal-footer border-0 p-0">
                <button type="button" class="btn btn-secondary" (click)="closeModal.emit()">{{ 'Close' | translate
                    }}</button>
                <button type="button" class="btn btn-primary" (click)="saveAndExit()">{{ 'SaveAndClose' | translate
                    }}</button>
                <button class="btn btn-primary">{{ 'Save' | translate }}</button>
            </div>
        </div>
    </form>
</div>