import { HttpErrorResponse } from "@angular/common/http";
import { createAction } from "@ngrx/store";
import { RoleModel } from "src/app/modules/roles/models/role.model";


export const CLEAN_STATE = createAction('[Roles] Clean state');
export const CLEAN_ITEM = createAction('[Roles] Clean item');

export const LOAD_LIST = createAction('[Roles] Load list');
export const LOAD_LIST_SUCCESS = createAction('[Roles] Load list success', (payload: RoleModel[]) => ({ payload }));
export const LOAD_LIST_FAILURE = createAction('[Roles] Load list fail', (error: HttpErrorResponse) => ({ error }));

export const LOAD_ITEM = createAction('[Roles] Load item', (id: number) => ({ id }));
export const LOAD_ITEM_SUCCESS = createAction('[Roles] Load item success', (payload: RoleModel) => ({ payload }));
export const LOAD_ITEM_FAILURE = createAction('[Roles] Load item fail', (error: HttpErrorResponse) => ({ error }));

export const CREATE = createAction('[Roles] Create item', (payload: Partial<RoleModel>) => ({ payload }));
export const CREATE_SUCCESS = createAction('[Roles] Create item success', (payload: RoleModel) => ({ payload }));
export const CREATE_FAILURE = createAction('[Roles] Create item fail', (error: HttpErrorResponse) => ({ error }));

export const UPDATE = createAction('[Roles] Update item', (payload: RoleModel) => ({ payload }));
export const UPDATE_SUCCESS = createAction('[Roles] Update item success', (payload: RoleModel) => ({ payload }));
export const UPDATE_FAILURE = createAction('[Roles] Update item fail', (error: HttpErrorResponse) => ({ error }));

export const DELETE = createAction('[Roles] Delete item', (id: number) => ({ id }));
export const DELETE_SUCCESS = createAction('[Roles] Delete item success', (id: number) => ({ id }));
export const DELETE_FAILURE = createAction('[Roles] Delete item fail', (error: HttpErrorResponse) => ({ error }));