export enum PermissionsEnum {
    CAN_BE_EDITED = 'can_be_edited',
    CAN_BE_DELETED = 'can_be_deleted',
    CAN_BE_PRINTED = 'can_be_printed',
    CAN_COLLECT_PAYMENT = 'can_collect_payment',
    CAN_BE_REVERSED = 'can_be_reversed',

    VIEW_CUSTOMERS_LIST = 'customers_list.view',
    VIEW_CUSTOMERS_FORM = 'customers_form.view',
    CUSTOMERS_READ = 'customers.read',
    CUSTOMERS_ADD_AND_EDIT = 'customers.add_and_edit',
    CUSTOMERS_DELETE = 'customers.delete',


    VIEW_OWNERS_LIST = 'owners_list.view',
    VIEW_OWNERS_FORM = 'owners_form.view',
    OWNERS_READ = 'owners.read',
    OWNERS_ADD_AND_EDIT = 'owners.add_and_edit',
    OWNERS_DELETE = 'owners.delete',

    VIEW_ROLES_LIST = 'roles.view',
    VIEW_ROLES_FORM = 'roles_form.view',
    ROLES_READ = 'roles.read',
    ROLES_ADD_AND_EDIT = 'roles.add_and_edit',
    ROLES_DELETE = 'roles.delete',

    VIEW_LOCATIONS_LIST = 'locations_list.view',
    VIEW_LOCATIONS_FORM = 'locations_form.view',
    LOCATIONS_READ = 'locations.read',
    LOCATIONS_ADD_AND_EDIT = 'locations.add_and_edit',
    LOCATIONS_DELETE = 'locations.delete',


    VIEW_RESERVATIONS_LIST = 'reservations_list.view',
    VIEW_RESERVATIONS_FORM = 'reservations_form.view',
    RESERVATIONS_READ = 'reservations.read',
    RESERVATIONS_ADD_AND_EDIT = 'reservations.add_and_edit',
    RESERVATIONS_DELETE = 'reservations.delete',


    VIEW_SERVICES_LIST = 'services_list.view',
    VIEW_SERVICES_FORM = 'services_form.view',
    SERVICES_READ = 'services.read',
    SERVICES_ADD_AND_EDIT = 'services.add_and_edit',
    SERVICES_DELETE = 'services.delete',

    VIEW_SESSIONS_LIST = 'sessions_list.view',
    VIEW_SESSIONS_FORM = 'sessions_form.view',
    SESSIONS_READ = 'sessions.read',
    SESSIONS_ADD_AND_EDIT = 'sessions.add_and_edit',
    SESSIONS_DELETE = 'sessions.delete',

    VIEW_SESSION_SERVICES_LIST = 'session_services_list.view',
    VIEW_SESSION_SERVICES_FORM = 'session_services_form.view',
    SESSION_SERVICES_READ = 'session_services.read',
    SESSION_SERVICES_ADD_AND_EDIT = 'session_services.add_and_edit',
    SESSION_SERVICES_DELETE = 'session_services.delete',

    SESSIONS_FINISH = 'sessions.finish',

    BRAND_PROFILE_READ = 'brand_profile.read',
    BRAND_PROFILE_ADD_AND_EDIT = 'brand_profile.add_and_edit',
    BRAND_PROFILE_LIST_VIEW = 'brand_profile_list.view',
    BRAND_PROFILE_FORM_VIEW = 'brand_profile_form.view',

    BRAND_INVOICE_PROFILE_READ = 'brand_invoice_profile.read',
    BRAND_INVOICE_PROFILE_ADD_AND_EDIT = 'brand_invoice_profile.add_and_edit',
    BRAND_INVOICE_PROFILE_DELETE = 'brand_invoice_profile.delete',


    SETTINGS_READ = 'settings.read',
    SETTINGS_ADD_AND_EDIT = 'settings.add_and_edit',
    SETTINGS_LIST_VIEW = 'settings_list.view',
    SETTINGS_FORM_VIEW = 'settings_form.view',

    PERMISSIONS_CHANGE = 'permissions.change',

    VIEW_INVOICES_LIST = "invoices_list.view",
    VIEW_INVOICES_FORM = "invoices_form.view",
    INVOICES_READ = 'invoices.read',
    INVOICES_ADD_AND_EDIT = 'invoices.add_and_edit',

    INVOICE_PAYMENTS_READ = 'invoice_payments.read',
    INVOICE_PAYMENTS_ADD_AND_EDIT = 'invoice_payments.add_and_edit',
    INVOICE_PAYMENTS_DELETE = 'invoice_payments.delete',

    INVOICE_SERIES_READ = 'invoice_series.read',
    INVOICE_SERIES_CREATE = 'invoice_series.create',
    INVOICE_SERIES_UPDATE = 'invoice_series.update',

    USERS_READ = 'users.read',
    USERS_ADD_AND_EDIT = 'users.add_and_edit',
    USERS_DELETE = 'users.delete',
    USERS_LIST_VIEW = 'users_list.view',
    USERS_FORM_VIEW = 'users_form.view',

    STOCK_MANAGEMENTS_READ = 'stock_managements.read',
    STOCK_MANAGEMENTS_ADD_AND_EDIT = 'stock_managements.add_and_edit',
    STOCK_MANAGEMENTS_DELETE = 'stock_managements.delete',
    STOCK_MANAGEMENTS_LIST_VIEW = 'stock_managements_list.view',
    STOCK_MANAGEMENTS_FORM_VIEW = 'stock_managements_form.view',

    INVENTORIES_READ = 'inventories.read',
    INVENTORIES_ADD_AND_EDIT = 'inventories.add_and_edit',
    INVENTORIES_DELETE = 'inventories.delete',
    INVENTORIES_LIST_VIEW = 'inventories_list.view',
    INVENTORIES_FORM_VIEW = 'inventories_form.view',

    PRODUCTS_READ = 'products.read',
    PRODUCTS_ADD_AND_EDIT = 'products.add_and_edit',
    PRODUCTS_LIST_VIEW = 'products_list.view',
    PRODUCTS_FORM_VIEW = 'products_form.view',

    INVENTORY_PRODUCTS_READ = 'inventory_products.read',
    INVENTORY_PRODUCTS_ADD_AND_EDIT = 'inventory_products.add_and_edit',

    SELF_PROFILE_READ = 'self.profile.read',
    SELF_PROFILE_UPDATE = 'self.profile.update',

    VIEW_EXPENSE_CATEGORIES_LIST = 'expense_categories_list.view',
    VIEW_EXPENSE_CATEGORIES_FORM = 'expense_categories_form.view',
    EXPENSE_CATEGORIES_READ = 'expense_categories.read',
    EXPENSE_CATEGORIES_ADD_AND_EDIT = 'expense_categories.add_and_edit',
    EXPENSE_CATEGORIES_DELETE = 'expense_categories.delete',

    VIEW_EXPENSES_LIST = 'expenses_list.view',
    VIEW_EXPENSES_FORM = 'expenses_form.view',
    EXPENSES_READ = 'expenses.read',
    EXPENSES_ADD_AND_EDIT = 'expenses.add_and_edit',
    EXPENSES_DELETE = 'expenses.delete',

    INTERFACE_ACCESS_DASHBOARD = 'interface.access_dashboard',
    INTERFACE_ACCESS_PERMISSIONS = 'interface.access_permisions',

    PAYMENT_DOCUMENTS_READ = 'payment_documents.read',
    PAYMENT_DOCUMENTS_ADD_AND_EDIT = 'payment_documents.add_and_edit',
    PAYMENT_DOCUMENTS_DELETE = 'payment_documents.delete',

    REPORTS_READ = 'reports.read',
    QR_CODES_READ = 'qr_codes.read',
}
