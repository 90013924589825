import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserStatusesModel } from 'src/app/modules/accounts/models/account-statuses.model';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { CURRENCY } from 'src/app/shared/constants';
import { RegularTableCellType } from 'src/app/shared/enums/RegularTableCellType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { isMobile } from 'src/app/shared/utils/responsive.utils';
import { AppFacade } from 'src/app/store/app.facade';
import { TableData } from '../../md/md-table/md-table.component';
import { TranslateService } from '@ngx-translate/core';


@UntilDestroy()
@Component({
    selector: 'app-regular-table',
    templateUrl: 'regular-table.component.html',
    styleUrls: ['./regular-table.component.scss']
})

export class RegularTableComponent implements OnInit, OnChanges {
    public readonly pageSizeOptions: number[] = [10, 25, 50, 100];
    public tableData: TableData;
    public userStatuses$ = this._appFacade.userStatuses$;
    public regularTableCellType = RegularTableCellType;
    public pageEvent: PageEvent;
    public currency = CURRENCY;
    public userStatuses: UserStatusesModel[];
    public lengthOptions: number[];
    public selectPageCtrl = new FormControl(0);
    public isMobile = isMobile();
    public initialPagination = {
        pageSize: 1,
        length: 1,
        pageIndex: 1,
        lastPage: 1,
        lengthOptions: [1]
    }

    @Input() hideIndex: number;
    @Input() path: string;
    @Input() headerRow: string[];
    @Input() rowPossibleActions: RowPossibleActions[];
    @Input() icon: string;
    @Input() tableId: string;
    @Input() totals: any;
    @Input() pagination: Omit<PaginationModel, 'data'>;
    @Input() loading: boolean;
    @Input() dataList: any[];
    @Input() deletedItem: string;

    @Output() onPageChanged = new EventEmitter<PageEvent>();
    @Output() actionType = new EventEmitter<any>();

    @ViewChild('paginator') paginator: MatPaginator;

    constructor(
        private _router: Router,
        private _appFacade: AppFacade,
        private _userProfileService: UserProfileService,
        private _translate: TranslateService,
    ) { }

    ngOnInit() {
        this.tableData = {
            headerRow: this.headerRow,
            dataRows: []
        };

        this.selectPageCtrl.valueChanges.subscribe(value => this.onPageChanged.emit({
            pageSize: Number(this.pagination?.per_page),
            pageIndex: value - 1,
            length: this.pagination?.total
        }));
        this._translate.onLangChange.pipe(untilDestroyed(this)).subscribe(res => {
            this.paginator._intl.itemsPerPageLabel = this._translate.instant('ITEMS_PER_PAGE_LABEL');
            this.paginator._intl.nextPageLabel = this._translate.instant('NEXT_PAGE_LABEL');
            this.paginator._intl.previousPageLabel = this._translate.instant('PREVIOUS_PAGE_LABEL');
            this.paginator['_intlChanges']._next(undefined);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const pag = changes.pagination?.currentValue;
        if (pag) {
            pag?.last_page && (this.lengthOptions = Array(pag.last_page).fill('').map((q, i) => i + 1));
            pag?.current_page && this.selectPageCtrl.setValue(pag.current_page, { emitEvent: false });
        }

        if (!this.pagination?.current_page) {
            this.initialPagination.length = this.dataList?.length;
            this.selectPageCtrl.setValue(1, { emitEvent: false });
        }

        if (changes.dataList?.currentValue) {
            this.tableData && (this.tableData.dataRows = changes.dataList.currentValue);
        }
    }

    goToLink(path: string, id: number) {
        this._router.navigate([path + id]);
    }

    openDialogFromField(index: number, rowPossibleActions: RowPossibleActions[], rowAvailableActions?: any) {
        let permissionName = ' ';
        const defaultAction = rowPossibleActions.find(action => action.default);
        const type = defaultAction ? defaultAction.type : rowPossibleActions[0].type;

        if (rowAvailableActions) {
            Object.keys(rowAvailableActions).forEach(action => {
                if (action === 'can_be_edited' && rowAvailableActions[action]) {
                    this.tableActionClicked({ index, type });
                }
            });
            return;
        }

        this.rowPossibleActions.forEach(permission => {
            if (permission.name === 'Edit' || permission.name === 'View') {
                permissionName = permission.permission;
            }
        });

        if (this._userProfileService.checkForPermission(permissionName)) {
            this.tableActionClicked({ index, type });
        }
    }

    tableActionClicked(event) {
        this.actionType.emit({ type: event.type, entry: this.tableData.dataRows[event.index] });
    }
}
