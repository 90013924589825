<div class="container-fluid mb-4" *ngIf="invoiceServicesList?.length; else noService">
    <div class="row subheader-title pb-2 text-uppercase">
        <div class="col-1 text-center small">{{ "Index" | translate }}</div>
        <div class="col-3 small">{{ "Name" | translate }}</div>
        <div class="col-2 small">{{ "MeasurementUnit" | translate }}</div>
        <div class="col-2 text-right small">{{ "Quantity" | translate }}</div>
        <div class="col-1 text-right small">{{ "VAT" | translate }} (%)</div>
        <div class="col-2 text-right small">{{ "UnitaryPrice" | translate }} ({{ "WithoutVat" | translate }})</div>
        <div class="col-1 text-right small">{{ "Actions" | translate }}</div>
    </div>

    <ng-container *ngFor="let service of invoiceServicesList; index as i">
        <div class="row service-cost-row" [appStrippedTable]="i">
            <div class="col-1 text-center small">{{ i + 1 }}</div>
            <div class="col-3 small">{{ service.name }}</div>
            <div class="col-2 small">{{ service.measurement_unit }}</div>
            <div class="col-2 text-right small">{{ service.quantity | number : '1.2-2' }}</div>
            <div class="col-1 text-right small">{{ service.vat }}</div>
            <div class="col-2 text-right small ">{{ service.unitary_price_including_vat | number : '1.2-2' }}</div>
            <div class="col-1 text-right small">
                <button class="btn btn-danger small-btn" (click)="deleteService(service)">
                    <i class="material-icons">delete</i>
                </button>
            </div>
        </div>
    </ng-container>

    <div class="row total-row">
        <div class="col-6">{{ "Total" | translate }}</div>
        <div class="col-6 text-right">
            {{ totalValue | number : '1.2-2' }} {{ data.invoice_series?.currency }}
        </div>
    </div>
</div>

<ng-template #noService>
    <app-no-item-to-show [message]="'NoServiceFound'"></app-no-item-to-show>
</ng-template>