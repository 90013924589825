import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RolesFacade } from 'src/app/modules/roles/store/roles.facade';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { RolesModalComponent } from './components/roles-modal/roles-modal.component';

@UntilDestroy()
@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

    public roles$ = this._rolesFacade.roles$;
    public loading$ = this._rolesFacade.rolesLoading$;
    public PermissionsEnum = PermissionsEnum;

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Name',
            fieldNames: ['name'],
            cssClasses: 'all',
            sorting: true
        }
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.ROLES_ADD_AND_EDIT,
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.ROLES_DELETE,
        }
    ];

    constructor(
        private _rolesFacade: RolesFacade,
        private _dialogService: DialogService,
        public sweetAlertService: SweetAlertService
    ) { }

    ngOnInit(): void {
        this._rolesFacade.loadList();
    }

    tableActionClicked(e: any) {
        if (e.type === 'EDIT') {
            this._dialogService.openModal(e.entry, RolesModalComponent, DimensionsEnum.AUTO, '80vw');
        }

        if (e.type === 'DELETE') {
            const item = 'Role';
            this.sweetAlertService.showSwal('warning-message', () => this.deleteEntry(e.entry.id), item);
        }
    }

    addEntry() {
        this._dialogService.openModal(null, RolesModalComponent, DimensionsEnum.AUTO, '80vw');
    }

    private deleteEntry(id: number) {
        this._rolesFacade.deleteItem(id);
    }
}
