import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InventoryFacade } from '../../inventories/store/inventory.facade';
import { BrandProfileModel } from '../../brand-profile/models/brand-profile.model';
import { LangService } from 'src/app/shared/services/lang.service';
import { utcConvert } from 'src/app/shared/utils/utils';
import { isMobile } from 'src/app/shared/utils/responsive.utils';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { AppFacade } from 'src/app/store/app.facade';
import { InventoryProductModel } from '../../inventories/models/inventory.model';
@UntilDestroy()
@Component({
    selector: 'app-product-qr-scan',
    templateUrl: './product-qr-scan.component.html',
    styleUrls: ['./product-qr-scan.component.css'],

})
export class ProductQrScanComponent implements OnInit, OnDestroy {
    public inventoryProduct$ = this._inventoryFacade.inventoryProduct$;
    public inventoryProducts$ = this._inventoryFacade.inventoryProducts$;
    public loading$ = this._inventoryFacade.inventoryProductsLoading$;
    public id: number;
    public currentBrand: BrandProfileModel;
    public totalScanned = 0;
    public totalProducts = 0;
    public utcConvert = utcConvert;
    public inventoryId: number;
    public isMobile = isMobile();
    public userProductScan: InventoryProductModel;

    constructor(private _route: ActivatedRoute,
        private _inventoryFacade: InventoryFacade,
        public translateService: TranslateService,
        public langService: LangService,
        private _appFacade: AppFacade
    ) {
    }

    ngOnInit(): void {
        this._route.params.pipe(untilDestroyed(this)).subscribe(params => {
            const id = parseInt(params['id']);
            if (this.id !== id) this._inventoryFacade.scanProduct(id, null);
            this.id = id;
            this._appFacade.setLastScannedProductId(id);
        });

        this.inventoryProduct$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this._inventoryFacade.getInventoryProducts({ id: data.inventory_product.inventory_id })
        })

        this.inventoryProducts$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.totalProducts = data.length;
            this.totalScanned = data.filter(entry => entry.scanned_at).length;
            this.userProductScan = data.filter(entry => entry.id === this.id)[0];
        })
    }

    ngOnDestroy() {
        this._inventoryFacade.cleanState();
    }

    updateProductStatus(id, status) {
        this._inventoryFacade.scanProduct(id, status);
    }
}
