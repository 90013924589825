import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserStatusesModel } from 'src/app/modules/accounts/models/account-statuses.model';
import { AccountModel } from 'src/app/modules/accounts/models/account.model';
import { RoleModel } from 'src/app/modules/roles/models/role.model';
import { AppFacade } from 'src/app/store/app.facade';
import { PermissionsEnum } from '../../enums/Permissions.enum';
@UntilDestroy()
@Component({
    selector: 'app-user-account-fields',
    templateUrl: './user-account-fields.component.html',
    styleUrls: ['./user-account-fields.component.css']
})
export class UserAccountFieldsComponent implements OnInit {
    private _account: AccountModel;

    public form: FormGroup;
    public PermissionsEnum: PermissionsEnum;

    @Input() hideRoleFields: boolean;
    @Input() rolesDropdownValues: Partial<RoleModel[]>;
    @Input() userStatuses: UserStatusesModel[];
    @Input() set selectedAccount(val: AccountModel) {
        this._account = val;
        this.form && this.form.patchValue({ ...val, roles_ids: (val?.roles_ids ? val.roles_ids[0] : null) });
    };

    get selectedAccount() {
        return this._account;
    };

    @Output() updateUserForm: EventEmitter<FormGroup> = new EventEmitter();

    constructor(private _formBuilder: FormBuilder, private _appFacade: AppFacade) { }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            id: this.selectedAccount?.id || '',
            first_name: this.selectedAccount?.first_name || '',
            last_name: this.selectedAccount?.last_name || '',
            username: this.selectedAccount?.username || '',
            password: this.selectedAccount?.password || '',
            password_confirmation: this.selectedAccount?.password_confirmation || '',
            roles: this.selectedAccount?.roles ? this.selectedAccount?.roles[0] : null,
            roles_ids: this.selectedAccount?.roles_ids[0] || this.rolesDropdownValues && this.rolesDropdownValues[0]?.id,
            status: this.selectedAccount?.status || this.userStatuses[0].value,
            mobile: this.selectedAccount?.mobile || '',
            email: this.selectedAccount?.email || '',
        });

        this.updateUserForm.emit(this.form);
        this.form.valueChanges.subscribe(() => this.updateUserForm.emit(this.form));
    }
}
