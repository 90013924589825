<app-user-account-fields (updateUserForm)="updateUserForm($event)"
                         [selectedAccount]="selectedAccount"
                         [rolesDropdownValues]="rolesDropdownValues$ | async"
                         [userStatuses]="userStatuses$ | async">
</app-user-account-fields>

<app-dialog-footer-buttons class="w-100"
                           (close)="closeModal.emit()"
                           (saveAndClose)="saveAndClose()"
                           (save)="onSubmit()">
</app-dialog-footer-buttons>