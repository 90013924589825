import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// export const apiUrl = `https://boilerplate-api.codern.ro/api/v1/ro/`;


function getDomain(): string {
    let [subdomain, ...rest] = window.origin.split('https://')[1].split('.');
    let requestSubdomain = subdomain + '-api';
    return window.origin.split('https://')[1].replace(subdomain, requestSubdomain);
}
const localHostDomain = 'dev-inventory-api.codern.ro'
export var domainName = window.origin.includes('localhost') ? localHostDomain : getDomain();
export const apiUrl = `https://${domainName}/api/v1/`;

export const apiEndpoints = {

    accounts: apiUrl + 'users',

    brandInvoiceProfiles: apiUrl + 'brand-invoice-profiles',
    brandProfile: apiUrl + 'brand-profile',
    brandProfileAdditionalDetails: apiUrl + 'brand-profile-additional-details',
    brandProfileLogoAndCover: apiUrl + 'brand-profile-logo-and-cover',
    brandProfileSettings: apiUrl + 'brand-profile-settings',

    constraints: apiUrl + 'constraints',
    owners: apiUrl + 'owners',
    ownerServices: apiUrl + 'owner-services',
    ownerInvoiceProfiles: apiUrl + 'owner-invoice-profiles',
    defaultOwnerInvoiceProfile: apiUrl + 'default-owner-invoice-profile',
    ownerProfilePicture: apiUrl + 'owner-profile-photo',

    defaultBrandInvoiceProfiles: apiUrl + 'default-brand-invoice-profile',

    emailResetLink: apiUrl + 'email-reset-link',
    enumValues: apiUrl + 'enum-values',

    invoicePayments: apiUrl + 'invoice-payments',
    invoiceSeries: apiUrl + 'invoice-series',
    invoices: apiUrl + 'invoices',
    invoiceServices: apiUrl + 'invoice-services',

    inventories: apiUrl + 'inventories',
    importFormat: apiUrl + 'import-format',
    inventoryProducts: apiUrl + 'inventory-products',

    login: apiUrl + 'login',
    logout: apiUrl + 'logout',

    locations: apiUrl + 'locations',
    locationServices: apiUrl + 'location-services',

    permission: apiUrl + 'permission',
    permissions: apiUrl + 'permissions-list',
    permissionsDetailed: apiUrl + 'permissions-detailed',

    products: apiUrl + 'products',
    reservations: apiUrl + 'reservations',
    reservationsGraphicalRepresentation: apiUrl + 'reservations-graphical-representation',

    recordRegisterReport: apiUrl + 'reports/record-register',
    refreshToken: apiUrl + 'refresh',
    resetPassword: apiUrl + 'reset-password',
    roles: apiUrl + 'roles',

    stockManagements: apiUrl + 'stock-managements',

    salesReport: apiUrl + 'sales-report',
    services: apiUrl + 'services',
    servicesCategories: apiUrl + 'services-categories',
    servicesTypes: apiUrl + 'services-types',
    sessions: apiUrl + 'sessions',
    overdueSessions: apiUrl + 'overdue-sessions',
    ownerSessionsReport: apiUrl + 'reports/owner-sessions',
    sessionServices: apiUrl + 'session-services',
    providedSessionServices: apiUrl + 'provided-session-services',
    settings: apiUrl + 'settings',
    userProfile: apiUrl + 'user',
    userProfilePicture: apiUrl + 'user-profile-photo',
    userStatuses: apiUrl + 'user-statuses',
}

export interface ApiResponse {
    success: boolean;
    data: object[] | object;
}

export interface ErrorResponse {
    message: string;
}

@Injectable({
    providedIn: 'root'
})

export class ApiRequestService {

    constructor(private _httpClient: HttpClient) { }

    submitGetRequest(url: string, payload?: object): Observable<ApiResponse | any> {
        return this._httpClient.get<ApiResponse>(url, payload);
    }

    submitPostRequest(url: string, payload?: object, headers?: object): Observable<ApiResponse | any> {
        return this._httpClient.post<any>(url, payload, headers);
    }

    submitPutRequest(url: string, payload?: object): Observable<ApiResponse | any> {
        return this._httpClient.put<any>(url, payload);
    }

    submitDeleteRequest(url: string, options?: object): Observable<ApiResponse | any> {
        return this._httpClient.delete<any>(url, options);
    }
}
