import { createReducer, on } from "@ngrx/store";
import { produce } from "immer";
import * as fromActions from "./app.actions";
import * as fromState from "./app.state";

export const appReducer = createReducer(
    fromState.appInitialState,
    on(
        fromActions.CLEAN_STATE,
        () => fromState.adapter.getInitialState(fromState.appInitialState)
    ),
    on(
        fromActions.SET_LOGGED_USER,
        (state, action) => produce(state, (draft) => {
            draft.isAuthenticated = true;
            draft.loggedUser.account = action.payload;
            draft.loggedUser.error = null;
            draft.loggedUser.loading = false;
        })
    ),
    on(
        fromActions.LOAD_LOGGED_USER,
        (state) => produce(state, (draft) => {
            draft.loggedUser.account = undefined;
            draft.loggedUser.error = null;
            draft.loggedUser.loading = true;
        })
    ),
    on(
        fromActions.LOAD_LOGGED_USER_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.isAuthenticated = true;
            draft.loggedUser.account = action.payload;
            draft.loggedUser.error = null;
            draft.loggedUser.loading = false;
        })
    ),
    on(
        fromActions.LOAD_LOGGED_USER_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.isAuthenticated = false;
            draft.loggedUser.account = undefined;
            draft.loggedUser.error = action.error.error;
            draft.loggedUser.loading = false;
        })
    ),
    on(
        fromActions.UPDATE_LOGGED_USER,
        (state) => produce(state, (draft) => {
            draft.loggedUser.error = null;
            draft.loggedUser.loading = true;
        })
    ),
    on(
        fromActions.UPDATE_LOGGED_USER_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.isAuthenticated = true;
            draft.loggedUser.account = action.payload;
            draft.loggedUser.error = null;
            draft.loggedUser.loading = false;
        })
    ),
    on(
        fromActions.UPDATE_LOGGED_USER_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.isAuthenticated = false;
            draft.loggedUser.account = undefined;
            draft.loggedUser.error = action.error.error;
            draft.loggedUser.loading = false;
        })
    ),
    on(
        fromActions.LOAD_USER_STATUSES,
        (state) => produce(state, (draft) => {
            draft.dropdownValues.userStatuses.list = undefined;
            draft.dropdownValues.userStatuses.error = null;
        })
    ),
    on(
        fromActions.LOAD_USER_STATUSES_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.dropdownValues.userStatuses.list = action.payload;
            draft.dropdownValues.userStatuses.error = null;
        })
    ),
    on(
        fromActions.LOAD_USER_STATUSES_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.dropdownValues.userStatuses.list = undefined;
            draft.dropdownValues.userStatuses.error = action.error.error;
        })
    ),
    on(
        fromActions.LOAD_ROLES_DROPDOWN_VALUES,
        (state) => produce(state, (draft) => {
            draft.dropdownValues.roles.list = undefined;
            draft.dropdownValues.roles.error = null;
        })
    ),
    on(
        fromActions.LOAD_ROLES_DROPDOWN_VALUES_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.dropdownValues.roles.list = action.payload;
            draft.dropdownValues.roles.error = null;
        })
    ),
    on(
        fromActions.LOAD_ROLES_DROPDOWN_VALUES_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.dropdownValues.roles.list = undefined;
            draft.dropdownValues.roles.error = action.error.error;
        })
    ),
    on(
        fromActions.LOAD_ACCOUNTS_DROPDOWN_VALUES,
        (state) => produce(state, (draft) => {
            draft.dropdownValues.accounts.list = undefined;
            draft.dropdownValues.accounts.error = null;
        })
    ),
    on(
        fromActions.LOAD_ACCOUNTS_DROPDOWN_VALUES_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.dropdownValues.accounts.list = action.payload;
            draft.dropdownValues.accounts.error = null;
        })
    ),
    on(
        fromActions.LOAD_ACCOUNTS_DROPDOWN_VALUES_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.dropdownValues.accounts.list = undefined;
            draft.dropdownValues.accounts.error = action.error.error;
        })
    ),
    on(
        fromActions.LOAD_STOCK_MANAGEMENTS_DROPDOWN_VALUES,
        (state) => produce(state, (draft) => {
            draft.dropdownValues.stockManagements.error = null;
        })
    ),
    on(
        fromActions.LOAD_STOCK_MANAGEMENTS_DROPDOWN_VALUES_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.dropdownValues.stockManagements.list = action.payload;
            draft.dropdownValues.stockManagements.error = null;
        })
    ),
    on(
        fromActions.LOAD_STOCK_MANAGEMENTS_DROPDOWN_VALUES_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.dropdownValues.stockManagements.list = undefined;
            draft.dropdownValues.stockManagements.error = action.error.error;
        })
    ),
    on(
        fromActions.LOAD_INVENTORIES_DROPDOWN_VALUES,
        (state) => produce(state, (draft) => {
            draft.dropdownValues.inventories.error = null;
        })
    ),
    on(
        fromActions.LOAD_INVENTORIES_DROPDOWN_VALUES_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.dropdownValues.inventories.list = action.payload;
            draft.dropdownValues.inventories.error = null;
        })
    ),
    on(
        fromActions.LOAD_INVENTORIES_DROPDOWN_VALUES_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.dropdownValues.inventories.list = undefined;
            draft.dropdownValues.inventories.error = action.error.error;
        })
    ),
    on(
        fromActions.SET_LANG,
        (state, action) => produce(state, (draft) => {
            draft.lang = action.lang;
        })
    ),
    on(
        fromActions.SET_LAST_SCANNED_PRODUCT_ID,
        (state, action) => produce(state, (draft) => {
            draft.lastScannedProductId = action.id;
        })
    ),
    on(
        fromActions.LOGOUT,
        (state) => produce(state, (draft) => {
            draft.isAuthenticated = false;
            draft.loggedUser.account = undefined;
        })
    )
)