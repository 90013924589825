<section class="table-list-section child-dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-profile pb-3">
                    <div class="card-avatar">
                        <img src="{{ currentBrandProfile?.logo }}" class="img"
                            *ngIf="currentBrandProfile?.logo; else fallbackLogo">
                        <ng-template #fallbackLogo>
                            <img src="../../../assets/img/placeholder.jpg" alt="img">
                        </ng-template>
                    </div>
                    <button class="btn btn-primary edit-button" (click)="openBrandProfileModal()"
                        [appRestrictPermissions]="PermissionsEnum.BRAND_PROFILE_ADD_AND_EDIT">
                        <mat-icon>edit</mat-icon> {{ "Edit" | translate }}
                    </button>

                    <div class="card-body pb-0 mt-3">
                        <div class="row">
                            <div class="col-md-4 text-left">
                                <p>{{ "Name" | translate }}: {{ currentBrandProfile?.name }}</p>
                            </div>
                            <div class="col-md-4 text-left">
                                <p>{{ "City" | translate }}: {{ currentBrandProfile?.city }}</p>
                            </div>
                            <div class="col-md-4 text-left">
                                <p>{{ "Address" | translate }}: {{ currentBrandProfile?.address }}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 text-left">
                                <p>{{ "PhoneFax" | translate }}: {{ currentBrandProfile?.phone_fax }}</p>
                            </div>
                            <div class="col-md-4 text-left">
                                <p>{{ "Mobile" | translate }} 1: {{ currentBrandProfile?.mobile1 }}</p>
                            </div>
                            <div class="col-md-4 text-left">
                                <p>{{ "Mobile" | translate }} 2: {{ currentBrandProfile?.mobile2 }}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 text-left">
                                <p>{{ "Email" | translate }} 1: {{ currentBrandProfile?.email1 }}</p>
                            </div>
                            <div class="col-md-4 text-left">
                                <p>{{ "Email" | translate }} 2: {{ currentBrandProfile?.email2 }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mt-3 d-flex justify-content-between border-top">
                                <div class="text-center">
                                    <button mat-raised-button=""
                                        class="btn btn-link btn-tumblr mat-raised-button mat-button-base">
                                        <a href="{{ currentBrandProfile?.website }}" target="_blank">
                                            <div class="mat-button-wrapper"><i
                                                    class="fa fa-external-link-square mr-1"></i>
                                                Website
                                            </div>
                                        </a>
                                        <div matripple="" class="mat-ripple mat-button-ripple"></div>
                                        <div class="mat-mdc-focus-indicator"></div>
                                    </button>
                                    <div>{{ currentBrandProfile?.website }}</div>
                                </div>
                                <div class="text-center">
                                    <button mat-raised-button=""
                                        class="btn btn-link btn-facebook mat-raised-button mat-button-base">
                                        <a href="{{ currentBrandProfile?.facebook }}" target="_blank">
                                            <div class="mat-button-wrapper"><i class="fa fa-facebook-square mr-1"></i>
                                                Facebook
                                            </div>
                                        </a>
                                        <div matripple="" class="mat-ripple mat-button-ripple"></div>
                                        <div class="mat-mdc-focus-indicator"></div>
                                    </button>
                                    <div>{{ currentBrandProfile?.facebook }}</div>
                                </div>
                                <div class="text-center">
                                    <button mat-raised-button=""
                                        class="btn btn-link btn-linkedin mat-raised-button mat-button-base">
                                        <a href="{{ currentBrandProfile?.linkedin }}" target="_blank">
                                            <div class="mat-button-wrapper"><i class="fa fa-linkedin-square mr-1"></i>
                                                LinkedIn
                                            </div>
                                        </a>
                                        <div matripple="" class="mat-ripple mat-button-ripple"></div>
                                        <div class="mat-mdc-focus-indicator"></div>
                                    </button>
                                    <div>{{ currentBrandProfile?.linkedin }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>