<div class="card mb-5">
    <div class="d-flex card-header card-header-icon card-header-{{color}}">
        <div class="w-75">
            <div class="card-icon"><i class="material-icons">assignment</i></div>
            <h4 class="card-title">{{ title | translate }}</h4>
        </div>
        <!-- <div class="w-25 d-flex justify-content-end">
            <app-download-xls-pdf-buttons [service]="service" [name]="title" [filters]="filters" [reportContent]="reportContent"></app-download-xls-pdf-buttons>
        </div> -->
    </div>
    <div class="card-body">
        <div #reportContent>
            <ng-content></ng-content>
        </div>
    </div>
</div>