import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { RoleModel } from "src/app/modules/roles/models/role.model";
import { RolesService } from "src/app/modules/roles/services/roles.service";
import * as fromActions from "./roles.actions";

@Injectable()
export class RolesEffects {
    constructor(private _actions$: Actions, private _rolesService: RolesService) {

    }
    public loadList$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_LIST),
        switchMap(() =>
            this._rolesService.getRoles$().pipe(
                map((res: RoleModel[]) => fromActions.LOAD_LIST_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_LIST_FAILURE(error)))
            )
        )
    ));

    public loadItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_ITEM),
        switchMap(action =>
            this._rolesService.getRole$(action.id).pipe(
                map((res: RoleModel) => fromActions.LOAD_ITEM_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_ITEM_FAILURE(error)))
            )
        )
    ));

    public createItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.CREATE),
        switchMap(action =>
            this._rolesService.createRole$(action.payload).pipe(
                map((res: RoleModel) => fromActions.CREATE_SUCCESS(res)),
                tap(() => this._rolesService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._rolesService.reportError('Error on create role', error);
                    return of(fromActions.CREATE_FAILURE(error));
                })
            )
        )
    ));

    public updateItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.UPDATE),
        switchMap(action =>
            this._rolesService.updateRole$(action.payload).pipe(
                map((res: RoleModel) => fromActions.UPDATE_SUCCESS(res)),
                tap(() => this._rolesService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._rolesService.reportError('Error on update role', error);
                    return of(fromActions.UPDATE_FAILURE(error));
                })
            )
        )
    ));

    public deleteItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.DELETE),
        switchMap(action =>
            this._rolesService.deleteRole$(action.id).pipe(
                map((res) => fromActions.DELETE_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.DELETE_FAILURE(error)))
            )
        )
    ));
}