import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromState from "./stock-management.state";

export const selectStockManagementState = createFeatureSelector<fromState.StockManagementState>('stock-management');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = fromState.adapter.getSelectors(selectStockManagementState);

// List selectors
export const selectPagination = createSelector(
    selectStockManagementState,
    (state: fromState.StockManagementState) => state.pagination
);

export const selectLoading = createSelector(
    selectStockManagementState,
    (state: fromState.StockManagementState) => state.loading
);

export const selectError = createSelector(
    selectStockManagementState,
    (state: fromState.StockManagementState) => state.error
);

export const selectFilters = createSelector(
    selectStockManagementState,
    (state: fromState.StockManagementState) => state.filters
);

// Item selectors

export const selectItem = createSelector(
    selectStockManagementState,
    (state: fromState.StockManagementState) => state.selectedStockManagement.stockManagement
);

export const selectItemLoading = createSelector(
    selectStockManagementState,
    (state: fromState.StockManagementState) => state.selectedStockManagement.loading
);

export const selectItemError = createSelector(
    selectStockManagementState,
    (state: fromState.StockManagementState) => state.selectedStockManagement.error
);
export const selectItemSuccessResponse = createSelector(
    selectStockManagementState,
    (state: fromState.StockManagementState) => state.selectedStockManagement.saveAndClose
);