import { Pipe, PipeTransform } from "@angular/core";
import { CURRENCY } from "../constants";

@Pipe({
  name: "priceFormating"
})

export class PriceFormatingPipe implements PipeTransform {
  transform(item: any, displayCurrency?: boolean): any {    
    if(item) {
      return `${parseFloat(item).toFixed(2)} ${displayCurrency ? (' ' + CURRENCY) : ''}`;
    }
     return '-'
  }
}