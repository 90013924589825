import { Component, OnInit } from '@angular/core';
import { isMobile } from 'src/app/shared/utils/responsive.utils';
import { InventoryFacade } from '../../inventories/store/inventory.facade';
import { ProductsFacade } from '../../products/store/products.facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { AppFacade } from 'src/app/store/app.facade';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { defaultFilters } from 'src/app/shared/constants';
@UntilDestroy()
@Component({
    selector: 'app-dashboard-tabs',
    templateUrl: './dashboard-tabs.component.html',
    styleUrls: ['./dashboard-tabs.component.css']
})
export class DashboardTabsComponent implements OnInit {
    public stockManagements$ = this._appFacade.stockManagementsDropdownValues$;
    public inventories$ = this._inventoryFacade.inventoriesPagination$;
    public products$ = this._productsFacade.productsPagination$;

    public stockManagementsCount: number;
    public inventoriesCount: number;
    public productsCount: number;

    public isMobile = isMobile();

    constructor(private _localStorageService: LocalStorageService,
        private _appFacade: AppFacade,
        private _inventoryFacade: InventoryFacade,
        private _productsFacade: ProductsFacade) { }

    ngOnInit() {
        this.stockManagements$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.stockManagementsCount = data.length);
        this.inventories$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.inventoriesCount = data.total);
        this.products$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.productsCount = data.total);

        this._appFacade.loadStockManagementsDropdownValues();
        this._inventoryFacade.loadList({ ...defaultFilters, parsedFilters: '&filter[status]=open' });
        this._productsFacade.loadList(defaultFilters);
    }

    navigateTo(link, lsFilters) {
        let filters = {}
        switch (link) {
            case 'stock_managements':
                break
            case 'open_inventories':
                filters['status'] = "open"
                break
            case 'products':
                break
        }
        this._localStorageService.navigateToPageWithFilters(filters, lsFilters);
    }
}
