import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { booleanOptions$ } from 'src/app/shared/constants';
import { parseMultiselectFilters } from 'src/app/shared/utils/form.utils';
import { InventoryFacade } from '../../inventories/store/inventory.facade';
import { Subscription, filter, timer } from 'rxjs';
import { isMobile } from 'src/app/shared/utils/responsive.utils';
import { AppFacade } from 'src/app/store/app.facade';
import { utcConvert } from 'src/app/shared/utils/utils';
@UntilDestroy()
@Component({
    selector: 'app-qr-inventory-products',
    templateUrl: './qr-inventory-products.component.html',
    styleUrls: ['./qr-inventory-products.component.css']
})
export class QrInventoryProductsComponent implements OnInit {
    public inventoriesList$ = this._appFacade.inventoriesDropdownValues$;
    public inventoryProducts$ = this._inventoryFacade.inventoryProducts$;
    public loading$ = this._inventoryFacade.inventoryProductsLoading$;
    public form: FormGroup;
    public inventoryProducts;
    public booleanOptions;
    public inventoryId;
    private _subscription: Subscription;
    public totalScanned = 0;
    public activeFilters: boolean;
    public isMobile = isMobile();
    public utcConvert = utcConvert;
    public filters = {};


    constructor(
        private _appFacade: AppFacade,
        private _formBuilder: FormBuilder,
        private _inventoryFacade: InventoryFacade,
    ) { }


    ngOnInit() {
        this._appFacade.loadInventoriesDropdownValues("filter[status]=open");
        booleanOptions$().pipe(untilDestroyed(this)).subscribe(value => this.booleanOptions = value.data);
        this.form = this._formBuilder.group({
            "product.name": '',
            "product.external_number": '',
            is_scanned: '',
            status: '',
            inventory_id: null
        });

        this._inventoryFacade.inventoryProducts$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.inventoryProducts = data;
            this.totalScanned = data.filter(entry => entry.scanned_at).length;
        });
    }

    applyFilters() {
        if (this._subscription) this._subscription.unsubscribe();
        this._subscription = timer(0, 30000).pipe(untilDestroyed(this)).subscribe(() => {
            this._inventoryFacade.getInventoryProducts({ id: this.form.controls.inventory_id.value, filters: parseMultiselectFilters(this.filters) })
        });

        this.filters = this.form.value;
        this.filters['inventory_id'] && delete this.filters['inventory_id'];
        this.activeFilters = parseMultiselectFilters(this.form.value) !== '?' ? true : false;
    }

    resetFilters() {
        this.form.reset();
        this.activeFilters = false;
        this._inventoryFacade.cleanItem();
        this._subscription && this._subscription.unsubscribe();
    }
}
