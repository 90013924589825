import { AfterContentInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { InventoryModel } from '../../models/inventory.model';
import { InventoryService } from '../../services/inventory.service';
import { InventoryFacade } from '../../store/inventory.facade';
import { isNullOrUndefined } from 'src/app/shared/utils/isNullOrUndefined.utils';
import { filter } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { AppFacade } from 'src/app/store/app.facade';
import * as moment from 'moment';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { utcConvert } from 'src/app/shared/utils/utils';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';

@Component({
    selector: 'app-inventory-modal',
    templateUrl: './inventory-modal.component.html',
    styleUrls: ['./inventory-modal.component.css']
})
export class InventoryModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public isEdit = false;
    public form: FormGroup;
    public selectedInventory$ = this._invetoryFacade.inventory$;
    public loading$ = this._invetoryFacade.inventoryLoading$;
    public stockManagementsList = this._appFacade.stockManagementsDropdownValues$;
    public inventoryProducts = this._invetoryFacade.inventoryProducts$;
    public uploadSuccessfully$ = this._invetoryFacade.uploadFileSuccessfull$;
    public inventoryId;
    public resetUploadFile = false;
    public closedInventory: boolean;
    public utcConvert = utcConvert;
    public PermissionsEnum = PermissionsEnum;


    constructor(
        public dialogRef: MatDialogRef<InventoryModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InventoryModel,
        private _inventoryService: InventoryService,
        private _invetoryFacade: InventoryFacade,
        private _appFacade: AppFacade,
        private _sweetAlertService: SweetAlertService,
        private _formBuilder: FormBuilder,) {
        super(dialogRef, _inventoryService);
        this.isEdit = !isNullOrUndefined(this.data?.id);
        if (this.data?.id) {
            this._invetoryFacade.loadItem(this.data.id)
            this._invetoryFacade.getInventoryProducts({ id: this.data.id })

        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._appFacade.loadStockManagementsDropdownValues();

        this.form = this._formBuilder.group({
            stock_management_id: 0,
            start_date: moment().format('YYYY-MM-DD'),
            id: 0,
            overwrite_stock_management: false
        });

        this.selectedInventory$.pipe(filter(sm => !!sm), untilDestroyed(this)).subscribe((inventory: InventoryModel) => {
            this.form.patchValue(inventory);
            this.isEdit = true;
            this.closedInventory = !!inventory.end_date;
            this.inventoryId = inventory.id;
        });
    }

    ngOnDestroy(): void {
        this._invetoryFacade.cleanItem();
    }

    saveAndClose() {
        if (this.form.valid) {
            this.onSubmit();
            this._invetoryFacade.inventorySuccessResponse$.pipe(filter(res => !!res)).subscribe(data => {
                this.dialogRef.close();
                this._invetoryFacade.setSaveAndCloseToFalse();
            })
        } else {
            this.form.markAllAsTouched();
        }
    }
    onSubmit() {
        let payload = {
            stock_management_id: this.form.controls.stock_management_id.value,
            start_date: moment(this.form.controls.start_date.value).format('YYYY-MM-DD'),
            id: this.inventoryId
        }
        !this.isEdit && delete payload.id;
        this.isEdit ? this._invetoryFacade.updateItem(payload) : this._invetoryFacade.createItem(payload);
    }

    getImportFormat() {
        this._invetoryFacade.loadImportFormat();
    }

    uploadFile(event) {
        const payload = {
            overwrite_stock_management: event.file.overwrite_stock_management,
            id: this.inventoryId,
            file: event
        }
        this._invetoryFacade.uploadProducts(payload);

    }

    clearErrors() {
        // this._invetoryFacade.clearErrors();
    }

    closeInventory() {
        this._sweetAlertService.showSwal('close-inventory', () => {
            this._invetoryFacade.closeInventory(this.inventoryId)
        });
    }
    generateQR(newly_added_products) {
        this._invetoryFacade.displayQRs(this.inventoryId, newly_added_products)
    }

    resetUpload(e) {
        this.resetUploadFile = e ? true : false;
    }
}
