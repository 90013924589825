import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { RoleModel } from "src/app/modules/roles/models/role.model";

export interface RolesState extends EntityState<RoleModel> {
    error: string | null;
    loading: boolean;

    selectedRole: {
        item: RoleModel | undefined;
        error: string | null;
        loading: boolean;
        saveAndClose: boolean;
    }
}

export const adapter: EntityAdapter<RoleModel> = createEntityAdapter<RoleModel>();

export const rolesInitialState: RolesState = adapter.getInitialState({
    ids: [],
    entities: {},
    error: null,
    loading: false,

    selectedRole: {
        item: null,
        error: null,
        loading: false,
        saveAndClose: false
    }
});