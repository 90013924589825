import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PermissionCategoryModel, PermissionsModel } from './../models/permissions.model';
import { ApiRequestService, apiEndpoints } from './api-request.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {

    permission$: BehaviorSubject<PermissionsModel> = new BehaviorSubject(undefined);
    permissionsList$: BehaviorSubject<string[]> = new BehaviorSubject(undefined);
    permissionsDetailedList$: BehaviorSubject<PermissionCategoryModel[]> = new BehaviorSubject(undefined);

    constructor(private _apiRequestService: ApiRequestService) { }

    getPermission(name) {
        this._apiRequestService.submitGetRequest(apiEndpoints.permission + '/' + name).subscribe(
            apiResponse => this.permission$.next(apiResponse.data),
            error => this.reportError('Error on get permission', error)
        );
    }

    getPermissions() {
        this._apiRequestService.submitGetRequest(apiEndpoints.permissions).subscribe(
            apiResponse => this.permissionsList$.next(apiResponse.data),
            error => this.reportError('Error on get permissions', error)
        );
    }

    getPermissionsDetailed() {
        this._apiRequestService.submitGetRequest(apiEndpoints.permissionsDetailed).subscribe(
            apiResponse => this.permissionsDetailedList$.next(apiResponse.data),
            error => this.reportError('Error on get permissions detailed', error)
        );
    }

    reportError(message: string, response: object) {
        console.error(message);
        console.log(response);
    }
}
