import { BrandProfileService } from '../../../services/brand-profile.service';
import { BrandProfileModel } from '../../../models/brand-profile.model';
import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-brand-images-tab',
    templateUrl: './brand-images-tab.component.html',
    styleUrls: ['./brand-images-tab.component.css']
})
export class BrandImagesTabComponent implements OnInit {
    public form: FormGroup;
    public currentLogo: string;
    public currentCover: string;

    @Input() data: BrandProfileModel;
    @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
    @Output() saveAndClose: EventEmitter<boolean> = new EventEmitter();


    @ViewChild('removeCoverImg', { static: true }) removeCoverImgRef: ElementRef;
    @ViewChild('removeLogoImg', { static: true }) removeLogoImgRef: ElementRef;
    
    constructor(
        private _brandProfileService: BrandProfileService,
        private _formBuilder: FormBuilder) { }


    ngOnInit(): void {
        this.form = this._formBuilder.group({
            cover: [this.data?.cover],
            logo: [this.data?.logo],
        });

        this.currentCover = this.data?.cover;
        this.currentLogo = this.data?.logo;

        this._brandProfileService.onSaveSuccess$.pipe(untilDestroyed(this)).subscribe(() => {
            this.removeCoverImgRef.nativeElement.click();
            this.removeLogoImgRef.nativeElement.click();
        });

        this._brandProfileService.currentBrandProfile$.pipe(filter(result => !!result), untilDestroyed(this)).subscribe(res => {
            this.currentLogo = res?.logo;
            this.currentCover = res?.cover;
        });
    }

    saveAndExit() {
        if (typeof this.form.controls.logo.value !== 'string' || typeof this.form.controls.cover.value !== 'string') {
            this.uploadFileToActivity(this.form.value);
            this.saveAndClose.emit(true);
            return;
        }
        this.closeModal.emit();
    }

    onSubmit() {
        if (typeof this.form.controls.logo.value !== 'string' || typeof this.form.controls.cover.value !== 'string') {
            this.uploadFileToActivity(this.form.value);
        }
    }

    handleFileInput(files: FileList, fileType: string) {
        this.form.get(fileType).setValue(files[0]);
    }

    uploadFileToActivity(imgs) {
        this._brandProfileService.updateCurrentBrandProfileLogoAndCover(imgs);
    }

    removeImage(input: HTMLInputElement, type: string) {
        input.value = '';
        type === 'logo' ? 
        this.form.controls.logo.setValue('') :
        this.form.controls.cover.setValue('')
    }
}
