import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import * as fromActions from "./products.actions";
import { Router } from "@angular/router";
import { ProductsService } from "../services/products.service";
import { ProductModel } from "../models/product.model";

@Injectable()
export class ProductsEffects {

    constructor(private _actions$: Actions, private _productsService: ProductsService, private _router: Router) { }

    public loadList$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_LIST),
        switchMap(action =>
            this._productsService.getProducts$(action.payload).pipe(
                map((res: PaginationModel) => fromActions.LOAD_LIST_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_LIST_FAILURE(error)))
            )
        )
    ));

    public loadItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_ITEM),
        switchMap(action =>
            this._productsService.getProduct$(action.id).pipe(
                map((res: ProductModel) => fromActions.LOAD_ITEM_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_ITEM_FAILURE(error)))
            )
        )
    ));

    public createItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.CREATE),
        switchMap(action =>
            this._productsService.createProduct$(action.payload).pipe(
                map((res) => fromActions.CREATE_SUCCESS(res)),
                tap(() => this._productsService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._productsService.reportError('Error on create product', error);
                    return of(fromActions.CREATE_FAILURE(error));
                })
            )
        )
    ));

    public updateItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.UPDATE),
        switchMap(action =>
            this._productsService.updateProduct$(action.payload).pipe(
                map((res) => fromActions.UPDATE_SUCCESS(res)),
                tap(() => this._productsService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._productsService.reportError('Error on update product', error);
                    return of(fromActions.UPDATE_FAILURE(error));
                })
            )
        )
    ));

    public displayQR$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.DISPLAY_QR),
        switchMap(action => {
            return this._productsService.getProductQRCode$(action.id).pipe(
                map((res) => fromActions.DISPLAY_QR_SUCCESS(res)),
                tap((res) => {
                    this.downloadFile(res.body, this.getFileNameFromHeaders(res.headers))
                    this._productsService.onSaveSuccess$.next(true)
                }),
                catchError((error: HttpErrorResponse) => {
                    this._productsService.reportError('Error get QR code', error);
                    return of(fromActions.DISPLAY_QR_FAILURE(error));
                })
            )
        })
    ));

    getFileNameFromHeaders(headers: HttpHeaders): string {
        let name = headers.get('Content-Disposition')?.split(';')[1].split('=')[1].replace(/\"/g, '')
        return name ? name : 'Import Format';
    }

    downloadFile(file: any, fileName: string) {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    }

}