export class AccountModel {
    id?: number;
    cnp?: number;
    id_card_number?: number;
    id_card_series?: string;
    address?: string;
    device_name?: string;
    email?: string;
    email_verified_at?: Date;
    first_name?: string;
    last_name?: string;
    mobile?: string;
    phone_fax?: string;
    profile_pic?: any;
    password?: string;
    password_confirmation?: string;
    permissions?: string[];
    status?: string;
    use_profile_pic?: boolean;
    username?: string;
    username_identifier?: string;
    website?: string;
    roles?: string[];
    roles_ids?: number[];
    self_update?: boolean;
    owner_invoice_profile_id?: number;
}
