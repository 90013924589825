import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { RequestMethodsEnum } from 'src/app/shared/enums/RequestMethods.enum';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { InvoiceModel } from '../models/invoice.model';
import { ApplyFiltersModel } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { parseFilters } from 'src/app/shared/utils/filters.utils';

@Injectable({
    providedIn: 'root'
})

export class InvoicesService extends BaseService {

    itemsList$: BehaviorSubject<InvoiceModel[]> = new BehaviorSubject(undefined);
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedInvoice$: BehaviorSubject<InvoiceModel> = new BehaviorSubject(undefined);

    constructor(
        private apiRequestService: ApiRequestService,
        private _router: Router,
        protected _formErrorService: FormErrorService) {
        super(_formErrorService);
    }

    getInvoices$(payload: ApplyFiltersModel): Observable<PaginationModel> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.invoices}${parseFilters(payload)}`);
    }

    generateInvoicePDF$(id: number) {
        return this.apiRequestService.submitGetRequest(apiEndpoints.invoices + `/${id}/generate-pdf`, { responseType: 'blob' });
    }

    getInvoice$(id: number) {
        return this.apiRequestService.submitGetRequest(apiEndpoints.invoices + `/${id}`)
    }

    reverseInvoice$(id: number, payload: any) {
        return this.apiRequestService.submitPostRequest(apiEndpoints.invoices + `/${id}/reverse`, { invoice_services: payload })
    }



    // getInvoices(filter?: string, paginationSettings?: string) {
    //     this.isLoading$.next(true);
    //     this.apiRequestService.submitGetRequest(apiEndpoints.invoices + (paginationSettings ? paginationSettings : '') + (filter ? `${filter}` : '')).subscribe({
    //         next: apiResponse => {
    //             const invoicesWithIssuedForm = apiResponse.data.map(invoice => {
    //                 return {
    //                     ...invoice,
    //                     invoice_series_and_no: `${invoice.invoice_series} - ${invoice.invoice_no}`,
    //                 }
    //             });
    //             this.itemsList$.next(invoicesWithIssuedForm);
    //             this.itemsData$.next({ ...apiResponse, data: invoicesWithIssuedForm });
    //             this.isLoading$.next(false);
    //         },
    //         error: error => this.reportError('Error on get invoices', error)
    //     })
    // }

    // getInvoice(id: number) {
    //     this.apiRequestService.submitGetRequest(apiEndpoints.invoices + `/${id}`).subscribe({
    //         next: apiResponse => this.selectedInvoice$.next(apiResponse.data),
    //         error: error => this.reportError('Error on get invoice', error)
    //     })
    // }

    // createInvoice(invoice: InvoiceModel, filters?: string) {
    //     this.apiRequestService.submitPostRequest(apiEndpoints.invoices, invoice).subscribe({
    //         next: apiResponse => {
    //             this.selectedInvoice$.next(apiResponse.data);
    //             this._router.url !== '/dashboard/sessions-report' && this.refreshListAfterChange(apiResponse.data, RequestMethodsEnum.Create, this.itemsList$);
    //             this.onSaveSuccess$.next(true);
    //         },
    //         error: error => this.reportError('Error on create invoice', error)
    //     })
    // }

    // updateInvoice(invoice: InvoiceModel) {
    //     this.isLoading$.next(true);
    //     this.apiRequestService.submitPutRequest(apiEndpoints.invoices + '/' + invoice.id, invoice).subscribe({
    //         next: apiResponse => {
    //             this.selectedInvoice$.next(apiResponse.data);
    //             this.refreshListAfterChange(apiResponse.data, RequestMethodsEnum.Update, this.itemsList$);
    //             this.isLoading$.next(false);
    //             this.onSaveSuccess$.next(true);
    //         },
    //         error: error => this.reportError('Error on update invoice', error)
    //     });
    // }

    // generateInvoicePDF(id: number, print?: boolean) {
    //     this.apiRequestService.submitGetRequest(apiEndpoints.invoices + `/${id}/generate-pdf`, { responseType: 'blob' }).subscribe({
    //         next: apiResponse => this.openFile(apiResponse, (print && print)),
    //         error: error => this.reportError('Error on generate invoice', error)
    //     })
    // }


    // reverseInvoice(id: number, payload: any): void {
    //     this.apiRequestService.submitPostRequest(apiEndpoints.invoices + `/${id}/reverse`, { invoice_services: payload }).subscribe({
    //         next: () => {
    //             this.selectedInvoice$.next(undefined);
    //             // this.getInvoices();
    //         },
    //         error: error => this.reportError('Error on reverse invoice', error)
    //     });
    // }

    openFile(file: any, print?: boolean) {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        this._router.navigate([]).then(() => {
            const openedWindow = window.open(a.href, '_blank');
            print && openedWindow.print();
        });
        this.isLoading$.next(false);
    }

    downloadFile(file: any, fileName: string) {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        this._router.navigate([]).then(() => { window.open(a.href, '_blank'); });
        a.click();
        this.isLoading$.next(false);
    }

    refreshListAfterChange(serverResponse: any, type: RequestMethodsEnum, list$: BehaviorSubject<any>) {
        const newList = [...list$.value];
        const index = newList.findIndex(item => item.id === serverResponse?.id);

        switch (type) {
            case RequestMethodsEnum.Create:
                newList.push(serverResponse);
                break;

            case RequestMethodsEnum.Update:
                newList[index] = serverResponse;
                break;

            case RequestMethodsEnum.Delete:
                newList.splice(index, 1);
                break;
        }

        list$.next(newList);
    }
}
