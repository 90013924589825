import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { CURRENCY } from 'src/app/shared/constants';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { InvoiceServiceModel } from '../../../../models/invoice.model';
import { InvoiceServicesService } from '../../../../services/invoice-services.service';

@UntilDestroy()
@Component({
    selector: 'app-invoice-services-list',
    templateUrl: './invoice-services-list.component.html',
    styleUrls: ['./invoice-services-list.component.css']
})
export class InvoiceServicesListComponent implements OnInit, OnDestroy {

    public invoiceServicesList: InvoiceServiceModel[];
    public totalValue: string;
    public currency = CURRENCY;

    @Input() data: any;

    constructor(private _invoiceServicesService: InvoiceServicesService, private _sweetAlertService: SweetAlertService) { }

    ngOnInit(): void {
        this.data?.id && this._invoiceServicesService.getInvoiceServices(this.data.id);

        this._invoiceServicesService.itemsList$.pipe(filter(services => !!services), untilDestroyed(this)).subscribe(data => {
            this.invoiceServicesList = data.invoice_services;
            this.totalValue = data.total_value;
        });
    }

    ngOnDestroy(): void {
        this._invoiceServicesService.itemsList$.next(undefined);
    }

    deleteService(service: InvoiceServiceModel) {
        this._sweetAlertService.showSwal('warning-message', () => this._invoiceServicesService.deleteInvoiceService(service), 'Service');
    }
}
