import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import * as fromActions from './roles.actions';
import * as fromState from './roles.state';

export const rolesReducer = createReducer(
    fromState.rolesInitialState,
    on(
        fromActions.CLEAN_STATE,
        () => fromState.adapter.getInitialState(fromState.rolesInitialState)
    ),

    on(
        fromActions.CLEAN_ITEM,
        (state) => produce(state, (draft) => {
            draft.selectedRole = produce(fromState.rolesInitialState.selectedRole, () => { });
        })
    ),

    on(
        fromActions.LOAD_LIST,
        (state) => produce(state, (draft) => {
            draft.loading = true;
            draft.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_LIST_SUCCESS,
        (state, action) => fromState.adapter.setAll(
            action.payload,
            produce(state, (draft) => {
                draft.loading = false;
                draft.error = undefined;
            }),
        )
    ),

    on(
        fromActions.LOAD_LIST_FAILURE,
        (state, action) => fromState.adapter.removeAll(
            produce(state, (draft) => {
                draft.loading = false;
                draft.error = action.error.error;
            })
        )
    ),
    on(
        fromActions.LOAD_ITEM,
        (state) => produce(state, (draft) => {
            draft.selectedRole.item = undefined;
            draft.selectedRole.loading = true;
            draft.selectedRole.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_ITEM_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.selectedRole.item = action.payload;
            draft.selectedRole.loading = false;
            draft.selectedRole.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_ITEM_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.selectedRole.loading = false;
            draft.selectedRole.error = action.error.error;
        })
    ),

    on(
        fromActions.CREATE,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedRole.loading = true;
                draft.selectedRole.error = undefined;
            });
        }
    ),

    on(
        fromActions.CREATE_SUCCESS,
        (state, action) => fromState.adapter.addOne(action.payload,
            produce(state, (draft) => {
                draft.selectedRole.item = action.payload;
                draft.selectedRole.loading = false;
                draft.selectedRole.error = undefined;
            })
        )
    ),

    on(
        fromActions.CREATE_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedRole.loading = false;
                draft.selectedRole.error = action.error.error;
                draft.selectedRole.saveAndClose = false;
            })
    ),

    on(
        fromActions.UPDATE,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedRole.loading = true;
                draft.selectedRole.error = undefined;
            });
        }
    ),

    on(
        fromActions.UPDATE_SUCCESS,
        (state, action) => fromState.adapter.updateOne({ id: action.payload.id, changes: action.payload },
            produce(state, (draft) => {
                draft.selectedRole.item = produce(state.selectedRole.item, () => action.payload);
                draft.selectedRole.loading = false;
                draft.selectedRole.error = undefined;
            })
        )
    ),

    on(
        fromActions.UPDATE_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedRole.loading = false;
                draft.selectedRole.error = action.error.error;
                draft.selectedRole.saveAndClose = false;
            })
    ),

    on(
        fromActions.DELETE,
        (state) => {
            return produce(state, (draft) => {
                draft.loading = true;
                draft.error = undefined;
            });
        }
    ),

    on(
        fromActions.DELETE_SUCCESS,
        (state, action) => {
            return fromState.adapter.removeOne(action.id.toString(),
                produce(state, (draft) => {
                    draft.loading = false;
                    draft.error = undefined;
                })
            )
        }
    ),

    on(
        fromActions.DELETE_FAILURE,
        (state, action) => {
            return produce(state, (draft) => {
                draft.loading = false;
                draft.error = action.error.error;
            })
        }
    )
);