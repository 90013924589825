import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'SplitFieldsPipe' })

export class SplitFieldsPipe implements PipeTransform {
    transform(row: any, field: string) {
        if (field) {
            if (field.includes('.')) {
                return this.splitFields(row, field);
            }
            return row[field];
        }
        return
    }

    splitFields(row: object, field: string) {
        let fields = field.split('.');
        let data = row;
        for (let i = 0; i < fields.length; i++) {
            const list = fields[i].includes('[') && fields[i].split('[');
            const listPosition = list && list[1].substring(0, 1);
            data = listPosition ? data[list[listPosition]][i] : data[fields[i]];
            if (!data) { return; }
        }
        return `${data}`;
    }
}