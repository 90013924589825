import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RolesFacade } from 'src/app/modules/roles/store/roles.facade';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { PermissionCategoryModel, PermissionsModel } from 'src/app/shared/models/permissions.model';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { RoleModel } from '../../models/role.model';
import { RolesService } from './../../services/roles.service';

@UntilDestroy()
@Component({
    selector: 'app-roles-modal',
    templateUrl: './roles-modal.component.html',
    styleUrls: ['./roles-modal.component.css']
})
export class RolesModalComponent extends BaseModalComponent implements OnInit, OnDestroy {


    public loading$ = this._rolesFacade.roleLoading$;
    public role$ = this._rolesFacade.role$;
    public selectedRole: RoleModel;
    public form = new FormGroup({
        name: new FormControl('')
    });
    public permissionsList: PermissionCategoryModel[];
    public permissionCols: string[] = ['Vizualizare', 'Creare', 'Editare', 'Stergere'];

    constructor(public dialogRef: MatDialogRef<RolesModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: RoleModel,
        private _rolesFacade: RolesFacade,
        private _permissionsService: PermissionsService,
        private _rolesService: RolesService) {
        super(dialogRef, _rolesService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this._permissionsService.getPermissionsDetailed();
        this.data?.id && this._rolesFacade.loadItem(this.data.id);

        combineLatest([
            this._permissionsService.permissionsDetailedList$,
            this.role$
        ]).pipe(
            filter(([permissionCategories, role]: [PermissionCategoryModel[], RoleModel]) => !!permissionCategories && !!role),
            untilDestroyed(this)
        ).subscribe(([permissionCategories, role]: [PermissionCategoryModel[], RoleModel]) => {
            this.selectedRole = role;
            this.form.controls.name.setValue(role.name);
            this.permissionsList = permissionCategories;

            this.permissionsList.forEach((category: PermissionCategoryModel) => {
                category.subcategories.forEach(subcategory => {
                    subcategory.permissions.forEach(permission => {
                        const found = this.selectedRole.permissions_names.find(rolePermName => rolePermName === permission.name);
                        permission.allowed = !!found;
                    });
                });
            });
        });
    }

    ngOnDestroy(): void {
        this._rolesFacade.cleanItem();
    }

    saveAndClose() {
        this._saveAndClose = true;
        this.onSubmit();
    }
    onSubmit() {
        if (!this.selectedRole) {
            this.addRole();
            return;
        }
        this.updateRole();
    }

    addRole() {
        this._rolesFacade.createItem(this.form.value);
    }

    updateRole() {
        const newPermissionsList = [];

        this.permissionsList.forEach((category: PermissionCategoryModel) => {
            category.subcategories.forEach(subcategory => {
                subcategory.permissions.forEach(permission => {
                    if (permission.allowed) {
                        newPermissionsList.push(permission.name);
                    }
                });
            });
        });

        this._rolesFacade.updateItem({
            id: this.selectedRole.id,
            name: this.form.value.name,
            permissions: newPermissionsList,
        });
    }

    changePermissions(updatedPermissions: PermissionsModel[]) {
        updatedPermissions.forEach(updatedPermission => {
            this.permissionsList.forEach((category: PermissionCategoryModel) => {
                category.subcategories.forEach(subcategory => {
                    subcategory.permissions.forEach(permission => {
                        if (updatedPermission.name === permission.name) {
                            permission.allowed = updatedPermission.allowed;
                        }
                    });
                });
            });
        });
    }
}
