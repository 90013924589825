import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountModel } from 'src/app/modules/accounts/models/account.model';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { AppFacade } from 'src/app/store/app.facade';
import { ApiRequestService, apiEndpoints } from './api-request.service';
import { AUTH_TOKEN, LocalStorageService } from './local-storage.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class UserProfileService extends BaseService {
    loggedUser: AccountModel;

    constructor(
        private apiRequestService: ApiRequestService,
        private _localStorageService: LocalStorageService,
        protected _formErrorService: FormErrorService,
        private _appFacade: AppFacade) {
        super(_formErrorService);
        this._appFacade.loggedUser$.pipe(untilDestroyed(this)).subscribe(user => this.loggedUser = user);
    }

    refreshToken() {
        this.apiRequestService.submitGetRequest(apiEndpoints.refreshToken).subscribe({
            next: apiResponse => {
                this._localStorageService.setValue(AUTH_TOKEN, apiResponse.data.token);
            },
            error: error => this.reportError('Error on refresh token', error)
        });
    }

    checkForPermission(permissionsParam: boolean | string | string[]) {
        if (permissionsParam === '' || permissionsParam === undefined) {
            return true;
        }

        if (typeof (permissionsParam) === 'object') {
            const giveAccess: boolean[] = [];
            permissionsParam.find(permissionParam => {
                const found = this.loggedUser?.permissions.find(permission => permission === permissionParam);
                found ? giveAccess.push(true) : giveAccess.push(false);
            });
            if (giveAccess.every(value => !value)) {
                return false;
            }
            return true;
        }

        return this.loggedUser?.permissions.find(permission => permissionsParam === permission) ? true : false;
    }
}
