import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CustomSheetsNumberLabel' })

export class CustomSheetsNumberLabel implements PipeTransform {
    transform(value: string, element: HTMLElement, row: any) {
        let guestsNo = row['guests_number'] ? row['guests_number'] : 0;
        let childrenNo = row['children_number'] ? row['children_number'] : 0;
        let sheetsNo = value ? value : 0;

        const tokens = element.classList.value.split(' ');

        tokens.forEach(token => token && element.classList.remove(token));
        if (sheetsNo && (guestsNo + childrenNo === sheetsNo)) {
            element.classList.add('badge', 'custom-table-badge', 'custom', `badge-success`);
        } else {
            element.classList.add('badge', 'custom-table-badge', 'custom', `badge-warning`);
        }
        if (element.parentElement) {
            element.parentElement ? element.parentElement.style.textAlign = 'center' : null;
        }
        return sheetsNo;
    }
}