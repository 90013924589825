import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { InvoiceModel } from "../models/invoice.model";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";

export interface InvoiceState extends EntityState<InvoiceModel> {
    error: string | null;
    loading: boolean;
    pagination: Omit<PaginationModel, 'data'>;
    filters: ApplyFiltersModel;
    selectedInvoice: {
        invoice: InvoiceModel | undefined;
        error: string | null;
        loading: boolean;
        saveAndClose: boolean;
    };
}

export const adapter: EntityAdapter<InvoiceModel> = createEntityAdapter<InvoiceModel>();

export const invoicesInitialState: InvoiceState = adapter.getInitialState({
    ids: [],
    entities: {},
    pagination: null,
    error: null,
    loading: false,
    filters: undefined,
    selectedInvoice: {
        invoice: null,
        error: null,
        loading: false,
        saveAndClose: false
    },
});