import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: '[appStrippedTable]'
})

export class StrippedTableDirective implements AfterViewInit {

    @Input() appStrippedTable: number;

    constructor(private elementRef: ElementRef) { }

    ngAfterViewInit(): void {
        this.appStrippedTable % 2 === 0 && (this.elementRef.nativeElement.style.backgroundColor = '#0000000d');
    }
}