import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import * as fromActions from "./invoices.actions";
import { InvoicesService } from "../services/invoices.service";
import { InvoiceModel } from "../models/invoice.model";

@Injectable()
export class InvoicesEffects {

    constructor(private _actions$: Actions, private _invoicesService: InvoicesService) { }

    public loadList$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_LIST),
        switchMap(action =>
            this._invoicesService.getInvoices$(action.payload).pipe(
                map((res: PaginationModel) => fromActions.LOAD_LIST_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_LIST_FAILURE(error)))
            )
        )
    ));

    public generateInvoicePDF$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.GENERATE_PDF),
        switchMap(action =>
            this._invoicesService.generateInvoicePDF$(action.id).pipe(
                map((res) => {
                    this._invoicesService.openFile(res, (action.print && action.print));
                    return fromActions.GENERATE_PDF_SUCCESS(res);
                }),
                catchError((error: HttpErrorResponse) => of(fromActions.GENERATE_PDF_FAILURE(error)))
            )
        )
    ));

    public loadItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_ITEM),
        switchMap(action =>
            this._invoicesService.getInvoice$(action.id).pipe(
                map((res: InvoiceModel) => fromActions.LOAD_ITEM_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_ITEM_FAILURE(error)))
            )
        )
    ));

    public reverseInvoice$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.REVERSE_INVOICE),
        switchMap(action =>
            this._invoicesService.reverseInvoice$(action.id, action.payload).pipe(
                map((res: InvoiceModel) => fromActions.REVERSE_INVOICE_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.REVERSE_INVOICE_FAILURE(error)))
            ))
    ));
}