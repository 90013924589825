import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormErrorService } from './form-error.service';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        public formErrorService: FormErrorService,) { }

    public reportError(message: string, response: HttpErrorResponse) {
        this.isLoading$.next(false);
        this.formErrorService.reportError$.next(response);
        console.error(message);
    }
}
