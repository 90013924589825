<div class="row dialog-top-header d-flex" mat-dialog-title>
    <div class="col-10 dialog-pre-title">
        <small>{{ 'Settings' | translate }}</small>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn"
                (click)="dialogRef.close()">
            <span class="material-icons">close</span>
        </button>
    </div>
    <div class="col-12">
        <h1 class="mb-0 dialog-title">
            {{ 'Edit' | translate }}: {{ setting?.name }}
        </h1>
    </div>
</div>


<mat-dialog-content>
    <div class="position-relative">
        <div class="loading-overlay w-100"
             *ngIf="loading$ | async">
            <span class="spinner"></span>
        </div>
        <form [formGroup]="form"
              class="mt-5"
              (ngSubmit)="onSubmit()"
              appFormValidator>
            <div class="form-row">
                <div class="col-md-12">
                    <mat-form-field class="customized">
                        <mat-label for="value">{{ 'Value' | translate }}:</mat-label>
                        <input type="text"
                               matInput
                               id="value"
                               autocomplete="off"
                               formControlName="value">
                        <mat-error>
                            <app-form-field-error [control]="form.controls['value']"></app-form-field-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<div class="mb-3">
    <mat-dialog-actions> 
        <app-dialog-footer-buttons class="w-100"
            (close)="dialogRef.close()"
            (saveAndClose)="saveAndClose()"
            (save)="onSubmit()">
        </app-dialog-footer-buttons>
    </mat-dialog-actions>
</div>