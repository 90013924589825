import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { changeCurrency } from 'src/app/shared/constants';
import { apiEndpoints, ApiRequestService } from '../../../shared/services/api-request.service';
import { BaseService } from '../../../shared/services/base.service';
import { FormErrorService } from '../../../shared/services/form-error.service';
import { BrandProfileCustomisationModel } from '../models/brand-profile-customisation.model';
import { BrandProfileModel } from '../models/brand-profile.model';

@Injectable({
    providedIn: 'root'
})
export class BrandProfileService extends BaseService {
    currentBrandProfile$: BehaviorSubject<BrandProfileModel> = new BehaviorSubject(undefined);

    get currency(): string {
        changeCurrency(this.currentBrandProfile$.value.currency);
        return this.currentBrandProfile$.value.currency;
    }

    constructor(
        private apiRequestService: ApiRequestService,
        public _formErrorService: FormErrorService) {
        super(_formErrorService);
    }

    getCurrentBrandProfile() {
        this.apiRequestService.submitGetRequest(apiEndpoints.brandProfile).subscribe(
            apiResponse => {
                this.currentBrandProfile$.next(apiResponse.data)
                changeCurrency(apiResponse.data.currency);
            },
            error => this.reportError('Error on get current brand', error));
    }

    updateCurrentBrandCustomisation(payload: BrandProfileCustomisationModel) {
        this.apiRequestService.submitPostRequest(apiEndpoints.brandProfileAdditionalDetails, payload).subscribe(
            apiResponse => {
                this.currentBrandProfile$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
            },
            error => this.reportError('Error on set customisation', error));
    }

    updateCurrentBrandProfile(currentBrandProfile: BrandProfileModel) {
        this.apiRequestService.submitPutRequest(apiEndpoints.brandProfile, currentBrandProfile).subscribe(
            apiResponse => {
                this.currentBrandProfile$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
            },
            error => this.reportError('Error on get current brand', error));
    }

    updateCurrentBrandSettings(settings) {
        this.apiRequestService.submitPutRequest(apiEndpoints.brandProfileSettings, settings).subscribe(
            apiResonse => {
                this.currentBrandProfile$.next(apiResonse.data);
                this.onSaveSuccess$.next(true);
            },
            error => this.reportError('Error on get current settings brand', error));
    }

    updateCurrentBrandProfileLogoAndCover(imgs) {
        const formData: FormData = new FormData();

        if (typeof imgs.logo !== 'string' && imgs.logo) {
            formData.append('logo', imgs?.logo, imgs?.logo.name);
        }
        if (typeof imgs.cover !== 'string' && imgs.cover) {
            formData.append('cover', imgs?.cover, imgs?.cover.name);
        }

        this.apiRequestService.submitPostRequest(apiEndpoints.brandProfileLogoAndCover, formData).subscribe(
            apiResponse => {
                this.currentBrandProfile$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
            },
            error => this.reportError('Error on get current Brand logo and cover', error));
    }

    getQuantumDurationOptions(maxDurationHours: number): number[] {
        const timeQuantumMinutes = this.currentBrandProfile$?.value.time_quantum || 10;

        const options = [];
        for (let index = timeQuantumMinutes; index <= maxDurationHours * 60; index += timeQuantumMinutes) {
            options.push(index);
        }

        return options;
    }
}
