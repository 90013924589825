import { PermissionsEnum } from '../../shared/enums/Permissions.enum';
import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { BrandProfileModalComponent } from './components/brand-profile-modal/brand-profile-modal.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BrandProfileModel } from './models/brand-profile.model';
import { BrandProfileService } from './services/brand-profile.service';

@UntilDestroy()
@Component({
    selector: 'app-brand-profile',
    templateUrl: './brand-profile.component.html',
    styleUrls: ['./brand-profile.component.css']
})
export class BrandProfileComponent implements OnInit {
    public currentBrandProfile: BrandProfileModel;
    public PermissionsEnum = PermissionsEnum;

    constructor(
        public sweetAlertService: SweetAlertService,
        private _brandProfileService: BrandProfileService,
        private _dialogService: DialogService) { }

    ngOnInit(): void {
        this._brandProfileService.getCurrentBrandProfile();

        this._brandProfileService.currentBrandProfile$
            .pipe(filter(brand => !!brand), untilDestroyed(this))
            .subscribe(brand => this.currentBrandProfile = brand);

    }

    public openBrandProfileModal() {
        this._dialogService.openModal(this.currentBrandProfile, BrandProfileModalComponent, DimensionsEnum.AUTO);
    }
}
