import { BrandInvoiceProfileModel } from "../../brand-invoice-profile/models/brand-invoice-profile.model";

export class BrandProfileModel {
    name: string;
    brand_invoice_profile: BrandInvoiceProfileModel;
    brand_invoice_profile_id: number;
    address: string;
    city: string;
    phone_fax: string;
    mobile1: string;
    mobile2: string;
    email1: string;
    email2: string;
    website: string;
    facebook: string;
    linkedin: string;
    logo: string;
    cover: string;
    same_user_option: string;
    show_dashboard: string;
    show_calendar: string;
    force_finish_order: string;
    payment_document_title: string;
    payment_document_text: string;
    medic_report_title: string;
    medic_report_text: string;
    company_description: string;
    currency: string;
    time_quantum: number;
}
