import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AccountModel } from "src/app/modules/accounts/models/account.model";
import * as fromActions from "./app.actions";
import * as fromSelectors from "./app.selectors";

@Injectable()
export class AppFacade {
    constructor(private _store: Store) { }

    // Select the selectors
    get isAuthenticated$() {
        return this._store.select(fromSelectors.isAuthenticated);
    }

    get langValue$() {
        return this._store.select(fromSelectors.lang);
    }

    get userStatuses$() {
        return this._store.select(fromSelectors.selectUserStatuses);
    }

    get loggedUser$() {
        return this._store.select(fromSelectors.selectLoggedUser);
    }

    get loggedUserLoading$() {
        return this._store.select(fromSelectors.selectLoggedUserLoading);
    }

    get rolesDropdownValues$() {
        return this._store.select(fromSelectors.selectRolesDropdownValues);
    }
    get accountsDropdownValues$() {
        return this._store.select(fromSelectors.selectAccountsDropdownValues);
    }
    get stockManagementsDropdownValues$() {
        return this._store.select(fromSelectors.selectStockManagementsDropdownValues);
    }
    get inventoriesDropdownValues$() {
        return this._store.select(fromSelectors.selectInventoriesDropdownValues);
    }
    get lastScannedProductId$() {
        return this._store.select(fromSelectors.lastScannedProductId);
    }

    // Dispatch actions
    cleanState() {
        this._store.dispatch(fromActions.CLEAN_STATE());
    }

    setLang(lang) {
        this._store.dispatch(fromActions.SET_LANG(lang));
    }

    loadLoggedUser() {
        this._store.dispatch(fromActions.LOAD_LOGGED_USER());
    }

    setLoggedUser(response: AccountModel) {
        this._store.dispatch(fromActions.SET_LOGGED_USER(response));
    }

    updateLoggedUser(response: AccountModel) {
        this._store.dispatch(fromActions.UPDATE_LOGGED_USER(response));
    }

    loadUserStatuses() {
        this._store.dispatch(fromActions.LOAD_USER_STATUSES());
    }

    loadRolesDropdownValues() {
        this._store.dispatch(fromActions.LOAD_ROLES_DROPDOWN_VALUES());
    }

    loadAccountsDropdownValues() {
        this._store.dispatch(fromActions.LOAD_ACCOUNTS_DROPDOWN_VALUES());
    }

    loadStockManagementsDropdownValues() {
        this._store.dispatch(fromActions.LOAD_STOCK_MANAGEMENTS_DROPDOWN_VALUES());
    }

    loadInventoriesDropdownValues(filters?: string) {
        this._store.dispatch(fromActions.LOAD_INVENTORIES_DROPDOWN_VALUES(filters));
    }

    setLastScannedProductId(id: number) {
        this._store.dispatch(fromActions.SET_LAST_SCANNED_PRODUCT_ID(id));
    }

    logout() {
        this._store.dispatch(fromActions.LOGOUT());
    }
}