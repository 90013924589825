<form [formGroup]="form" #top>
    <div class="row d-flex align-items-end flex-wrap">
        <div *ngFor="let filter of filters" class="{{ filter.style }} mb-3">
            <ng-container [ngSwitch]="filter.type">
                <app-date-interval-picker *ngSwitchCase="filterTypes.DATE_RANGE" [form]="form" [data_cy]="filter.data_cy" 
                    [filterControls]="filter.controls" [label]="filter.label" [defaultRange]="filter.defaultRange">
                </app-date-interval-picker>

                <div *ngSwitchCase="filterTypes.SELECT">
                    <app-dropdown-with-search [control]="form.controls[filter.controls[0]]" [label]="filter.label" [data_cy]="filter.data_cy"
                        [itemsList]="options[filter.resourceName]" [firstOptionMessage]="filter.firstOptionMessage"
                        [cypressSelector]="filter.dropdownId"></app-dropdown-with-search>
                </div>

                <div *ngSwitchCase="filterTypes.ENUM_SELECT">
                    <app-dropdown-with-enum-values [enumName]="filter.enumName" [label]="filter.label" [data_cy]="filter.data_cy"
                        [control]="form.controls[filter.controls[0]]" [firstOptionMessage]="filter.firstOptionMessage">
                    </app-dropdown-with-enum-values>
                </div>

                <div *ngSwitchCase="filterTypes.TEXT">
                    <div class="d-flex">
                        <app-simple-input-text *ngFor="let control of filter.controls; index as i" class="w-100" [label]="filter.label" [data_cy]="filter.data_cy"
                            [class.mr-3]="i < filter.controls?.length - 1" [control]="form.controls[filter.controls[i]]"
                            [placeholder]="filter.placeholders[i]"></app-simple-input-text>
                    </div>
                </div>

                <div *ngSwitchCase="filterTypes.BOOLEAN_SELECT">
                    <app-dropdown-with-booleans [itemsList]="options[filter.resourceName]" [label]="filter.label" [data_cy]="filter.data_cy"
                        [control]="form.controls[filter.controls[0]]" [firstOptionMessage]="filter.firstOptionMessage">
                    </app-dropdown-with-booleans>
                </div>
            </ng-container>
        </div>
        <div class="col-md-5 d-flex align-items-center {{ isMobile ? 'justify-content-around' : 'justify-content-end' }} ml-auto">
            <button class="btn btn-primary {{ isMobile ? 'btn-sm px-2':''}}" data-cy="apply-filters-btn"
                    (click)="save()">
                <span class="material-icons mr-1">search</span>
                {{ "ApplyFilters" | translate }}
            </button>
            <button class="btn btn-secondary {{ isMobile ? 'btn-sm px-2':''}}" data-cy="reset-filters-btn"
                    [class.btn-active]="parsedFilters?.length"
                    (click)="resetFilters()">
                <span class="material-icons mr-1">refresh</span>
                {{ "ResetFilters" | translate }}
            </button>
        </div>
    </div>
</form>