import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ConvertEmptyContentToLinePipe' })

export class ConvertEmptyContentToLinePipe implements PipeTransform {
    transform(row: any, field: string, targetField: string) {
        if (!row[targetField]) {
            return '-';
        }
        return row[field];
    }
}
