import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { InventoryFacade } from './store/inventory.facade';
import { isMobile } from 'src/app/shared/utils/responsive.utils';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { InventoryModel } from './models/inventory.model';
import { PageEvent } from '@angular/material/paginator';
import { InventoryModalComponent } from './components/inventory-modal/inventory-modal.component';
import { AppFacade } from 'src/app/store/app.facade';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { booleanOptions$ } from 'src/app/shared/constants';
import { TranslateService } from '@ngx-translate/core';
@UntilDestroy()
@Component({
    selector: 'app-inventories',
    templateUrl: './inventories.component.html',
    styleUrls: ['./inventories.component.css']
})
export class InventoriesComponent implements OnInit, OnDestroy {

    public PermissionsEnum = PermissionsEnum;
    public list$ = this._inventoryFacade.inventories$;
    public pagination$ = this._inventoryFacade.inventoriesPagination$;
    public loading$ = this._inventoryFacade.inventoryLoading$;
    public isMobile = isMobile();

    public filtersPayload: ApplyFiltersModel = {
        queryParams: {
            page: 1,
            records_number: 10
        },
        parsedFilters: ''
    }

    public filters: TableFilter[] = [
        {
            resourceName: 'stock_management_id',
            label: 'StockManagement',
            type: TableFilterTypesEnum.SELECT,
            controls: ['stock_management_id'],
            style: 'col-md-4',
            firstOptionMessage: 'SelectStockManagement',
            dropdownId: 'stock_management',
            optionsList$: this._appFacade.stockManagementsDropdownValues$,
            data_cy: 'stock-management-filter',
        },
        {
            resourceName: 'status',
            label: 'Status',
            type: TableFilterTypesEnum.BOOLEAN_SELECT,
            controls: ['status'],
            style: 'col-md-2',
            data_cy: 'status-filter',
            firstOptionMessage: 'All',
            dropdownId: 'status',
            optionsList$: booleanOptions$(this._translateService.instant('Open'), this._translateService.instant('Closed'), 'open', 'closed')
        },
        {
            resourceName: 'start_date',
            label: 'StartDate',
            data_cy: 'start-date-filter',
            type: TableFilterTypesEnum.DATE_RANGE,
            controls: ['start_date_start', 'start_date_end'],
            style: 'col-md-3',
        },
        {
            resourceName: 'end_date',
            label: 'EndDate',
            data_cy: 'end-date-filter',
            type: TableFilterTypesEnum.DATE_RANGE,
            controls: ['end_date_start', 'end_date_end'],
            style: 'col-md-3',
        }
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'StockManagement',
            fieldNames: ['stock_management.name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
            cellType: TableCellType.FULL_NAME,
        },
        {
            name: 'Code',
            fieldNames: ['stock_management.code'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
            cellType: TableCellType.FULL_NAME,
            hideOnMobile: true
        },
        {
            name: 'StartDate',
            fieldNames: ['start_date'],
            cssClasses: 'all text-center',
            cellType: TableCellType.DATE,
            searchable: true,
            sorting: true,
            hideOnMobile: true
        },
        {
            name: 'EndDate',
            fieldNames: ['end_date'],
            cssClasses: 'all text-center',
            cellType: TableCellType.INVENTORY_CLOSED,
            searchable: true,
            sorting: true
        },
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.INVENTORIES_ADD_AND_EDIT
        },
        {
            name: 'ExportQRForAllProducts',
            type: TableActionTypeEnum.ExportQRAll,
            permission: PermissionsEnum.QR_CODES_READ
        },
        {
            name: 'ExportQRForNewProductsAdded',
            type: TableActionTypeEnum.ExportQR,
            permission: PermissionsEnum.QR_CODES_READ
        }
    ];

    @ViewChild('table', { static: true }) table: TableFiltersComponent;

    constructor(
        private _inventoryFacade: InventoryFacade,
        private _dialogService: DialogService,
        private _appFacade: AppFacade,
        private _translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this._appFacade.loadStockManagementsDropdownValues();

    }

    ngOnDestroy(): void {
        this._inventoryFacade.cleanState();
    }

    tableActionClicked(e: any) {
        if (e.type === TableActionTypeEnum.Edit) {
            this.openEditModal(e.entry);
        }
        if (e.type === TableActionTypeEnum.ExportQRAll) {
            this._inventoryFacade.displayQRs(e.entry.id)
        }
        if (e.type === TableActionTypeEnum.ExportQR) {
            this._inventoryFacade.displayQRs(e.entry.id, 'true')
        }
    }

    addEntry() {
        this.openEditModal(null);
    }

    deleteEntry(entry) {
        this._inventoryFacade.deleteItem(entry.id);
        this.applyFilters();
    }

    private openEditModal(entry: InventoryModel | null) {
        this._dialogService
            .openModal(entry, InventoryModalComponent, DimensionsEnum.AUTO, '80vw')
            .afterClosed().pipe(untilDestroyed(this)).subscribe(() => this.applyFilters());
    }

    updatePage(event: PageEvent) {
        let queryParams = {
            page: event.pageIndex + 1,
            records_number: event.pageSize
        }
        this._inventoryFacade.loadList({ ...this.filtersPayload, queryParams: queryParams });
    }

    applyFilters(filters?: ApplyFiltersModel) {
        filters && (this.filtersPayload = filters);
        this._inventoryFacade.loadList(this.filtersPayload);
    }
}
