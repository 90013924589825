import { HttpErrorResponse } from "@angular/common/http";
import { createAction } from "@ngrx/store";
import { AccountModel } from "src/app/modules/accounts/models/account.model";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";

export const CLEAN_STATE = createAction('[Accounts] Clean state');
export const CLEAN_ITEM = createAction('[Accounts] Clean item');

export const LOAD_LIST = createAction('[Accounts] Load list', (payload: ApplyFiltersModel) => ({ payload }));
export const LOAD_LIST_SUCCESS = createAction('[Accounts] Load list success', (payload: PaginationModel) => ({ payload }));
export const LOAD_LIST_FAILURE = createAction('[Accounts] Load list fail', (error: HttpErrorResponse) => ({ error }));

export const LOAD_LIST_DROPDOWN_VALUES = createAction('[Accounts] Load list dropdown values', (payload?: string) => ({ payload }));
export const LOAD_LIST_DROPDOWN_VALUES_SUCCESS = createAction('[Accounts] Load list dropdown values success', (payload: Partial<AccountModel>[]) => ({ payload }));
export const LOAD_LIST_DROPDOWN_VALUES_FAILURE = createAction('[Accounts] Load list dropdown values fail', (error: HttpErrorResponse) => ({ error }));

export const SET_ITEM = createAction('[Accounts] Set item', (payload: AccountModel) => ({ payload }));
export const LOAD_ITEM = createAction('[Accounts] Load item', (id: number) => ({ id }));
export const LOAD_ITEM_SUCCESS = createAction('[Accounts] Load item success', (payload: AccountModel) => ({ payload }));
export const LOAD_ITEM_FAILURE = createAction('[Accounts] Load item fail', (error: HttpErrorResponse) => ({ error }));

export const CREATE = createAction('[Accounts] Create item', (payload: AccountModel) => ({ payload }));
export const CREATE_SUCCESS = createAction('[Accounts] Create item success', (payload: AccountModel) => ({ payload }));
export const CREATE_FAILURE = createAction('[Accounts] Create item fail', (error: HttpErrorResponse) => ({ error }));

export const UPDATE = createAction('[Accounts] Update item', (payload: AccountModel) => ({ payload }));
export const UPDATE_SUCCESS = createAction('[Accounts] Update item success', (payload: AccountModel) => ({ payload }));
export const UPDATE_FAILURE = createAction('[Accounts] Update item fail', (error: HttpErrorResponse) => ({ error }));

export const DELETE = createAction('[Accounts] Delete item', (id: number) => ({ id }));
export const DELETE_SUCCESS = createAction('[Accounts] Delete item success', (id: number) => ({ id }));
export const DELETE_FAILURE = createAction('[Accounts] Delete item fail', (error: HttpErrorResponse) => ({ error }));

export const UPDATE_PICTURE = createAction('[Accounts] Update picture', (id: number, img: File) => ({ id, img }));
export const UPDATE_PICTURE_SUCCESS = createAction('[Accounts] Update picture success', (payload: AccountModel) => ({ payload }));
export const UPDATE_PICTURE_FAILURE = createAction('[Accounts] Update picture fail', (error: HttpErrorResponse) => ({ error }));

export const REMOVE_PICTURE = createAction('[Accounts] Remove picture', (id: number) => ({ id }));
export const REMOVE_PICTURE_SUCCESS = createAction('[Accounts] Remove picture success', (payload: AccountModel) => ({ payload }));
export const REMOVE_PICTURE_FAILURE = createAction('[Accounts] Remove picture fail', (error: HttpErrorResponse) => ({ error }));