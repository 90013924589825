import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InvoiceSerieModel } from 'src/app/modules/brand-invoice-profile/models/brand-invoice-profile.model';

@Component({
  selector: 'app-send-to-invoice',
  templateUrl: './send-to-invoice.component.html',
  styleUrls: ['./send-to-invoice.component.css']
})
export class SendToInvoiceComponent implements OnInit {

  @Input() QRScan: boolean;
  @Input() form: FormGroup;
  @Input() invoiceSeriesList: InvoiceSerieModel[]
  @Output() sendToInvoice = new EventEmitter()

  constructor() { }

  ngOnInit(): void {}

  onSendToInvoice() {
    this.sendToInvoice.emit();
  }
}
