import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import * as fromActions from './settings.actions';
import * as fromState from './settings.state';

export const settingsReducer = createReducer(
    fromState.settingsInitialState,
    on(
        fromActions.CLEAN_STATE,
        () => fromState.adapter.getInitialState(fromState.settingsInitialState)
    ),

    on(
        fromActions.CLEAN_ITEM,
        (state) => produce(state, (draft) => {
            draft.selectedSetting = produce(fromState.settingsInitialState.selectedSetting, () => { });
        })
    ),

    on(
        fromActions.LOAD_LIST,
        (state) => produce(state, (draft) => {
            draft.loading = true;
            draft.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_LIST_SUCCESS,
        (state, action) => fromState.adapter.setAll(
            action.payload,
            produce(state, (draft) => {
                draft.loading = false;
                draft.error = undefined;
            }),
        )
    ),

    on(
        fromActions.LOAD_LIST_FAILURE,
        (state, action) => fromState.adapter.removeAll(
            produce(state, (draft) => {
                draft.loading = false;
                draft.error = action.error.error;
            })
        )
    ),
    on(
        fromActions.LOAD_ITEM,
        (state) => produce(state, (draft) => {
            draft.selectedSetting.item = undefined;
            draft.selectedSetting.loading = true;
            draft.selectedSetting.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_ITEM_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.selectedSetting.item = action.payload;
            draft.selectedSetting.loading = false;
            draft.selectedSetting.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_ITEM_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.selectedSetting.loading = false;
            draft.selectedSetting.error = action.error.error;
        })
    ),
    on(
        fromActions.UPDATE,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedSetting.loading = true;
                draft.selectedSetting.error = undefined;
            });
        }
    ),

    on(
        fromActions.UPDATE_SUCCESS,
        (state, action) => fromState.adapter.updateOne({ id: action.payload.id, changes: action.payload },
            produce(state, (draft) => {
                draft.selectedSetting.item = produce(state.selectedSetting.item, () => action.payload);
                draft.selectedSetting.loading = false;
                draft.selectedSetting.error = undefined;
            })
        )
    ),

    on(
        fromActions.UPDATE_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedSetting.loading = false;
                draft.selectedSetting.error = action.error.error;
                draft.selectedSetting.saveAndClose = false;
            })
    ),
)