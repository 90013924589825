import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromState from "./products.state";

export const selectProductState = createFeatureSelector<fromState.ProductState>('products');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = fromState.adapter.getSelectors(selectProductState);

// List selectors
export const selectPagination = createSelector(
    selectProductState,
    (state: fromState.ProductState) => state.pagination
);

export const selectLoading = createSelector(
    selectProductState,
    (state: fromState.ProductState) => state.loading
);

export const selectError = createSelector(
    selectProductState,
    (state: fromState.ProductState) => state.error
);

export const selectFilters = createSelector(
    selectProductState,
    (state: fromState.ProductState) => state.filters
);

// Item selectors

export const selectItem = createSelector(
    selectProductState,
    (state: fromState.ProductState) => state.selectedProduct.product
);

export const selectItemLoading = createSelector(
    selectProductState,
    (state: fromState.ProductState) => state.selectedProduct.loading
);

export const selectItemError = createSelector(
    selectProductState,
    (state: fromState.ProductState) => state.selectedProduct.error
);
export const selectItemSuccessResponse = createSelector(
    selectProductState,
    (state: fromState.ProductState) => state.selectedProduct.saveAndClose
);

