    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-4 {{isMobile ? 'pb-3': ''}}">
                <div class="card card-pricing card-raised h-100">
                    <div class="card-body">
                        <h6 class="card-category">{{ "AllStockManagements" | translate }}</h6>
                        <div class="card-icon icon-success"><i class="material-icons">all_inbox</i></div>
                        <h3 class="card-title mt-4">{{ stockManagementsCount }}</h3>
                        <p class="card-description">{{"StockManagements" | translate}}</p>
                        <div class="card-button">
                            <a href="/#/dashboard/stock-management" (click)="navigateTo('stock_managements', 'stock-management-records-tbl')" 
                                class="btn btn-success btn-round dashboard-tab">
                                {{ "StockManagements" | translate }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 {{isMobile ? 'pb-3': ''}}">
                <div class="card card-pricing card-raised h-100">
                    <div class="card-body">
                        <h6 class="card-category">{{ "AllOpenInventories" | translate }}</h6>
                        <div class="card-icon icon-info"><i class="material-icons">inventory</i></div>
                        <h3 class="card-title mt-4">{{ inventoriesCount }}</h3>
                        <p class="card-description">{{"Inventories" | translate}}</p>
                        <div class="card-button">
                            <a href="/#/dashboard/inventories" (click)="navigateTo('open_inventories', 'inventories-records-tbl')"
                                class="btn btn-info btn-round dashboard-tab">
                                {{ "Inventories" | translate }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 {{isMobile ? 'pb-3': ''}}">
                <div class="card card-pricing card-raised h-100">
                    <div class="card-body">
                        <h6 class="card-category">{{ "AllProductsInStockManagement" | translate }}</h6>
                        <div class="card-icon icon-warning"><i class="material-icons">qr_code</i></div>
                        <h3 class="card-title mt-4">{{productsCount}}</h3>
                        <p class="card-description">{{"Products" | translate}}</p>
                        <div class="card-button">
                            <a href="/#/dashboard/products" (click)="navigateTo('products', 'products-records-tbl')"
                                class="btn btn-warning btn-round dashboard-tab">
                                {{ "Products" | translate }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>