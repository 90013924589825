import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'DisplaySymbolPipe' })

export class DisplaySymbolPipe implements PipeTransform {
    constructor() { }

    transform(orderWorks: any[], value: string) {
        if (value === 'works_name_and_symbol') {
            let symbolsString = '';
            orderWorks?.forEach(work => {
                if (symbolsString.length) {
                    symbolsString += ', ' + work.symbol;
                } else {
                    work.symbol === null ? '' : symbolsString += work.symbol;
                }
            });
            return symbolsString;
        }
        return orderWorks;
    }
}
