import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import PerfectScrollbar from 'perfect-scrollbar';
import { filter } from 'rxjs';
import { BrandProfileModel } from 'src/app/modules/brand-profile/models/brand-profile.model';
import { BrandProfileService } from 'src/app/modules/brand-profile/services/brand-profile.service';
import { AppFacade } from 'src/app/store/app.facade';
import { AccountsModalComponent } from '../../../modules/accounts/components/accounts-modal/accounts-modal.component';
import { AccountModel } from '../../../modules/accounts/models/account.model';
import { PermissionsEnum } from '../../enums/Permissions.enum';
import { AuthService } from '../../services/auth.service';
import { DialogService } from '../../services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';
import { Router } from '@angular/router';
import { InventoryFacade } from 'src/app/modules/inventories/store/inventory.facade';

declare const $: any;
export const SCAN_ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'Language',
        type: 'sub',
        icontype: 'language',
        collapse: 'language',
        children: [
            { title: 'RO', value: 'ro' },
            { title: 'EN', value: 'en' }
        ]
    },
];
// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    permission?: string;
    queryParams?: any;
    data_cy?: string;
}

export interface ChildrenItems {
    path?: string;
    title: string;
    ab?: string;
    type?: string;
    permission?: string;
    value?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'GeneralPanel',
        type: 'link',
        icontype: 'dashboard',
        permission: PermissionsEnum.SELF_PROFILE_READ,
        data_cy: 'dashboard-menu-item'
    }, {
        path: '/dashboard/stock-management',
        title: 'StockManagements',
        type: 'link',
        icontype: 'all_inbox',
        permission: PermissionsEnum.STOCK_MANAGEMENTS_READ,
        data_cy: 'stock-management-menu-item'
    }, {
        path: '/dashboard/inventories',
        title: 'Inventories',
        type: 'link',
        icontype: 'inventory',
        permission: PermissionsEnum.INVENTORIES_READ,
        data_cy: 'inventories-menu-item'
    }, {
        path: '/dashboard/products',
        title: 'Products',
        type: 'link',
        icontype: 'qr_code',
        permission: PermissionsEnum.PRODUCTS_READ,
        data_cy: 'products-menu-item'
    }, {
        path: '/dashboard/invoices',
        title: 'Invoices',
        type: 'link',
        icontype: 'receipt_long',
        permission: PermissionsEnum.VIEW_INVOICES_LIST,
        data_cy: 'invoices1-menu-item'
    }
];
@UntilDestroy()
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit, AfterViewInit {

    public lastScannedProductId$ = this._appFacade.lastScannedProductId$;
    public loggedUser$ = this._appFacade.loggedUser$;
    public menuItems: any[];
    public PermissionsEnum = PermissionsEnum;
    public currentBrand: BrandProfileModel;
    public isScanView: boolean;
    public scanMenuItems: any[];
    public lang;
    public scannedProductId;
    public isUser: boolean;

    ps: any;

    @ViewChildren('parentMenuItem') parentMenuItem: QueryList<ElementRef>;

    constructor(
        private _appFacade: AppFacade,
        private _brandProfileService: BrandProfileService,
        private _dialogService: DialogService,
        private _authService: AuthService,
        public translateService: TranslateService,
        public langService: LangService,
        private _router: Router,
    ) {
        this._appFacade.loadUserStatuses();
    }

    ngOnInit() {
        this._brandProfileService.getCurrentBrandProfile();
        this.isScanView = this._router.url.includes('qr-scan');

        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.scanMenuItems = SCAN_ROUTES.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
        this._brandProfileService.currentBrandProfile$.pipe(filter(currentBrand => !!currentBrand), untilDestroyed(this))
            .subscribe(currentBrand => this.currentBrand = currentBrand);

        this._appFacade.langValue$.pipe(untilDestroyed(this)).subscribe(lang => this.lang = lang)
    }

    ngAfterViewInit(): void {
        this.parentMenuItem.forEach((item: any) => {
            const childrenList = item.nativeElement?.getElementsByTagName('div')[0]?.firstChild.children;
            if (childrenList) {
                for (const child of childrenList) {
                    if (!child.classList.contains('d-none')) {
                        item.nativeElement.classList.remove('d-none');
                    }
                }
            }
        });
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps && this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    toggleActive(e) {
        const navItems = document.querySelectorAll('.nav-item');
        navItems.forEach(nav => nav.classList.remove('active'));
        e.currentTarget.classList.add('active');
    }

    editUser(user: AccountModel) {
        const selfUpdateUser = {
            self_update: true,
            ...user
        };
        this._dialogService.openModal(selfUpdateUser, AccountsModalComponent, 'auto', '70vw');
    }

    logout() {
        this._authService.logout();
    }
}
