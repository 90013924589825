import { HttpErrorResponse } from "@angular/common/http";
import { createAction } from "@ngrx/store";
import { SettingModel } from "src/app/modules/settings/models/settings.model";

export const CLEAN_STATE = createAction('[Settings] Clean state');
export const CLEAN_ITEM = createAction('[Settings] Clean item');

export const LOAD_LIST = createAction('[Settings] Load list');
export const LOAD_LIST_SUCCESS = createAction('[Settings] Load list success', (payload: SettingModel[]) => ({ payload }));
export const LOAD_LIST_FAILURE = createAction('[Settings] Load list fail', (error: HttpErrorResponse) => ({ error }));

export const LOAD_ITEM = createAction('[Settings] Load item', (id: number) => ({ id }));
export const LOAD_ITEM_SUCCESS = createAction('[Settings] Load item success', (payload: SettingModel) => ({ payload }));
export const LOAD_ITEM_FAILURE = createAction('[Settings] Load item fail', (error: HttpErrorResponse) => ({ error }));

export const UPDATE = createAction('[Settings] Update item', (payload: SettingModel) => ({ payload }));
export const UPDATE_SUCCESS = createAction('[Settings] Update item success', (payload: SettingModel) => ({ payload }));
export const UPDATE_FAILURE = createAction('[Settings] Update item fail', (error: HttpErrorResponse) => ({ error }));
