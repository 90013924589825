import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-item-to-show',
  templateUrl: './no-item-to-show.component.html',
  styleUrls: ['./no-item-to-show.component.css']
})
export class NoItemToShowComponent implements OnInit {

  @Input() message: string;

  constructor() { }

  ngOnInit(): void {  
  }

}
