import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BrandInvoiceProfileModalComponent } from 'src/app/modules/brand-invoice-profile/components/brand-invoice-profile-modal/brand-invoice-profile-modal.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { PermissionsEnum } from '../../shared/enums/Permissions.enum';
import { TableCellType } from '../../shared/enums/TableCellType.enum';
import { BrandInvoiceProfileModel } from './models/brand-invoice-profile.model';
import { BrandInvoiceProfileService } from './services/brand-invoice-profile.service';
import { isMobile } from 'src/app/shared/utils/responsive.utils';

@Component({
    selector: 'app-brand-invoice-profile',
    templateUrl: './brand-invoice-profile.component.html',
    styleUrls: ['./brand-invoice-profile.component.css']
})
export class BrandInvoiceProfileComponent implements OnInit, OnDestroy {
    public isMobile = isMobile();
    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Name',
            fieldNames: ['name', 'is_default_profile'],
            cssClasses: '',
            cellType: TableCellType.DEFAULT_PROFILE,
        },
        {
            name: 'CUI',
            fieldNames: ['fiscal_code'],
            cssClasses: '',
        },
        {
            name: 'Address',
            fieldNames: ['address'],
            cssClasses: '',
        },
        {
            name: 'Mobile',
            fieldNames: ['mobile1'],
            cssClasses: '',
        },
        {
            name: 'PhoneFax',
            fieldNames: ['phone_fax'],
            cssClasses: '',
        },
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.BRAND_INVOICE_PROFILE_ADD_AND_EDIT
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.BRAND_INVOICE_PROFILE_DELETE
        },
        {
            name: 'Default',
            type: TableActionTypeEnum.Default,
            permission: PermissionsEnum.BRAND_INVOICE_PROFILE_ADD_AND_EDIT
        }
    ];

    private _subscriptions: Subscription[];
    public currentBrandProfile: BrandInvoiceProfileModel;
    public brandInvoiceProfiles: BrandInvoiceProfileModel[];
    public defaultBrandInvoiceProfile: BrandInvoiceProfileModel;
    public PermissionsEnum = PermissionsEnum;

    constructor(
        public sweetAlertService: SweetAlertService,
        public brandInvoiceProfileService: BrandInvoiceProfileService,
        private _dialogService: DialogService) { }

    ngOnInit(): void {
        this.brandInvoiceProfileService.getBrandInvoiceProfiles();

        this._subscriptions = [
            this.brandInvoiceProfileService.itemsList$
                .pipe(filter(brandInvoiceProfile => !!brandInvoiceProfile))
                .subscribe(brandInvoiceProfile => this.brandInvoiceProfiles = brandInvoiceProfile),
        ];
    }

    ngOnDestroy() {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    tableActionClicked(e: any) {
        if (e.type === TableActionTypeEnum.Edit) {
            this._dialogService.openModal(e.entry, BrandInvoiceProfileModalComponent, DimensionsEnum.AUTO);
        }

        if (e.type === TableActionTypeEnum.Delete) {
            const item = `BrandProfile`;
            this.sweetAlertService.showSwal('warning-message', () => this.deleteEntry(e.entry), item);
        }

        if (e.type === TableActionTypeEnum.Default) {
            this.setDefault(e.entry);
        }
    }

    private deleteEntry(entry: BrandInvoiceProfileModel) {
        this.brandInvoiceProfileService.deleteBrandInvoiceProfile(entry);
    }

    private setDefault(entry: BrandInvoiceProfileModel) {
        this.brandInvoiceProfileService.setDefaultBrandInvoiceProfile(entry);
    }

    addEntry() {
        this._dialogService.openModal(null, BrandInvoiceProfileModalComponent, DimensionsEnum.AUTO);
    }
}
