<app-gradient-border-title>{{ "InvoiceSeriesList" | translate }}</app-gradient-border-title>

<div class="spacer-2"></div>

<ng-container *ngIf="invoiceSeriesList?.length; else noInvoiceSerie">
    <div class="row subheader-title align-items-end pb-2">
        <div class="col-1 text-center">{{ "Index" | translate | uppercase }}</div>
        <div class="col-3">{{ 'Name' | translate | uppercase }}</div>
        <div class="col-2 text-center">{{ 'StartInvoiceNo' | translate | uppercase }}</div>
        <div class="col-2 text-center">{{ 'VAT' | translate | uppercase }}</div>
        <div class="col-2 text-center">{{ 'Currency' | translate | uppercase }}</div>
        <div class="col-2 text-center">{{ 'Default' | translate | uppercase }}</div>
    </div>
    <ng-container *ngFor="let invoiceSerie of invoiceSeriesList; index as i">
        <div class="row special-stage" [class.special-stage-even]="i % 2 === 0">
            <div class="col-1 text-center">{{ i + 1 }}</div>
            <div class="col-3">{{ invoiceSerie.series }}</div>
            <div class="col-2 text-center">{{ invoiceSerie.start_no }}</div>
            <div class="col-2 text-center">{{ invoiceSerie.vat }}%</div>
            <div class="col-2 text-center">{{ invoiceSerie.currency }}</div>
            <div class="col-2 text-center"
                [class.disabled-input]="invoiceSerie.active == 1 && currentActiveInvoiceSerie == invoiceSerie.id">
                <mat-slide-toggle [checked]="invoiceSerie.active == 1 && currentActiveInvoiceSerie == invoiceSerie.id"
                    (click)="activateInvoiceSerie(invoiceSerie.id)">
                </mat-slide-toggle>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #noInvoiceSerie>
    <app-no-item-to-show [message]="'NoInvoiceSerie'"></app-no-item-to-show>
</ng-template>