import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { SettingModel } from "src/app/modules/settings/models/settings.model";
import * as fromActions from "./settings.actions";
import * as fromSelectors from "./settings.selectors";

@Injectable()
export class SettingsFacade {
    constructor(private _store: Store) { }

    // Select the selectors
    get settings$() {
        return this._store.select(fromSelectors.selectAll);
    }

    get settingsLoading$() {
        return this._store.select(fromSelectors.selectLoading);
    }

    get settingsError$() {
        return this._store.select(fromSelectors.selectError);
    }


    get setting$() {
        return this._store.select(fromSelectors.selectItem);
    }

    get settingLoading$() {
        return this._store.select(fromSelectors.selectItemLoading);
    }

    get settingError$() {
        return this._store.select(fromSelectors.selectItemError);
    }

    // Dispatch actions
    cleanState() {
        this._store.dispatch(fromActions.CLEAN_STATE());
    }

    cleanItem() {
        this._store.dispatch(fromActions.CLEAN_ITEM());
    }

    loadList() {
        this._store.dispatch(fromActions.LOAD_LIST());
    }

    loadItem(id: number) {
        this._store.dispatch(fromActions.LOAD_ITEM(id));
    }

    updateItem(payload: SettingModel) {
        this._store.dispatch(fromActions.UPDATE(payload));
    }
}