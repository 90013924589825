import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import * as fromActions from './invoices.actions';
import * as fromState from './invoices.state';

export const invoicesReducer = createReducer(
    fromState.invoicesInitialState,
    on(
        fromActions.CLEAN_STATE,
        () => fromState.adapter.getInitialState(fromState.invoicesInitialState)
    ),

    on(
        fromActions.LOAD_LIST,
        (state, action) => produce(state, (draft) => {
            draft.loading = true;
            draft.error = undefined;
            draft.filters = action.payload;
        })
    ),

    on(
        fromActions.LOAD_LIST_SUCCESS,
        (state, action) => fromState.adapter.setAll(
            action.payload.data,
            produce(state, (draft) => {
                const { data, ...rest } = action.payload;
                draft.pagination = rest
                draft.loading = false;
                draft.error = undefined;
            })
        )
    ),

    on(
        fromActions.LOAD_LIST_FAILURE,
        (state, action) => fromState.adapter.removeAll(
            produce(state, (draft) => {
                draft.loading = false;
                draft.error = action.error.error;
            })
        )
    ),

    on(
        fromActions.LOAD_ITEM,
        (state) => produce(state, (draft) => {
            draft.selectedInvoice.invoice = undefined;
            draft.selectedInvoice.loading = true;
            draft.selectedInvoice.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_ITEM_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.selectedInvoice.invoice = action.payload;
            draft.selectedInvoice.loading = false;
            draft.selectedInvoice.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_ITEM_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.selectedInvoice.loading = false;
            draft.selectedInvoice.error = action.error.error;
        })
    ),

    on(
        fromActions.GENERATE_PDF,
        (state, action) => produce(state, (draft) => {
            draft.selectedInvoice.loading = true;
            draft.selectedInvoice.error = undefined;
        })
    ),

    on(
        fromActions.GENERATE_PDF_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.selectedInvoice.loading = false;
            draft.selectedInvoice.error = undefined;
        })
    ),

    on(
        fromActions.GENERATE_PDF_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.selectedInvoice.loading = false;
            draft.selectedInvoice.error = action.error.error;
        })
    ),
    on(
        fromActions.REVERSE_INVOICE,
        (state, action) => produce(state, (draft) => {
            draft.selectedInvoice.loading = true;
            draft.selectedInvoice.error = undefined;
            draft.selectedInvoice.invoice = undefined;
        })
    ),
    on(
        fromActions.REVERSE_INVOICE_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.selectedInvoice.loading = false;
            draft.selectedInvoice.invoice = action.payload;
        })
    ),
    on(
        fromActions.REVERSE_INVOICE_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.selectedInvoice.loading = false;
            draft.selectedInvoice.error = action.error.error;
        })
    ),

);