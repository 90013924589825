import { TranslateService } from '@ngx-translate/core';
import { Component, Input } from '@angular/core';
import { parseMultiselectFilters } from 'src/app/shared/utils/form.utils';

@Component({
  selector: 'app-download-xls-pdf-buttons',
  templateUrl: './download-xls-pdf-buttons.component.html',
  styleUrls: ['./download-xls-pdf-buttons.component.css']
})
export class DownloadXlsPdfButtonsComponent {

  @Input() service: any;
  @Input() name: string;
  @Input() filters: any;
  @Input() reportContent: any;

  constructor(private _translateService: TranslateService) { }

  downloadXLS(): void {
    this.service.generateReportXLS(this.getFileName(), parseMultiselectFilters(this.filters));
  }

  downloadPDF(): void {
    this.service.generateReportPDF(this.getFileName(), parseMultiselectFilters(this.filters));
  }

  getFileName(): string {
    return `${this._translateService.instant(this.name)}: ${this.filters?.start_date} - ${this.filters?.end_date}`;
  }
}
