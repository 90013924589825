import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromState from "./inventory.state";

export const selectInventoryState = createFeatureSelector<fromState.InventoryState>('inventory');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = fromState.adapter.getSelectors(selectInventoryState);

// List selectors
export const selectPagination = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.pagination
);

export const selectLoading = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.loading
);

export const selectError = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.error
);

export const selectFilters = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.filters
);

// Item selectors

export const selectItem = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.inventory
);

export const selectItemLoading = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.loading
);

export const selectItemError = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.error
);
export const selectItemSuccessResponse = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.saveAndClose
);


export const selectInventoryProducts = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.products.products
);

export const selectInventoryProductsLoading = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.products.loading
);

export const selectInventoryProductsError = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.products.error
);

export const selectInventoryProduct = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.selectedInventoryProduct.product
);

export const selectInventoryProductLoading = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.selectedInventoryProduct.loading
);

export const selectInventoryProductError = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.selectedInventoryProduct.error
);
export const massUpdateSuccessfull = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.massUpdateSuccessfull
);
export const importedProducts = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.products.import
);
export const importProductsSuccess = createSelector(
    selectInventoryState,
    (state: fromState.InventoryState) => state.selectedInventory.products.importSuccess
);

