<form [formGroup]="form">
    <div class="row mt-5">
        <div class="col-md-6 d-flex flex-column">
            <h4 class="font-weight-bold">Logo {{ "Brand" | translate | lowercase }}</h4>
            <div class="fileinput fileinput-new text-center mb-3">
                <div class="fileinput-new thumbnail">
                    <img src="{{currentLogo}}"
                        onerror='this.onerror = null; this.src="./assets/img/image_placeholder.jpg"' class="w-100"
                        alt="CurrentLogo">
                </div>
            </div>
            <h5>{{ "RecommendedDimensions" | translate }} logo (350 x 350px)</h5>
            <div class="fileinput fileinput-new text-center" data-provides="fileinput">
                <div class="fileinput-new thumbnail">
                    <img src="./assets/img/image_placeholder.jpg" alt="...">
                </div>
                <div class="fileinput-preview fileinput-exists thumbnail"></div>
                <div>
                    <span class="btn btn-primary btn-round btn-file">
                        <span class="fileinput-new">{{"SelectImage" | translate}}</span>
                        <span class="fileinput-exists">{{"Change" | translate}}</span>
                        <input #inputLogoToUpload type="file" name="logo" (change)="handleFileInput($event.target.files, 'logo')" />
                    </span>
                    <button #removeLogoImg class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput" (click)="removeImage(inputLogoToUpload, 'logo')">
                        <i class="fa fa-times"></i> {{"Delete" | translate}}
                    </button>
                </div>
            </div>
        </div>

        <div class="col-md-6 d-flex flex-column">
            <h4 class="font-weight-bold">Cover {{ "Brand" | translate | lowercase }}</h4>
            <div class="fileinput fileinput-new text-center mb-3">
                <div class="fileinput-new thumbnail">
                    <img src="{{currentCover}}"
                        onerror='this.onerror = null; this.src="./assets/img/image_placeholder.jpg"' class="w-100"
                        alt="CurrentCover">
                </div>
            </div>
            <h5>{{ "RecommendedDimensions" | translate }} cover (828 x 315px)</h5>
            <div class="fileinput fileinput-new text-center" data-provides="fileinput">
                <div class="fileinput-new thumbnail">
                    <img src="./assets/img/image_placeholder.jpg" alt="...">
                </div>
                <div class="fileinput-preview fileinput-exists thumbnail"></div>
                <div>
                    <span class="btn btn-primary btn-round btn-file">
                        <span class="fileinput-new">{{"SelectImage" | translate}}</span>
                        <span class="fileinput-exists">{{"Change" | translate}}</span>
                        <input #inputCoverToUpload type="file" name="cover" (change)="handleFileInput($event.target.files, 'cover')" />
                    </span>
                    <button #removeCoverImg class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput" (click)="removeImage(inputCoverToUpload, 'cover')">
                        <i class="fa fa-times"></i> {{"Delete" | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="mb-0">
        <div class="w-100 modal-footer border-0 p-0 pt-4">
            <button type="button" class="btn btn-secondary pull-right" (click)="closeModal.emit()">{{ 'Close' | translate }}</button>
            <button class="btn btn-primary pull-right" (click)="saveAndExit()">{{ 'SaveAndClose' |translate }}</button>
            <button class="btn btn-primary pull-right" (click)="onSubmit()">{{ 'Save' | translate }}</button>
        </div>
    </div>
</form>