<div class="position-relative" *ngIf="selectedInvoice | async as invoice">
    <div class="row dialog-top-header">
        <div class="col-12 dialog-pre-title">
            <small>{{"Invoices" | translate}}</small>
        </div>
        <div class="col-10">
            <h1 class="mb-0" mat-dialog-title>
                {{ "View" | translate }} {{ "Invoice" | translate | lowercase }}:
                {{ invoice.invoice_series?.series}} -
                {{ invoice.number }} {{ "FromDate" | translate | lowercase }}
                {{ invoice.date | date:'dd.MM.yyyy' }}
            </h1>
        </div>
        <div class="col-2 d-flex justify-content-end">
            <button class="btn btn-secondary-custom small-btn ml-3" (click)="dialogRef.close()">
                <span class="material-icons">close</span>
            </button>
        </div>
    </div>

    <div class="mat-mdc-dialog-content">
        <app-invoice-general-info-tab [selectedInvoice]="invoice"></app-invoice-general-info-tab>
    </div>

    <div class="full-loading-overlay" *ngIf="this.invoiceFacade.isLoading$ | async">
        <span class="spinner"></span>
    </div>
</div>