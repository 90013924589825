import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appCustomPermissions]'
})
export class CustomPermissionsDirective implements OnInit {

  @Input() appCustomPermissions: any;

  private _permissionsList: any[] = [];

  constructor(
    private _element: ElementRef) { }

  ngOnInit(): void {
    const permissions = Object.entries(this.appCustomPermissions);
    let hideBtn = true;
    permissions.forEach(permission => {
      if (permission[1] === true) {
        this._permissionsList.push(permission[0]);
        hideBtn = false;
      };
    });

    if(hideBtn) this._element.nativeElement.classList.add('d-none');

  }
}
