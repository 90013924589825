import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableDataHeaders, TableHeadersTypeEnum } from 'src/app/shared/models/table-data-headers.model';
import { isMobile } from 'src/app/shared/utils/responsive.utils';
import { ProductsFacade } from './store/products.facade';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { AppFacade } from 'src/app/store/app.facade';
import { ProductModel } from './models/product.model';
import { PageEvent } from '@angular/material/paginator';
import { booleanOptions$ } from 'src/app/shared/constants';
import { ProductModalComponent } from './components/product-modal/product-modal.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, OnDestroy {

    public PermissionsEnum = PermissionsEnum;
    public list$ = this._productsFacade.products$;
    public pagination$ = this._productsFacade.productsPagination$;
    public loading$ = this._productsFacade.productsLoading$;
    public isMobile = isMobile();

    public filtersPayload: ApplyFiltersModel = {
        queryParams: {
            page: 1,
            records_number: 10
        },
        parsedFilters: ''
    }

    public filters: TableFilter[] = [
        {
            resourceName: 'stock_management_id',
            label: 'StockManagement',
            type: TableFilterTypesEnum.SELECT,
            controls: ['stock_management_id'],
            style: 'col-md-3',
            firstOptionMessage: 'SelectStockManagement',
            dropdownId: 'stock_management',
            optionsList$: this._appFacade.stockManagementsDropdownValues$
        },
        {
            resourceName: 'name',
            label: 'ProductName',
            type: TableFilterTypesEnum.TEXT,
            controls: ['name'],
            style: 'col-md-3',
            placeholders: [''],
        },
        {
            resourceName: 'external_number',
            label: 'InventoryNumber',
            type: TableFilterTypesEnum.TEXT,
            controls: ['external_number'],
            style: 'col-md-3',
            placeholders: [''],
            data_cy: 'inventory-no-filter'
        },
        {
            resourceName: 'internal_number',
            label: 'InternalNumber',
            type: TableFilterTypesEnum.TEXT,
            controls: ['internal_number'],
            style: 'col-md-3',
            placeholders: [''],
        },
        {
            resourceName: 'status',
            label: 'Status',
            type: TableFilterTypesEnum.ENUM_SELECT,
            controls: ['status'],
            style: 'col-md-3',
            firstOptionMessage: 'SelectStatus',
            dropdownId: 'status',
            enumName: 'inventory_product_statuses',
        },
        {
            resourceName: 'in_stock',
            label: 'InStock',
            type: TableFilterTypesEnum.BOOLEAN_SELECT,
            controls: ['in_stock'],
            style: 'col-md-3',
            firstOptionMessage: 'All',
            dropdownId: 'in_stock',
            optionsList$: booleanOptions$(),
            data_cy: 'in-stock'
        },
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'ProductName',
            fieldNames: ['name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
            cellType: TableCellType.FULL_NAME,
        },
        {
            name: 'StockManagement',
            fieldNames: ['stock_management.name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
            // cellType: TableCellType.FULL_NAME,
        },
        {
            name: 'InventoryNumber/InternalNumber',
            fieldNames: ['external_number', 'internal_number'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            type: TableHeadersTypeEnum.MULTIPLE_ELEMENTS,
        },
        {
            name: 'MeasurementUnit',
            fieldNames: ['measurement_unit'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            hideOnMobile: true
        },
        {
            name: 'Price',
            fieldNames: ['price'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            hideOnMobile: true
        },
        {
            name: 'EntryDate',
            fieldNames: ['entry_inventory.start_date'],
            cssClasses: 'all text-center',
            cellType: TableCellType.DATE_FORMAT_ON_MULTIPLE_ELEMENTS,
            searchable: true,
            sorting: true,
            hideOnMobile: true
        },
        // {
        //     name: 'ExitDate',
        //     fieldNames: ['inventory_product.inventory.end_date'],
        //     cssClasses: 'all text-center',
        //     cellType: TableCellType.DATE_FORMAT_ON_MULTIPLE_ELEMENTS,
        //     searchable: true,
        //     sorting: true,
        //     hideOnMobile: true
        // },
        {
            name: 'InOpenInventory',
            fieldNames: ['inventory_product.inventory.end_date'],
            cssClasses: 'all text-center',
            cellType: TableCellType.LETTER_MARK,
            searchable: true,
            sorting: true,
            hideOnMobile: true
        },
        {
            name: 'Status',
            fieldNames: ['inventory_product.status'],
            cssClasses: 'all text-center',
            cellType: TableCellType.STATUS_BADGE,
            searchable: true,
            sorting: true,
            hideOnMobile: true
        },
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.PRODUCTS_ADD_AND_EDIT
        },
        {
            name: 'ExportQR',
            type: TableActionTypeEnum.ExportQR,
            permission: PermissionsEnum.QR_CODES_READ
        }
    ];

    @ViewChild('table', { static: true }) table: TableFiltersComponent;

    constructor(
        private _productsFacade: ProductsFacade,
        private _dialogService: DialogService,
        private _sweetAlertService: SweetAlertService,
        private _appFacade: AppFacade,
    ) { }

    ngOnInit(): void {
        this._appFacade.loadStockManagementsDropdownValues();

    }

    ngOnDestroy(): void {
        this._productsFacade.cleanState();
    }

    tableActionClicked(e: any) {
        if (e.type === TableActionTypeEnum.Edit) {
            this.openEditModal(e.entry);
        }
        if (e.type === TableActionTypeEnum.ExportQR) {
            this._productsFacade.getQRCode(e.entry.id);
        }
    }

    addEntry() {
        this.openEditModal(null);
    }


    private openEditModal(entry: ProductModel | null) {
        this._dialogService
            .openModal(entry, ProductModalComponent, DimensionsEnum.AUTO, '70vw')
            .afterClosed().pipe(untilDestroyed(this)).subscribe(() => this.applyFilters());
    }

    updatePage(event: PageEvent) {
        let queryParams = {
            page: event.pageIndex + 1,
            records_number: event.pageSize
        }
        this._productsFacade.loadList({ ...this.filtersPayload, queryParams: queryParams });
    }

    applyFilters(filters?: ApplyFiltersModel) {
        filters && (this.filtersPayload = filters);
        this._productsFacade.loadList(this.filtersPayload);
    }
}
