import { Directive, Input, ComponentFactoryResolver, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[appValueOrActionDirective]'
})
export class ValueOrActionDirective {

  @Input() appValueOrActionDirective: any;
  @Input() textPosition: string;
  @Input () component: any;
  @Output() actionEmit: EventEmitter<number> = new EventEmitter();

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef) {}

    ngOnInit() {
      if (this.appValueOrActionDirective?.temp_value) {
        this.createComponent();
      }
    }

    createComponent() {
      const componentFactory = this._componentFactoryResolver.resolveComponentFactory(this.component);
      const component = this._viewContainerRef.createComponent(componentFactory);
    }
}
