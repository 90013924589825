import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import * as fromActions from './stock-management.actions';
import * as fromState from './stock-management.state';

export const stockManagementsReducer = createReducer(
    fromState.stockManagementInitialState,
    on(
        fromActions.CLEAN_STATE,
        () => fromState.adapter.getInitialState(fromState.stockManagementInitialState)
    ),

    on(
        fromActions.CLEAN_ITEM,
        (state) => produce(state, (draft) => {
            draft.selectedStockManagement = produce(fromState.stockManagementInitialState.selectedStockManagement, () => { });
        })
    ),

    on(
        fromActions.LOAD_LIST,
        (state, action) => produce(state, (draft) => {
            draft.loading = true;
            draft.error = undefined;
            draft.filters = action.payload;
        })
    ),

    on(
        fromActions.LOAD_LIST_SUCCESS,
        (state, action) => fromState.adapter.setAll(
            action.payload.data,
            produce(state, (draft) => {
                const { data, ...rest } = action.payload;
                draft.pagination = rest
                draft.loading = false;
                draft.error = undefined;
            })
        )
    ),

    on(
        fromActions.LOAD_LIST_FAILURE,
        (state, action) => fromState.adapter.removeAll(
            produce(state, (draft) => {
                draft.loading = false;
                draft.error = action.error.error;
            })
        )
    ),

    on(
        fromActions.SET_ITEM,
        (state, action) => produce(state, (draft) => {
            draft.selectedStockManagement.stockManagement = action.payload;
        })
    ),

    on(
        fromActions.LOAD_ITEM,
        (state) => produce(state, (draft) => {
            draft.selectedStockManagement.stockManagement = undefined;
            draft.selectedStockManagement.loading = true;
            draft.selectedStockManagement.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_ITEM_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.selectedStockManagement.stockManagement = action.payload;
            draft.selectedStockManagement.loading = false;
            draft.selectedStockManagement.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_ITEM_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.selectedStockManagement.loading = false;
            draft.selectedStockManagement.error = action.error.error;
        })
    ),

    on(
        fromActions.CREATE,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedStockManagement.loading = true;
                draft.selectedStockManagement.error = undefined;
            });
        }
    ),
    on(
        fromActions.CREATE_SUCCESS,
        (state, action) => {
            return fromState.adapter.addOne(action.payload,
                produce(state, (draft) => {
                    draft.selectedStockManagement.stockManagement = produce(state.selectedStockManagement.stockManagement, () => action.payload);
                    draft.selectedStockManagement.loading = false;
                    draft.selectedStockManagement.error = undefined;
                    draft.selectedStockManagement.saveAndClose = true;
                })
            )
        }
    ),

    on(
        fromActions.CREATE_FAILURE,
        (state, action) => {
            return produce(state, (draft) => {
                draft.selectedStockManagement.loading = false;
                draft.selectedStockManagement.error = action.error.error;
                draft.selectedStockManagement.saveAndClose = false;
            })
        }
    ),

    on(
        fromActions.UPDATE,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedStockManagement.loading = true;
                draft.selectedStockManagement.error = undefined;

            });
        }
    ),

    on(
        fromActions.UPDATE_SUCCESS,
        (state, action) => fromState.adapter.updateOne({ id: action.payload.id, changes: action.payload },
            produce(state, (draft) => {
                draft.selectedStockManagement.stockManagement = produce(state.selectedStockManagement.stockManagement, () => action.payload);
                draft.selectedStockManagement.loading = false;
                draft.selectedStockManagement.error = undefined;
                draft.selectedStockManagement.saveAndClose = true;

            })
        )
    ),

    on(
        fromActions.UPDATE_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedStockManagement.loading = false;
                draft.selectedStockManagement.error = action.error.error;
                draft.selectedStockManagement.saveAndClose = false;
            })
    ),


    on(
        fromActions.SETCLOSETOFALSE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedStockManagement.saveAndClose = false;
            })
    ),
);