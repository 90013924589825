import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/shared/modules/material.module';

import { DashboardComponent } from './dashboard.component';
import { DashboardRoutes } from './dashboard.routing';
import { httpTranslateLoader } from '../../shared/components/tables/tables.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AdminLayoutComponent } from 'src/app/shared/layouts/admin/admin-layout.component';
import { SidebarModule } from 'src/app/shared/components/sidebar/sidebar.module';
import { NavbarModule } from 'src/app/shared/components/navbar/navbar.module';
import { MdModule } from 'src/app/shared/components/md/md.module';
import { FooterModule } from 'src/app/shared/components/footer/footer.module';
import { MatCommonModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { DashboardTabsComponent } from './dashboard-tabs/dashboard-tabs.component';


@NgModule({
    providers: [AuthGuard],
    declarations: [
        DashboardComponent,
        AdminLayoutComponent,
        DashboardTabsComponent,
    ],
    imports: [
        MatCommonModule,
        RouterModule.forChild(DashboardRoutes),
        FormsModule,
        ReactiveFormsModule,
        MdModule,
        HttpClientModule,
        MaterialModule,
        NavbarModule,
        FooterModule,
        SidebarModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        DirectivesModule,
        PipesModule,
        SharedComponentsModule,
    ]
})

export class DashboardModule { }