<div class="background-pattern">
    <div class="logo d-flex align-items-center">
        <a class="simple-text logo-mini">
            <div class="logo-image">
                <img src="{{ currentBrand?.logo }}"
                    class="logo-img"
                    *ngIf="currentBrand?.logo; else fallbackLogo">
                <ng-template #fallbackLogo>
                    <img src="../../assets/img/company_logo.png"
                        alt="img"
                        class="logo-img">
                </ng-template>
            </div>
        </a>
    </div>
</div>
<div class="wrapper">
    <div class="sidebar" data-color="accent" data-background-color="black" data-image="./assets/img/sidebar1.jpg">
        <app-sidebar-cmp></app-sidebar-cmp>
        <div class="sidebar-background" style="background-image: url(assets/img/sidebar1.jpg)"></div>
    </div>
    <div class="main-panel d-flex flex-column" >
        <app-navbar-cmp class="d-flex"></app-navbar-cmp>
        <router-outlet></router-outlet>

    </div>
</div>