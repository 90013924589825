import { Component, Input, OnInit } from '@angular/core';
import { InvoiceModel } from '../../../../models/invoice.model';


@Component({
    selector: 'app-invoices-to-export-list',
    templateUrl: './invoices-to-export-list.component.html',
    styleUrls: ['./invoices-to-export-list.component.css']
})
export class InvoicesToExportListComponent implements OnInit {

    @Input() invoicesList: InvoiceModel[];

    constructor() { }

    ngOnInit(): void {
    }

}
