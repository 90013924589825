<ul class="navbar-nav">
    <li class="nav-item dropdown">
        <a class="ws-dashboard-icon {{ menu?.classes }}" href="javascript:void(0)" id="{{ menu.name }}" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">{{ menu.icon }}</i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" [attr.aria-labelledby]="menu.name">
            <ng-container *ngFor="let item of menu.items">
                <a class="dropdown-item"
                    href="javascript:void(0)"
                    (click)="triggerMenuItem(item)"
                    [appRestrictPermissions]="item.permissions">
                    <span class="material-icons mr-3" *ngIf="item.icon">{{ item.icon }}</span>
                    {{ item.name | translate }}
                </a>
            </ng-container>
        </div>
    </li>
</ul>