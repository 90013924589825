import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.css']
})
export class BadgeComponent {

  @Input() message: string;
  @Input() textColor: string;
  @Input() badgeColor: string;

  constructor() { }
}
