import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FormErrorService } from './form-error.service';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    public readonly onSaveSuccess$: Subject<any> = new Subject();
    public readonly isLoading$: Subject<boolean> = new Subject();

    constructor(
        public formErrorService: FormErrorService) { }

    public reportError(message: string, response: HttpErrorResponse) {
        this.isLoading$.next(false);
        this.formErrorService.reportError$.next(response);
        this.onSaveSuccess$.next(undefined);
        console.error(message);
    }
}
