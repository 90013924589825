<div class="row d-flex justify-content-around">
    <div class="col-6 text-center d-flex flex-column align-items-center mt-5 mb-0 justify-content-between" *ngIf="!fileToUpload">
        <h5>{{"DownloadImportFileModel" | translate }}</h5>
        <div class="small pt-2 text-danger">*{{"SideNote1" | translate }}</div>
        <div>
            <div *ngIf="downloadUrl; else apiRequestToDownload">
                <a [href]="downloadUrl" class="btn btn-outline fileinput-exists btm-small mt-2" type="button">
                    <i class="material-icons">download</i> {{ "DownloadFileModel" | translate }}
                </a>
            </div>
            <ng-template #apiRequestToDownload>
                <button class="btn btn-outline fileinput-exists btm-small mt-2" type="button" (click)="downloadTemplate.emit()">
                    <i class="material-icons">download</i> {{ "DownloadFileModel" | translate }}
                </button>
            </ng-template>
        </div>
    </div>
    <div class="col-6 fileinput fileinput-new text-center d-flex flex-column align-items-center mt-5 mb-0 justify-content-between" data-provides="fileinput">
        <h5>{{"UploadImportFile" | translate }}</h5>
        <div class="mr-2">
            <span class="material-icons">attach_file</span>
        </div>
        <div class="fileinput-exists mt-1">
            <span>{{ fileToUpload?.name }}</span>
        </div>  
        <div class="d-flex flex-column align-items-center">
            <div class="mt-2">
                
                <!-- <div class="pt-2 text-center">
                    <span class="small">{{ "SideNote2" | translate }}</span>
                </div> -->
                <span class="btn btn-primary btm-small btn-file mt-2">
                    <span class="fileinput-new"> <i class="material-icons">upload</i> {{"SelectFile" | translate}}</span>
                    <span class="fileinput-exists"><i class="material-icons">undo</i> {{"Change" | translate}}</span>
                    <input #takeInput type="file" id="upload" name="cover" [accept]="fileType" (change)="handleFileInput($event.target.files)" (click)="resetInputValue()" />
                </span>
                <button class="btn btn-danger fileinput-exists  ml-2" #dismissFileInput data-dismiss="fileinput" (click)="dismissFile()">
                    <i class="material-icons">close</i> {{"Delete" | translate}}
                </button>
            </div>
            <button id="file-input-ready" class="btn btn-primary fileinput-exists mt-2" type="button" (click)="uploadFile()">
                <i class="material-icons">upload</i> {{ "UploadFile" | translate }}
            </button>
            <div class="fileinput-exists mt-4">
                <div class="d-flex align-items-center justify-content-center ">
                    <mat-checkbox id="overwrite" [(ngModel)]="overwriteStockManagement" (change)="onUpdateOverwriteStockManagement($event)" >{{"OverwriteStockManagement"| translate}}</mat-checkbox>
                    <!-- <mat-label for="overwrite"></mat-label> -->
                </div>
                <div class="small" [class.text-danger]="overwriteStockManagement">*{{"SideNote2" | translate }}</div>
            </div>
        </div>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-6">
        <div *ngIf="errors" class="errors-wrapper">
            <div class="spacer-2"></div>
            <div *ngFor="let error of errors | keyvalue; index as i" class="small text-danger">
                <p class="font-weight-bolder mt-3 mb-0" *ngIf="checkErrorLine(+error.key.split('.')[0], i)">{{"Line" | translate }} {{ +error.key.split('.')[0] + 2 }}: </p> 
                <span class="mr-2">{{ "ErrorInColumn" | translate }} <span class="font-weight-bold">{{ error.key.split('.')[1] | translate | uppercase}}</span>:</span>
                <span>{{ error.value[0] }}</span>
            </div>
            <div class="spacer-2"></div>
        </div>
    </div>
</div>
