<div *rerender="id">
    <div>
    <div class="spacer-4"></div>
    <div *ngIf="inventoryProduct$| async as product">
        <ng-container *ngIf="product.inventory_product; else noInventoryOpenForTheProduct">
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <h2 class="col-12 gradient-border-title">{{product.stock_management?.name}}</h2>
                </div>
                <div class="d-flex mt-3 product-info-card">
                    <div class="col-12 d-flex flex-column align-items-center py-3">
                        <div class="d-flex">
                            <div class="p-0">{{ "IsScanned" | translate }}:</div>
                            <div class="border-bottom-0 pt-0 pl-2">
                                <p class="text-right font-weight-bold medium mb-0 p-0 text-accent">{{ totalScanned }} / {{ totalProducts }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center product-info-card py-4 mb-0">
                    <h4 class="font-weight-bold text-center mb-4">{{product.name}}</h4>
                    <p class="mb-3 medium">{{"InventoryNumber" | translate}}: <span class="font-weight-bold text-accent">{{product.external_number}}</span></p>
                    <p class="mb-1 small">{{"InternalNumber" | translate}}: <span class="font-weight-bold">{{product.internal_number | translate}}</span></p>
                    <p class="mb-1 small">{{"ScannedAt" | translate}}: 
                        <span class="font-weight-bold" *ngIf="product.inventory_product.scanned_at;else notScanned">
                        {{ (utcConvert(product.inventory_product.scanned_at) | date : 'dd.MM.YYYY') || '-'}} - {{ (utcConvert(product.inventory_product.scanned_at) | date : 'HH:mm') || '-'}}
                        </span>
                        <ng-template #notScanned> - </ng-template>
                    </p>

                    <p class="mb-1 small">{{"ScannedBy" | translate}}: <span class="font-weight-bold">{{userProductScan?.scanned_by_user?.first_name}} {{userProductScan?.scanned_by_user?.last_name}}</span></p>
                    <p class="mb-0 small">{{"CurrentStatus" | translate}}: <span class="badge badge-{{product.inventory_product.status}}" data-cy="product-status">{{ product.inventory_product.status | translate}}</span></p>
                </div>
                <div class="spacer-2"></div>
                <div class="status-update text-center">
                    <p>{{"ChooseProductStatus" | translate}}:</p>
                </div>
                <div class="buttons-group pb-3 row">
                    <div class="col-6 d-flex flex-column align-items-end">
                        <button data-cy="not-processed-btn"
                            class="btn btn-sm btn-fixed-width align-items-center px-3 mb-3" 
                            [class.status-not-processed-btn-active]="product.inventory_product.status === 'not_processed'" 
                            [class.status-not-processed-btn]="product.inventory_product.status !== 'not_processed'" 
                            (click)="updateProductStatus(product.id,'not_processed')">
                            <span class="font-weight-bold">{{"not_processed" | translate }}</span>
                        </button>
                        <button data-cy="in-inventory-btn"
                            class="btn btn-sm btn-fixed-width align-items-center" 
                            [class.status-in-inventory-btn-active]="product.inventory_product.status === 'in_inventory'" 
                            [class.status-in-inventory-btn]="product.inventory_product.status !== 'in_inventory'" 
                            (click)="updateProductStatus(product.id,'in_inventory')">
                            <span class="font-weight-bold">{{"in_inventory" | translate }}</span>
                        </button>
                    </div>
                    <div class="col-6 d-flex flex-column align-items-start">
                        <button data-cy="disposed-btn"
                            class="btn btn-sm btn-fixed-width align-items-center mb-3" 
                            [class.status-disposed-btn-active]="product.inventory_product.status === 'disposed'" 
                            [class.status-disposed-btn]="product.inventory_product.status !== 'disposed'" 
                            (click)="updateProductStatus(product.id,'disposed')">
                            <span class="font-weight-bold">{{"ProductDisposalRequest" | translate }}</span>
                        </button>
                        <button data-cy="charged-btn"
                            class="btn btn-sm btn-fixed-width align-items-center" 
                            [class.status-charged-btn-active]="product.inventory_product.status === 'charged'" 
                            [class.status-charged-btn]="product.inventory_product.status !== 'charged'" 
                            (click)="updateProductStatus(product.id,'charged')">
                            <span class="font-weight-bold">{{"ProductChargeRequest" | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    
        <ng-template #noInventoryOpenForTheProduct>
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <h2 class="col-12 gradient-border-title">{{product.name}}</h2>
                </div>
                <div class="d-flex flex-column align-items-center mt-3 product-info-card py-4">
                    <p class="mb-1 small">{{"InternalNumber" | translate}}: <span class="font-weight-bold">{{product.internal_number}}</span></p>
                    <p class="mb-1 small">{{"InventoryNumber" | translate}}: <span class="font-weight-bold">{{product.external_number | translate}}</span></p>
                    <p class="mb-1 small">{{"StockManagement" | translate}}: <span class="font-weight-bold">{{product.stock_management.name}}</span></p>
                    <p class="mb-0 small">{{"Status" | translate}}: <span class="font-weight-bold">{{ "ProductInClosedInventory" | translate }}</span></p>
                </div>
            </div>
        </ng-template>
    </div>
    </div>
</div>
