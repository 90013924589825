import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import * as fromActions from "./products.actions";
import * as fromSelectors from "./products.selectors";

@Injectable()
export class ProductsFacade {
    constructor(private _store: Store) { }

    // Select the selectors
    get products$() {
        return this._store.select(fromSelectors.selectAll);
    }

    get productsPagination$() {
        return this._store.select(fromSelectors.selectPagination);
    }

    get productsLoading$() {
        return this._store.select(fromSelectors.selectLoading);
    }

    get productsError$() {
        return this._store.select(fromSelectors.selectError);
    }

    get productsFilters$() {
        return this._store.select(fromSelectors.selectFilters);
    }

    get product$() {
        return this._store.select(fromSelectors.selectItem);
    }

    get productLoading$() {
        return this._store.select(fromSelectors.selectItemLoading);
    }

    get productError$() {
        return this._store.select(fromSelectors.selectItemError);
    }
    get productSuccessResponse$() {
        return this._store.select(fromSelectors.selectItemSuccessResponse);
    }


    // Dispatch actions
    cleanState() {
        this._store.dispatch(fromActions.CLEAN_STATE());
    }

    cleanItem() {
        this._store.dispatch(fromActions.CLEAN_ITEM());
    }

    loadList(payload: ApplyFiltersModel) {
        this._store.dispatch(fromActions.LOAD_LIST(payload));
    }

    loadItem(id: number) {
        this._store.dispatch(fromActions.LOAD_ITEM(id));
    }

    setItem(payload: any) {
        this._store.dispatch(fromActions.SET_ITEM(payload));
    }

    createItem(payload: any) {
        this._store.dispatch(fromActions.CREATE(payload));
    }

    updateItem(payload: any) {
        this._store.dispatch(fromActions.UPDATE(payload));
    }

    setSaveAndCloseToFalse() {
        this._store.dispatch(fromActions.SETCLOSETOFALSE());
    }

    getQRCode(id) {
        this._store.dispatch(fromActions.DISPLAY_QR(id));
    }

}