import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { SettingsFacade } from 'src/app/modules/settings/store/settings.facade';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { SettingModel } from '../../models/settings.model';
import { SettingsService } from '../../services/settings.service';

@UntilDestroy()
@Component({
    selector: 'app-settings-modal',
    templateUrl: './settings-modal.component.html',
    styleUrls: ['./settings-modal.component.css']
})
export class SettingsModalComponent extends BaseModalComponent implements OnInit {

    public form: FormGroup;
    public setting: SettingModel;
    public loading$ = this._settingsFacade.settingLoading$;

    constructor(
        public dialogRef: MatDialogRef<SettingsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SettingModel,
        private _settingsFacade: SettingsFacade,
        private _settingsService: SettingsService,
        private _fb: FormBuilder) {
        super(dialogRef, _settingsService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.data?.id && this._settingsFacade.loadItem(this.data.id);

        this.form = this._fb.group({
            value: '',
        });

        this._settingsFacade.setting$.pipe(filter(setting => !!setting), untilDestroyed(this)).subscribe((setting: SettingModel) => {
            this.setting = setting;
            this.form.controls.value.setValue(this.setting.value);
        });
    }

    saveAndClose() {
        this._saveAndClose = true;
        this.onSubmit();
    }

    onSubmit() {
        this._settingsFacade.updateItem({
            id: this.data.id,
            ...this.form.value
        });
    }

}
