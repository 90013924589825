<div class="row dialog-top-header">
    <div class="col-12 dialog-pre-title">
        <small>{{'Roles' | translate}}</small>
    </div>
    <div class="col-10">
        <h1 class="mb-0"
            mat-dialog-title
            *ngIf="data?.id; else add_role">
            {{'Edit' | translate}}: {{selectedRole?.name}}
        </h1>
        <ng-template #add_role>
            <h1 class="mb-0"
                mat-dialog-title>
                {{'AddRole' | translate}}
            </h1>
        </ng-template>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn"
                (click)="dialogRef.close()">
            <span class="material-icons">close</span>
        </button>
    </div>
</div>

<div class="position-relative">
    <div class="loading-overlay"
         *ngIf="loading$ | async">
        <span class="spinner"></span>
    </div>

    <div mat-mdc-dialog-content>
        <form [formGroup]="form"
              (ngSubmit)="onSubmit()"
              appFormValidator>
            <div class="form-row mt-5">
                <div class="col-md-12">
                    <mat-form-field class="customized">
                        <mat-label for="name">{{ 'Name' | translate }}:</mat-label>
                        <input matInput
                               type="text"
                               id="name"
                               autocomplete="off"
                               formControlName="name"
                               required>
                        <mat-error>
                            <app-form-field-error [control]="form.controls['name']"></app-form-field-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-row mb-2 list-wrapper"
                 *ngIf="selectedRole?.id">
                <div class="col-12"
                     *ngFor="let category of permissionsList">

                    <div class="spacer-2"></div>
                    <app-gradient-border-title>{{ category.categoryName }}</app-gradient-border-title>

                    <div class="row category-permissions py-3"
                         *ngFor="let subcategory of category.subcategories; index as subcategoryIndex">
                        <div class="col-md-2 d-flex align-items-center">
                            <h6 class="font-weight-bold mb-0">{{ subcategory.subCategory }}</h6>
                        </div>
                        <div class="col-md-9">
                            <app-roles-check-all [permissions]="subcategory.permissions"
                                                 (permissionsChange)="changePermissions($event)">
                            </app-roles-check-all>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <app-dialog-footer-buttons class="w-100"
                               (close)="dialogRef.close()"
                               (saveAndClose)="saveAndClose()"
                               (save)="onSubmit()">
    </app-dialog-footer-buttons>
</div>