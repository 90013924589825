<div class="d-flex justify-content-{{ isCentered? 'center' : 'end' }}">
    <div class="col-4" [class.d-none]="!totalNotImported && !totalNotScanned">
        <div class="card d-flex flex-column my-0">
            <h5 class="card-section-title text-center text-uppercase small mb-2 backgound-text-warning">{{ "NeedAttention" | translate }}!</h5>
            <div class="d-flex flex-row">
                <div class="body d-flex justify-content-center col-{{totalNotScanned? 6:12}} p-0" *ngIf="totalNotImported">
                    <div class="card-header">
                        <h5 class="card-title text-center font-weight-bolder mb-0 text-active" [attr.data-cy]="'total-not-imported'+tab">{{ totalNotImported }}</h5>
                        <span class="small pt-0">{{ "ProductsNotImported" | translate }}</span>
                    </div>
                </div>
                <div class="body d-flex justify-content-center col-{{totalNotImported? 6:12}} p-0" *ngIf="totalNotScanned">
                    <div class="card-header">
                        <h5 class="card-title text-center font-weight-bolder mb-0 text-active" [attr.data-cy]="'total-not-scanned'+tab">{{ totalNotScanned }}</h5>
                        <span class="small pt-0">{{ "ProductsNotScanned" | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-8">
        <div class="card d-flex flex-column my-0">
            <h5 class="card-section-title text-center text-uppercase small mb-2 backgound-text-info">{{ "InventoryProductStatus" | translate }}</h5>
            <div class="d-flex flex-row">
                <div class="body d-flex justify-content-center col-3 p-0">
                    <div class="card-header">
                        <h5 class="card-title text-center font-weight-bolder mb-0 text-inactive" [attr.data-cy]="'total-not-processed'+tab">{{ totalNotProcessed }}</h5>
                        <span class="small pt-0">{{ "ProductsUnprocessed" | translate }}</span>
                    </div>
                </div>
                <div class="body d-flex justify-content-center col-3 p-0">
                    <div class="card-header">
                        <h5 class="card-title text-center font-weight-bolder mb-0 text-in-inventory" [attr.data-cy]="'total-in-inventory'+tab">{{ totalInInventory }}</h5>
                        <span class="small pt-0">{{ "ProductsInInventory" | translate }}</span>
                    </div>
                </div>        
                <div class="body d-flex justify-content-center col-3 p-0">
                    <div class="card-header">
                        <h5 class="card-title text-center font-weight-bolder mb-0 text-disposed" [attr.data-cy]="'total-disposed'+tab">{{ totalDisposed }}</h5>
                        <span class="small pt-0">{{ "ProductsDisposed" | translate }}</span>
                    </div>
                </div>
                <div class="body d-flex justify-content-center col-3 p-0">
                    <div class="card-header">
                        <h5 class="card-title text-center font-weight-bolder mb-0 text-charged" [attr.data-cy]="'total-charged'+tab">{{ totalCharged }}</h5>
                        <span class="small pt-0">{{ "ProductsCharged" | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>