import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { AccountModel } from "src/app/modules/accounts/models/account.model";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import { StockManagementsModel } from "../models/stock-management.model";

export interface StockManagementState extends EntityState<StockManagementsModel> {
    error: string | null;
    loading: boolean;
    pagination: Omit<PaginationModel, 'data'>;
    filters: ApplyFiltersModel;

    selectedStockManagement: {
        stockManagement: StockManagementsModel | undefined;
        error: string | null;
        loading: boolean;
        saveAndClose: boolean;
    }
}

export const adapter: EntityAdapter<StockManagementsModel> = createEntityAdapter<StockManagementsModel>();

export const stockManagementInitialState: StockManagementState = adapter.getInitialState({
    ids: [],
    entities: {},
    pagination: null,
    error: null,
    loading: false,
    filters: undefined,

    selectedStockManagement: {
        stockManagement: undefined,
        error: null,
        loading: false,
        saveAndClose: false
    }
});