<ng-container *ngIf="invoicesList?.length; else noInvoiceFound">
    <div class="row subheader-title align-items-end pb-2">
        <div class="col-1 small text-center">{{ "Index" | translate | uppercase }}</div>
        <div class="col-2 small text-center">{{ "InvoiceSeriesAndNo" | translate | uppercase }}</div>
        <div class="col-1 small text-center text-nowrap">{{ "InvoiceDue" | translate | uppercase }}</div>
        <div class="col-2 small text-center">{{ 'InvoiceType' | translate | uppercase }}</div>
        <div class="col-2 small">{{ 'Company / Owner' | translate | uppercase }}</div>
        <div class="col-2 small">{{ 'Market / RentalSpace' | translate | uppercase }}</div>
        <div class="col-1 small text-right text-nowrap">{{ 'ValueWithVat' | translate | uppercase }}</div>
        <div class="col-1 small">{{ 'User' | translate | uppercase }}</div>
    </div>

    <ng-container *ngFor="let invoice of invoicesList; index as i">
        <div class="row special-stage" [class.special-stage-even]="i % 2 === 0">
            <div class="col-1 text-center small">{{ i + 1 }}</div>
            <div class="col-2 d-flex flex-column small text-center">
                <span class="font-weight-bold">{{ invoice.invoice_series }} - {{ invoice.invoice_no }}</span>
                <span>{{ invoice.invoice_date | date : "dd.MM.yyyy" }}</span>
            </div>
            <div class="col-1 text-center">
                <span class="small">{{ invoice.invoice_due_date }}</span>
            </div>
            <div class="col-2 small text-center">
                <span #cell>{{ invoice.type | LabelPipe : cell : invoice : 'type' | translate }}</span>
            </div>
            <div class="col-2 d-flex flex-column small">
                <span class="font-weight-bold">{{ invoice.owner_company_name }}</span>
                <span class="font-weight-lighter">{{ invoice.owner_last_name }} {{ invoice.owner_first_name
                    }}</span>
            </div>
            <div class="col-2 d-flex flex-column small">
                <ng-container *ngIf="invoice.subscription_payment">
                    <span class="font-weight-bold">{{
                        invoice.subscription_payment?.subscription.rental_space.market.name }}</span>
                    <span class="font-weight-lighter">{{ invoice.subscription_payment?.subscription.rental_space.name
                        }}</span>
                </ng-container>
                <ng-container *ngIf="invoice.daily_rental">
                    <span class="font-weight-bold">{{ invoice.daily_rental?.rental_space.market.name }}</span>
                    <span class="font-weight-lighter">{{ invoice.daily_rental.rental_space.name }}</span>
                </ng-container>
            </div>
            <div class="col-1 d-flex flex-column small text-right">
                <span class="font-weight-bold">{{ invoice.value | number:'1.2-2':'en-GB' | noComma }}</span>
            </div>
            <div class="col-1 small">{{ invoice.user.first_name }} {{ invoice.user.last_name }}</div>
        </div>
    </ng-container>
</ng-container>

<ng-template #noInvoiceFound>
    <app-no-item-to-show [message]="'NoInvoiceFound'"></app-no-item-to-show>
</ng-template>