import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApplyFiltersModel } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { parseFilters } from 'src/app/shared/utils/filters.utils';
import { InventoryModel, InventoryProductModel } from '../models/inventory.model';
import { ProductModel } from '../../products/models/product.model';

@Injectable({
    providedIn: 'root'
})

export class InventoryService extends BaseService {
    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService) {
        super(_formErrorService);
    }


    getInventories$(payload: ApplyFiltersModel): Observable<PaginationModel> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.inventories}${parseFilters(payload)}`);
    }

    createInventory$(inventory: InventoryModel): Observable<InventoryModel> {
        return this.apiRequestService.submitPostRequest(apiEndpoints.inventories, inventory).pipe(map(res => ({ ...res.data })));
    }


    getInventory$(id: number): Observable<InventoryModel> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.inventories}/${id}`).pipe(map(res => ({ ...res.data })));
    };


    updateInventory$(inventory: InventoryModel): Observable<InventoryModel> {
        return this.apiRequestService.submitPutRequest(apiEndpoints.inventories + '/' + inventory.id, inventory).pipe(map(res => ({ ...res.data })));
    }

    removeInventory$(id: number): Observable<null> {
        return this.apiRequestService.submitDeleteRequest(apiEndpoints.inventories + '/' + id);
    }

    closeInventory$(id: number): Observable<InventoryModel> {
        return this.apiRequestService.submitPutRequest(`${apiEndpoints.inventories}/${id}/close`).pipe(map(res => ({ ...res.data })));
    }

    importFormat$(): Observable<any> {
        return this.apiRequestService.submitGetRequest(apiEndpoints.importFormat, { observe: 'response', responseType: 'blob' }).pipe(map(res => res));
    }

    importProducts$(formData: FormData, id: number): Observable<any> {
        return this.apiRequestService.submitPostRequest(`${apiEndpoints.inventories}/${id}/import`, formData).pipe(map(res => res));
    }

    getInventoryProducts$(id: number, filters?: string): Observable<any> {
        return this.apiRequestService.submitGetRequest(filters ? `${apiEndpoints.inventories}/${id}/inventory-products${filters}` : `${apiEndpoints.inventories}/${id}/inventory-products`).pipe(map(res => res.data));
    }

    updateInventoryProduct$(id: number, status: string, filters?: string): Observable<any> {
        return this.apiRequestService.submitPutRequest(`${apiEndpoints.inventoryProducts}/${id}`, { status: status }).pipe(map(res => ({ ...res.data, filters: filters })));
    }

    displayQRs$(id: number, params?: string): Observable<any> {
        return this.apiRequestService.submitGetRequest(params ? `${apiEndpoints.inventories}/${id}/qr-pdf?filter[newly_added_products]=true` : `${apiEndpoints.inventories}/${id}/qr-pdf`, { observe: 'response', responseType: 'blob' }).pipe(map(res => res));
    }

    scanProduct$(id: number, status: string): Observable<InventoryProductModel> {
        return this.apiRequestService.submitPutRequest(`${apiEndpoints.products}/${id}/scan`, status && { status: status }).pipe(map(res => ({ ...res.data })));
    }

    massUpdate$(productIds: number[], status: string, inventory_id: number, filters?: string): Observable<InventoryProductModel> {
        return this.apiRequestService.submitPutRequest(`${apiEndpoints.inventoryProducts}/status`, { inventory_products_ids: productIds, status: status, inventory_id: inventory_id }).pipe(map(res => ({ ...res.data, inventory_id: inventory_id, filters: filters })));
    }
    massUpdateProductStockManagement$(payload: any, inventory_id: number, filters?: string): Observable<InventoryProductModel> {
        return this.apiRequestService.submitPutRequest(`${apiEndpoints.products}/stock-management`, payload).pipe(map(res => ({ ...res.data, inventory_id: inventory_id, filters })));
    }

    getProduct$(id: number): Observable<ProductModel> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.products}/${id}`).pipe(map(res => ({ ...res.data })));
    };
}
