<section class="table-list-section child-dashboard-section">
    <div class="container">
        <div class="row"
             [appRestrictPermissions]="PermissionsEnum.BRAND_INVOICE_PROFILE_READ">
            <div class="col-12 {{!isMobile ? 'd-flex': ''}} justify-content-between">
                <span>{{ 'InvoiceProfiles' | translate | uppercase }}</span>
                <div class="{{isMobile ? 'd-flex justify-content-end mt-3': ''}}">
                    <button mat-raised-button
                            class="btn btn-white text-dark {{isMobile ? 'btn-sm': ''}}"
                            (click)="addEntry()"
                            [appRestrictPermissions]="PermissionsEnum.BRAND_INVOICE_PROFILE_ADD_AND_EDIT">
                        <i class="material-icons mr-1">add</i> {{ "Add" | translate }}
                    </button>
                </div>
            </div>
            <div class="col-12">
                <app-regular-table #table
                                   [headerRow]="tableHeaders"
                                   [dataList]="brandInvoiceProfileService.itemsList$ | async"
                                   [rowPossibleActions]="rowPossibleActions"
                                   [tableId]="'brand-invoice-profiles-records-tbl'"
                                   (actionType)="tableActionClicked($event)">
                </app-regular-table>
            </div>
        </div>
    </div>
</section>