<ng-container *ngFor="let field of cell.fieldNames">
    <ng-container [ngSwitch]="cell.cellType">
        <span *ngSwitchCase="tableCellType.TOOLTIP" [appCreateTooltip]="row" [fieldName]="field"
            [tooltipStyle]="cell.cellContentStyle" [tooltipValue]="getValueFromObject(row, field)"
            class="table-cell table-{{cell.type}} {{field}}" style="cursor: pointer;">
        </span>

        <span *ngSwitchCase="tableCellType.DEFAULT_PROFILE" [appCreateBadge]="row" [fieldName]="field"
            [badgeValue]="getValueFromObject(row, field)" class="table-cell table-{{cell.type}} {{field}}"
            style="cursor: pointer;">
        </span>

        <span *ngSwitchCase="tableCellType.FULL_NAME">
            {{ row | SplitFieldsPipe : field }}
        </span>

        <span *ngSwitchCase="tableCellType.STATUS_BADGE">
            <span class="badge badge-{{(row | SplitFieldsPipe : field)}}">{{  (row | SplitFieldsPipe : field) | translate}}</span>
        </span>
        
        <span *ngSwitchCase="tableCellType.LETTER_MARK">
            <span class="test badge-outlined-danger" *ngIf="(row | SplitFieldsPipe : field) === undefined"><span class="p-1 font-weight-bold">P</span></span>
        </span>

        <span *ngSwitchCase="tableCellType.DASH_SEPARATED">
            {{ row | DashSepareFieldsPipe : cell.fieldNames : field }}
        </span>

        <span #tableCell *ngSwitchCase="tableCellType.CUSTOM_LABEL_NUMBER">
            {{row[field] | CustomSheetsNumberLabel : tableCell : row : field | translate }}
        </span>

        <span *ngSwitchCase="tableCellType.PERCENT" class="d-block text-right">
            {{ row[field] }}%
        </span>

        <span *ngSwitchCase="tableCellType.PRICE" #tableCell class="table-cell table-{{cell.type}} {{field}}">
            {{ row | ModelWithSubPropertiesPipe : field : tableCell | priceFormating }}
        </span>

        <span *ngSwitchCase="tableCellType.DATE" class="d-block text-md-center">
            {{ row[field] | date : 'dd.MM.yyyy' }}
        </span>
        <span *ngSwitchCase="tableCellType.INVENTORY_CLOSED" class="d-block text-md-center">
            <div *ngIf="row[field]; else noEndDate">
                {{row[field] | date : 'dd.MM.yyyy'}}
            </div>
            <ng-template #noEndDate>
                <span class="badge badge-primary">{{"InProgress" | translate}}</span>
            </ng-template>
        </span>

        <span *ngSwitchCase="tableCellType.DATE_FORMAT_ON_MULTIPLE_ELEMENTS" #tableCell
            class="table-cell table-{{cell.type}} {{field}}">
            {{ row | ModelWithSubPropertiesPipe : field : tableCell | date : 'dd.MM.yyyy'  }}
        </span>

        <span *ngSwitchCase="tableCellType.DATE_HOUR_FORMAT" class="d-block text-center">
            <div>{{ row[field] | date : 'dd.MM.yyyy' }}</div>
            <span class="text-table-small">{{ row[field] | date : 'HH:mm:ss' }}</span>
        </span>
        <span *ngSwitchCase="tableCellType.DATE_HOUR_FORMAT_WITH_MULTIPLE_PROPS" class="d-block text-center">
            <span class="{{cell.block ? 'd-block' : 'mr-1' }}">{{ (row[field] | date : 'dd.MM.yyyy') || '-'}}</span>
            <span class="small">{{ row[field] | date : 'HH:mm' }}</span>
        </span>

        <span *ngSwitchCase="tableCellType.SUBSCRIPTIONS_REQUIRED_ACTIONS" [appSubscriptionsRequiredActions]="row"
            field="{{field}}">
        </span>

        <span *ngSwitchCase="tableCellType.EMPTY_CONTENT_TO_LINE" class="table-cell table-{{cell.type}} {{field}}">
            {{ (row | SplitFieldsPipe : field) ? (row | SplitFieldsPipe : field) : "-"}}
        </span>

        <div *ngSwitchCase="tableCellType.BOOLEAN_LABEL" class="text-center">
            <span class="badge badge-{{row[field] ? 'success' : 'danger'}}">{{row[field] ? ("Yes" | translate) : ("No" |
                translate)}}</span>
        </div>

        <span #tableCell *ngSwitchCase="tableCellType.LABEL">
            {{row[field] | LabelPipe : tableCell : row : field | translate }}
        </span>

        <span #tableCell *ngSwitchCase="tableCellType.BADGE_OR_DATE">
            {{row[field] | BadgeOrDate : tableCell : row : field : element : tableCell | translate }}
        </span>

        <span #tableCell *ngSwitchCase="tableCellType.SUBSTRACT_LABEL">
            {{row | SubsctractLabelPipe : tableCell | translate }}
        </span>

        <span #tableCell *ngSwitchCase="tableCellType.TRANSLATE" class="table-cell table-{{cell.type}} {{field}}">
            {{row | ModelWithSubPropertiesPipe : field : tableCell | translate }}
        </span>

        <span *ngSwitchDefault #tableCell [appTransformToIcons]="row" class="table-cell table-{{cell.type}} {{field}}"
            style="cursor: pointer;" currentCell="{{field}}" [appCreatedAt]="cell" fieldValue="{{row[field]}}">
            {{ row |
            ModelWithSubPropertiesPipe : field : tableCell |
            UserStatusesPipe : tableCell : userStatuses : row |
            DisplaySymbolPipe : field }}
        </span>
    </ng-container>
</ng-container>