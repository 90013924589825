<div class="spacer-2"></div>
<app-gradient-border-title>{{ "InvoiceServices" | translate }}</app-gradient-border-title>

<div class="row subheader-title pb-2 text-uppercase">
    <div class="col-1 small text-center">{{ "#" | translate }}</div>
    <div class="col-6 small">{{ "Details" | translate }}</div>
    <div class="col-1 small text-center">{{ "Quantity" | translate }}</div>
    <div class="col-2 small text-center">{{ "MeasurementUnit" | translate }}</div>
    <div class="col-1 small text-right">{{ "UnitaryPrice" | translate }}</div>
    <div class="col-1 small text-right">{{ "Price" | translate }}</div>
</div>

<div class="row service-cost-row" *ngFor="let service of selectedInvoice.invoice_services; index as i"
    [appStrippedTable]="i">
    <div class="col-1 text-center"> {{ i + 1 }}</div>
    <div class="col-6">{{ service.service_name }}</div>
    <div class="col-1 text-center">{{ service.service_quantity | number: '1.0-0' }}</div>
    <div class="col-2 text-center">{{ service.service_measurement_unit | translate }}</div>
    <div class="col-1 text-right"> {{ service.unitary_service_price | number:'1.2-2':'en-GB' | noComma }}</div>
    <div class="col-1 text-right">{{ service.service_value_including_vat | number:'1.2-2':'en-GB' | noComma }}</div>
</div>

<div class="row total-row">
    <div class="col-6">{{ "Total" | translate }}</div>
    <div class="col-6 text-right">
        {{ totalValue | number : '1.2-2' }} {{ selectedInvoice.invoice_series?.currency }}
    </div>
</div>

<div class="spacer-2"></div>