import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-date-interval-picker',
    templateUrl: './date-interval-picker.component.html',
    styleUrls: ['./date-interval-picker.component.css']
})
export class DateIntervalPickerComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() label: string;
    @Input() filterControls: string[];
    @Input() defaultRange: any;
    @Input() data_cy: string;

    constructor() { }

    ngOnInit(): void {
        const startDate = this.form.controls[this.filterControls[0]];
        const endDate = this.form.controls[this.filterControls[1]];

        startDate.setValue(startDate.value || (this.defaultRange?.start ? moment(this.defaultRange?.start).format('YYYY-MM-DD') : ''), { emitEvent: false });
        endDate.setValue(endDate.value || (this.defaultRange?.end ? moment(this.defaultRange?.end).format('YYYY-MM-DD') : ''), { emitEvent: false });
    }
}
