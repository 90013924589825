import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class FormErrorService {

    reportError$: BehaviorSubject<HttpErrorResponse> = new BehaviorSubject(undefined)

    renderServerErrors(form: FormGroup, response: any) {
        if (response.error.errors) {
            const errors = response.error.errors;
            for (const error in errors) {
                const fieldName = error;
                const message = errors[error][0];
                this.setFieldError(form, fieldName, message);
            }
        }
    }

    hasWrongValue(formControl: AbstractControl): boolean {
        return !!this.getFieldErrors(formControl).length;
    }

    // Render one error for a field
    getFieldError(formControl: AbstractControl): string {
        return this.getFieldErrors(formControl)[0];
    }

    getFieldErrors(formControl: AbstractControl): string[] {
        return (formControl && formControl.touched && formControl.errors) ? this.getErrors(formControl) : [];
    }

    getErrors(control: AbstractControl): string[] {
        return Object.keys(control.errors)
            .filter((error: any) => control.errors[error])
            .map((error: any) => {
                return error;
            });
    }

    private setFieldError(form: FormGroup, fieldName: string, message: string) {
        const control = form.controls[fieldName];
        const errors = { [message]: true };
        control?.setErrors(errors);
        control?.markAsTouched();
    }
}
