export enum TableCellType {
    ICON = 'icon',
    STRING = 'string',
    NUMBER = 'number',
    DATE = 'date',
    DATE_HOUR_FORMAT = 'date-hour-format',
    ORDER_WORK = 'order-work',
    MULTIPLE_PROPERTIES = 'multiple-properties',
    VALUE_OR_ACTION = 'value-or-action',
    TOOLTIP = 'tooltip',
    BOOLEAN = 'boolean',
    DEFAULT_PROFILE = 'default_profile',
    CANCEL_OR_REMAKE_STATUS = 'cancel-or-remake-status',
    DEFAULT = 'default',
    FULL_NAME = 'full_name',
    TRANSLATE = 'translate',
    SUBSCRIPTIONS_REQUIRED_ACTIONS = 'subscription-required-actions',
    PERCENT = 'percent',
    LABEL = 'label',
    BOOLEAN_LABEL = 'boolean_label',
    EMPTY_CONTENT_TO_LINE = 'empty-content-to-line',
    DATE_FORMAT_ON_MULTIPLE_ELEMENTS = 'date-format-on-multiple-elements',
    PRICE = 'price',
    SUBSTRACT_LABEL = "subsctract-label",
    BADGE_OR_DATE = "badge_or_date",
    DATE_HOUR_FORMAT_WITH_MULTIPLE_PROPS = "date-hour-format-with-multiple-props",
    DASH_SEPARATED = "dash-separated",
    CUSTOM_LABEL_NUMBER = "custom-label-number",
    TOOLTIP_INFORMATION = 'tooltip-information',
    TOOLTIP_RESERVATION_INFO = 'tooltip-reservation-info',
    INVENTORY_CLOSED = 'inventory-closed',
    STATUS_BADGE = 'status-badge',
    LETTER_MARK = 'letter-mark'
}