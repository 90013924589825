import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent {

  @Input() message: string;
  @Input() position: string;
  @Input() icon: string;
  @Input() iconColor: string;

  constructor() { }
}
