import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})


export class SweetAlertService {

    public inputValue$: BehaviorSubject<string> = new BehaviorSubject(undefined);

    constructor(public translateService: TranslateService) { }

    showSwal(type, callbackFunction, item?, elementsList?) {
        if (type === 'basic') {
            swal.fire({
                title: 'Here\'s a message!',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        } else if (type === 'title-and-text') {
            swal.fire({
                title: 'Here\'s a message!',
                text: 'It\'s pretty, isn\'t it?',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-info'
                }
            });
        } else if (type === 'close-inventory') {
            swal.fire({
                title: `${this.translateService.instant('CloseInventory?')}`,
                buttonsStyling: false,
                icon: 'success',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('CloseInventory'),
                cancelButtonText: this.translateService.instant('Cancel'),
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });

        } else if (type == 'success-message') {
            swal.fire({
                title: 'Good job!',
                text: 'You clicked the button!',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                },
                icon: 'success'
            });

        } else if (type === 'warning-message') {
            swal.fire({
                title: `${this.translateService.instant('DeleteItem')} ${this.translateService.instant(item).toLowerCase()}?`,
                text: this.translateService.instant('Irreversible'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmDelete'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'stop-working-stage') {
            swal.fire({
                title: `${this.translateService.instant('StopStage')} ${this.translateService.instant(item).toLowerCase()}?`,
                text: this.translateService.instant('Irreversible'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmStopStage'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'warning-message-send-invoice') {
            swal.fire({
                title: this.translateService.instant('SendInvoice?'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmSend'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'warning-message-order-empty-special-stages') {
            let htmlElementsList: HTMLElement = document.createElement('ol');
            htmlElementsList.classList.add('text-left', 'small');
            elementsList.forEach(element => htmlElementsList.innerHTML += `<li class="ml-2">${element.name}</li>`);
            swal.fire({
                title: `${this.translateService.instant('DeleteItem')} ${this.translateService.instant(item).toLowerCase()}?`,
                html: htmlElementsList,
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmDelete'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });

        } else if (type === 'cloning-message') {
            swal.fire({
                title: `${this.translateService.instant('CloneItem')} ${this.translateService.instant(item).toLowerCase()}?`,
                // text: this.translateService.instant('Irreversible'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmCloning'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'warning-message-cancel') {
            swal.fire({
                title: `${this.translateService.instant('CancelItem')} ${this.translateService.instant(item).toLowerCase()}?`,
                text: this.translateService.instant('Irreversible'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmCancel'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'warning-message-remake') {
            swal.fire({
                title: `${this.translateService.instant('RemakeItem')} ${this.translateService.instant(item).toLowerCase()}?`,
                text: this.translateService.instant('Irreversible'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmRemake'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
            
        } else if (type === 'mass-update') {
            swal.fire({
                title: `${this.translateService.instant('StatusUpdate')}`,
                // text: `${this.translateService.instant('UpdateThe')} ${item.items} ${this.translateService.instant('SelectedElementsToStatus').toLowerCase()} - ${this.translateService.instant(item.status).toUpperCase()}?`,
                html: `${this.translateService.instant('UpdateThe')} ${item.items} ${this.translateService.instant('SelectedElementsToStatus').toLowerCase()} <br><strong> ${this.translateService.instant(item.status).toUpperCase()}
                </strong>?`,
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('Update'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'change-stock-management') {
            swal.fire({
                title: `${this.translateService.instant('MoveProducts')}`,
                // text: `${this.translateService.instant('MoveThe')} ${item.items} ${this.translateService.instant('ProductsToTheStockManagement').toLowerCase()} - ${this.translateService.instant(item.stockManagement)}?`,
                html: `${this.translateService.instant('MoveThe')} ${item.items} ${this.translateService.instant('ProductsToTheStockManagement').toLowerCase()} <br><strong> ${this.translateService.instant(item.stockManagement).toUpperCase()}
                </strong>?`,
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('Move'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'confirmation-delete') {
            swal.fire({
                title: this.translateService.instant('Deleted'),
                text: `${this.translateService.instant(item)} ${this.translateService.instant('HasBeenDeleted')}`,
                icon: 'success',
                customClass: {
                    confirmButton: 'btn btn-success',
                },
                buttonsStyling: false
            });
        } else if (type === 'warning-message-and-cancel') {
            swal.fire({
                title: 'Are you sure?',
                text: 'You will not be able to recover this imaginary file!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    swal.fire({
                        title: 'Deleted!',
                        text: 'Your imaginary file has been deleted.',
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false
                    });
                } else {
                    swal.fire({
                        title: 'Cancelled',
                        text: 'Your imaginary file is safe :)',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: false
                    });
                }
            })

        } else if (type == 'custom-html') {
            swal.fire({
                title: 'HTML example',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                },
                html: 'You can use <b>bold text</b>, ' +
                    '<a href="http://github.com">links</a> ' +
                    'and other HTML tags'
            });

        } else if (type === 'auto-close') {
            swal.fire({
                title: 'Auto close alert!',
                text: 'I will close in 2 seconds.',
                timer: 2000,
                showConfirmButton: false
            });
        } else if (type === 'input-field') {
            swal.fire({
                title: 'Input something',
                html: '<div class="form-group">' +
                    '<input id="input-field" type="text" class="form-control" />' +
                    '</div>',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                // swal.fire({
                //     icon: 'success',
                //     html: 'You entered: <strong>' +
                //         $('#input-field').val() +
                //         '</strong>',
                //     customClass: {
                //         confirmButton: 'btn btn-success',
                //     },
                //     buttonsStyling: false
                // })
                this.inputValue$.next($('#input-field').val());
            });
        }
    }

    lockPaymentDocument(callbackFunction, item) {
        const message = {
            title: 'LockItem',
            text: 'Irreversible',
            icon: 'warning',
            confirmText: 'ConfirmLock',
            cancel: 'Cancel'
        }
        this.triggerSwal(callbackFunction, item, message)
    }

    generateInvoice(callbackFunction, item) {
        const message = {
            title: 'GenerateItem',
            icon: 'warning',
            confirmText: 'ConfirmGenerate',
            cancel: 'Cancel'
        }
        this.triggerSwal(callbackFunction, item, message)
    }

    triggerSwal(callbackFunction, item, message) {
        swal.fire({
            title: `${this.translateService.instant(message.title)} ${this.translateService.instant(item).toLowerCase()}?`,
            text: message.text ? this.translateService.instant(message.text) : null,
            icon: message.icon,
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            confirmButtonText: this.translateService.instant(message.confirmText),
            cancelButtonText: this.translateService.instant(message.cancel),
            buttonsStyling: false
        }).then((result) => {
            if (result.value) {
                callbackFunction();
            }
        });
    }

}


