import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TablesModule } from '../../shared/components/tables/tables.module';
import { inventoryReducer } from './store/inventory.reducers';
import { InventoryEffects } from './store/inventory.effects';
import { InventoryFacade } from './store/inventory.facade';
import { InventoriesComponent } from './inventories.component';
import { InventoryModalComponent } from './components/inventory-modal/inventory-modal.component';
import { InventoryProductsComponent } from './components/inventory-products/inventory-products.component';
import { ProductsUploadSuccessfullComponent } from './components/products-upload-successfull/products-upload-successfull.component';

@NgModule({
    imports: [
        SharedComponentsModule,
        DirectivesModule,
        TablesModule,
        CommonModule,
        PipesModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        StoreModule.forFeature('inventory', inventoryReducer),
        EffectsModule.forFeature([InventoryEffects])
    ],
    declarations: [
        InventoriesComponent,
        InventoryModalComponent,
        InventoryProductsComponent,
        ProductsUploadSuccessfullComponent,
    ],
    exports: [
        InventoriesComponent,
        InventoryModalComponent

    ],
    providers: [InventoryFacade]
})

export class InventoryModule { }