import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApplyFiltersModel } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { parseFilters } from 'src/app/shared/utils/filters.utils';
import { ProductModel } from '../models/product.model';

@Injectable({
    providedIn: 'root'
})

export class ProductsService extends BaseService {
    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService) {
        super(_formErrorService);
    }


    getProducts$(payload: ApplyFiltersModel): Observable<PaginationModel> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.products}${parseFilters(payload)}`);
    }

    createProduct$(inventory: ProductModel): Observable<ProductModel> {
        return this.apiRequestService.submitPostRequest(apiEndpoints.products, inventory).pipe(map(res => ({ ...res.data })));
    }


    getProduct$(id: number): Observable<ProductModel> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.products}/${id}`).pipe(map(res => ({ ...res.data })));
    };


    updateProduct$(product: ProductModel): Observable<ProductModel> {
        return this.apiRequestService.submitPutRequest(apiEndpoints.products + '/' + product.id, product).pipe(map(res => ({ ...res.data })));
    }

    // scanProduct$(id: number): Observable<ProductModel> {
    //     return this.apiRequestService.submitPutRequest(`${apiEndpoints.products}/${id}/scan`).pipe(map(res => ({ ...res.data })));
    // }


    getProductDropdownValues$(): Observable<Partial<ProductModel[]>> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.products}/dropdown-values`).pipe(map(res => ([...res.data])));
    }

    getProductQRCode$(id: number): Observable<any> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.products}/${id}/qr-pdf`, { observe: 'response', responseType: 'blob' }).pipe(map(res => res));
    }

}
