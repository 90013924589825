import { HttpErrorResponse } from "@angular/common/http";
import { createAction } from "@ngrx/store";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import { InvoiceModel } from "../models/invoice.model";

export const CLEAN_STATE = createAction('[Invoices] Clean state');
export const CLEAN_ITEM = createAction('[Invoices] Clean item');

export const LOAD_LIST = createAction('[Invoices] Load list', (payload: ApplyFiltersModel) => ({ payload }));
export const LOAD_LIST_SUCCESS = createAction('[Invoices] Load list success', (payload: PaginationModel) => ({ payload }));
export const LOAD_LIST_FAILURE = createAction('[Invoices] Load list fail', (error: HttpErrorResponse) => ({ error }));

export const GENERATE_PDF = createAction('[Invoices] Print item', (id: number, print?: boolean) => ({ id, print }));
export const GENERATE_PDF_SUCCESS = createAction('[Invoices] Print item success', (id: number) => ({ id }));
export const GENERATE_PDF_FAILURE = createAction('[Invoices] Print item fail', (error: HttpErrorResponse) => ({ error }));

export const LOAD_ITEM = createAction('[Invoices] Load item', (id: number) => ({ id }));
export const LOAD_ITEM_SUCCESS = createAction('[Invoices] Load item success', (payload: InvoiceModel) => ({ payload }));
export const LOAD_ITEM_FAILURE = createAction('[Invoices] Load item fail', (error: HttpErrorResponse) => ({ error }));

export const REVERSE_INVOICE = createAction('[Invoices] Reverse invoice', (id: number, payload: any) => ({ id, payload }));
export const REVERSE_INVOICE_SUCCESS = createAction('[Invoices] Reverse invoice success', (payload: InvoiceModel) => ({ payload }));
export const REVERSE_INVOICE_FAILURE = createAction('[Invoices] Reverse invoice fail', (error: HttpErrorResponse) => ({ error }));