import { HttpErrorResponse } from "@angular/common/http";
import { createAction } from "@ngrx/store";
import { UserStatusesModel } from "src/app/modules/accounts/models/account-statuses.model";
import { AccountModel } from "src/app/modules/accounts/models/account.model";
import { RoleModel } from "src/app/modules/roles/models/role.model";
import { StockManagementsModel } from "../modules/stock-managements/models/stock-management.model";
import { InventoryModel } from "../modules/inventories/models/inventory.model";


export const CLEAN_STATE = createAction('[App] Clean state');
export const SET_LANG = createAction('[App] Set language', (lang: string) => ({ lang }));

export const SET_LOGGED_USER = createAction('[App] SET logged user', (payload: AccountModel) => ({ payload }));
export const LOAD_LOGGED_USER = createAction('[App] Load logged user');
export const LOAD_LOGGED_USER_SUCCESS = createAction('[App] Load logged user success', (payload: AccountModel) => ({ payload }));
export const LOAD_LOGGED_USER_FAILURE = createAction('[App] Load logged user failure', (error: HttpErrorResponse) => ({ error }));

export const UPDATE_LOGGED_USER = createAction('[App] Update logged user', (payload: AccountModel) => ({ payload }));
export const UPDATE_LOGGED_USER_SUCCESS = createAction('[App] Update logged user success', (payload: AccountModel) => ({ payload }));
export const UPDATE_LOGGED_USER_FAILURE = createAction('[App] Update logged user failure', (error: HttpErrorResponse) => ({ error }));

export const LOAD_USER_STATUSES = createAction('[App] Load user statuses');
export const LOAD_USER_STATUSES_SUCCESS = createAction('[App] Load user statuses success', (payload: UserStatusesModel[]) => ({ payload }));
export const LOAD_USER_STATUSES_FAILURE = createAction('[App] Load user statuses failure', (error: HttpErrorResponse) => ({ error }));

export const LOAD_ROLES_DROPDOWN_VALUES = createAction('[App] Load roles dropdown values');
export const LOAD_ROLES_DROPDOWN_VALUES_SUCCESS = createAction('[App] Load roles dropdown values success', (payload: Partial<RoleModel[]>) => ({ payload }));
export const LOAD_ROLES_DROPDOWN_VALUES_FAILURE = createAction('[App] Load roles dropdown values failure', (error: HttpErrorResponse) => ({ error }));

export const LOAD_ACCOUNTS_DROPDOWN_VALUES = createAction('[App] Load accounts dropdown values');
export const LOAD_ACCOUNTS_DROPDOWN_VALUES_SUCCESS = createAction('[App] Load accounts dropdown values success', (payload: Partial<AccountModel[]>) => ({ payload }));
export const LOAD_ACCOUNTS_DROPDOWN_VALUES_FAILURE = createAction('[App] Load accounts dropdown values failure', (error: HttpErrorResponse) => ({ error }));

export const LOAD_INVENTORIES_DROPDOWN_VALUES = createAction('[App] Load inventories dropdown values', (filters?: string) => ({ filters }));
export const LOAD_INVENTORIES_DROPDOWN_VALUES_SUCCESS = createAction('[App] Load inventories dropdown values success', (payload: Partial<InventoryModel[]>) => ({ payload }));
export const LOAD_INVENTORIES_DROPDOWN_VALUES_FAILURE = createAction('[App] Load inventories dropdown values failure', (error: HttpErrorResponse) => ({ error }));

export const LOAD_STOCK_MANAGEMENTS_DROPDOWN_VALUES = createAction('[Stock Management] Load list dropdown values', (payload?: string) => ({ payload }));
export const LOAD_STOCK_MANAGEMENTS_DROPDOWN_VALUES_SUCCESS = createAction('[Stock Management] Load list dropdown values success', (payload: Partial<StockManagementsModel>[]) => ({ payload }));
export const LOAD_STOCK_MANAGEMENTS_DROPDOWN_VALUES_FAILURE = createAction('[Stock Management] Load list dropdown values fail', (error: HttpErrorResponse) => ({ error }));


export const SET_LAST_SCANNED_PRODUCT_ID = createAction('[App] Set last scanned id', (id: number) => ({ id }));

export const LOGOUT = createAction('[App] Logout');
