import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromState from "./settings.state";

export const selectSettingsState = createFeatureSelector<fromState.SettingsState>('settings');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = fromState.adapter.getSelectors(selectSettingsState);

// List selectors
export const selectLoading = createSelector(
    selectSettingsState,
    (state: fromState.SettingsState) => state.loading
);

export const selectError = createSelector(
    selectSettingsState,
    (state: fromState.SettingsState) => state.error
);

// Item selectors

export const selectItem = createSelector(
    selectSettingsState,
    (state: fromState.SettingsState) => state.selectedSetting.item
);

export const selectItemLoading = createSelector(
    selectSettingsState,
    (state: fromState.SettingsState) => state.selectedSetting.loading
);

export const selectItemError = createSelector(
    selectSettingsState,
    (state: fromState.SettingsState) => state.selectedSetting.error
);