<div class="spacer-2"></div>

<app-gradient-border-title>{{ "AddInvoiceSerie" | translate }}</app-gradient-border-title>

<div class="spacer-2"></div>

<form [formGroup]="form" class="h-auto" appFormValidator>
    <div class="form-row align-items-end">
        <div class="col-md-2">
            <mat-form-field class="customized">
                <mat-label for="series">{{ "Name" | translate }}</mat-label>
                <input matInput type="text" id="series" formControlName="series">
                <mat-error>
                    <app-form-field-error [control]="form.controls.series"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-md-2">
            <mat-form-field class="customized">
                <mat-label for="start_no">{{ "StartInvoiceNo" | translate }}</mat-label>
                <input matInput type="text" id="start_no" formControlName="start_no">
                <mat-error>
                    <app-form-field-error [control]="form.controls.start_no"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        
        <div class="col-md-1 offset-1">
            <mat-form-field class="customized">
                <app-dropdown-with-search [control]="form.controls.active" [itemsList]="statusActive" [label]="'Default'"
                    [cypressSelector]="'status'" (changeOption)="changeStatusActive($event)">
                </app-dropdown-with-search>
                <mat-error>
                    <app-form-field-error [control]="form.controls.active"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-md-1 offset-1">
            <mat-form-field class="customized">
                <div class="d-flex text-right">
                    <mat-label for="vat">{{ "VAT" | translate }}</mat-label>
                    <input matInput type="text" id="vat" formControlName="vat">%
                </div>
                <mat-error>
                    <app-form-field-error [control]="form.controls.vat"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        
        <div class="col-md-2 offset-1">
            <mat-form-field class="customized">
                <div class="w-120px">
                    <app-dropdown-with-enum-values [label]="'Currency'" [enumName]="'currencies'" [control]="form.controls.currency">
                    </app-dropdown-with-enum-values>
                </div>
            </mat-form-field>
        </div>

        <div class="col-1 d-flex justify-content-end pr-0">
            <button class="btn btn-primary small-btn mb-0" (click)="save()">{{ "Add" | translate }}</button>
        </div>
    </div>
</form>

<div class="spacer-2"></div>
<app-invoice-series-list [data]="data"></app-invoice-series-list>
<div class="spacer-2"></div>