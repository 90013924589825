import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InventoryFacade } from '../../store/inventory.facade';
import { Observable, Subscription, filter, timer } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { parseMultiselectFilters } from 'src/app/shared/utils/form.utils';
import { booleanOptions$ } from 'src/app/shared/constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { StockManagementsModel } from 'src/app/modules/stock-managements/models/stock-management.model';
import { utcConvert } from 'src/app/shared/utils/utils';
@UntilDestroy()
@Component({
    selector: 'app-inventory-products',
    templateUrl: './inventory-products.component.html',
    styleUrls: ['./inventory-products.component.css']
})
export class InventoryProductsComponent implements OnInit {
    public selectedInventoryProducts = []
    public selectedProducts = []
    public inventoryProducts;
    public form: FormGroup;
    public booleanOptions: any;
    public allSelected;
    public activeFilters: boolean;
    private _subscription: Subscription;
    public loading$ = this._inventoryFacade.inventoryProductsLoading$;
    public massUpdateSuccessfull$ = this._inventoryFacade.massUpdateSuccessfull$;
    public utcConvert = utcConvert;

    @Input() id: number;
    @Input() view: boolean;
    @Input() stockManagementsList: StockManagementsModel[];
    @Input() stockManagementId: number;
    @Output() closeModal: EventEmitter<any> = new EventEmitter();

    constructor(private _inventoryFacade: InventoryFacade, private _formBuilder: FormBuilder, private _sweetAlertService: SweetAlertService) {
        this.id && this._inventoryFacade.getInventoryProducts({ id: this.id })
    }

    ngOnInit() {
        booleanOptions$().pipe(untilDestroyed(this)).subscribe(value => this.booleanOptions = value.data);
        this.form = this._formBuilder.group({
            "product.name": '',
            "product.external_number": '',
            "product.internal_number": '',
            is_scanned: '',
            status: '',
            is_imported: '',
        });

        if (this._subscription) this._subscription.unsubscribe()
        this._subscription = timer(0, 30000).pipe(untilDestroyed(this)).subscribe(() => {
            let allDropdowns = document.querySelectorAll('.stop-refresh');
            let dropdownOpened = false
            allDropdowns.forEach(element => {
                if (element.classList.contains('show')) {
                    dropdownOpened = true;
                    return
                }
            })
            !this.view && this.id && !dropdownOpened && this._inventoryFacade.getInventoryProducts({ id: this.id, filters: parseMultiselectFilters(this.form.value) })
        });
        this._inventoryFacade.inventoryProducts$.pipe(filter(data => !!data)).subscribe((data) => this.inventoryProducts = data);

        this.massUpdateSuccessfull$.pipe(untilDestroyed(this)).subscribe(data => {
            data && this.resetCheckboxList();
            this._inventoryFacade.setMassUpdateToFalse();
        });
    }

    applyFilters() {
        this.selectedInventoryProducts = [];
        this.selectedProducts = [];
        this.activeFilters = parseMultiselectFilters(this.form.value) !== '?' ? true : false;
        this._inventoryFacade.getInventoryProducts({ id: this.id, filters: parseMultiselectFilters(this.form.value) })
    }

    updateProduct(product, status) {
        this._inventoryFacade.updateInventoryProduct(product.id, status, parseMultiselectFilters(this.form.value));
    }
    resetFilters() {
        this.form.reset();
        this.activeFilters = false;
        this._inventoryFacade.getInventoryProducts({ id: this.id, filters: parseMultiselectFilters(this.form.value) })
    }

    toggle(product, event: MatCheckboxChange): void {
        if (event.checked) {
            this.selectedInventoryProducts.push(product.id);
            this.selectedProducts.push(product.product.id);
        } else {
            const inventoryProductIndex = this.selectedInventoryProducts.indexOf(product.id);
            const productIndex = this.selectedProducts.indexOf(product.product.id);
            if (inventoryProductIndex >= 0) {
                this.selectedInventoryProducts.splice(inventoryProductIndex, 1);
                this.selectedProducts.splice(productIndex, 1);
            }
        }
    }

    exists(id): boolean {
        return this.selectedInventoryProducts.indexOf(id) > -1;
    };

    isIndeterminate(): boolean {
        return (this.selectedInventoryProducts.length > 0 && !this.isChecked());
    };

    isChecked(): boolean {
        return this.selectedInventoryProducts.length === this.inventoryProducts.length;
    };

    toggleAll(event: MatCheckboxChange): void {
        if (event.checked) {
            this.inventoryProducts.forEach(row => {
                if (!this.exists(row.id)) {
                    this.selectedInventoryProducts.push(row.id);
                    this.selectedProducts.push(row.product.id);
                }
            });
            return;
        }
        this.selectedInventoryProducts = []
        this.selectedProducts = []
    }
    massUpdate(type: string) {
        this._sweetAlertService.showSwal('mass-update', () => this._inventoryFacade.massUpdate(this.selectedInventoryProducts, type, this.id, parseMultiselectFilters(this.form.value)), { items: this.selectedInventoryProducts.length, status: type });
    }

    stockManagementChange(e) {
        this._sweetAlertService.showSwal('change-stock-management', () => this._inventoryFacade.updateProductStockManagement({ stock_management_id: e.id, products_ids: this.selectedProducts }, this.id, parseMultiselectFilters(this.form.value)), { items: this.selectedProducts.length, stockManagement: e.name });
    }

    resetCheckboxList() {
        this.selectedInventoryProducts = []
        this.selectedProducts = []
    }
}