import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-upload-files',
    templateUrl: './upload-files.component.html',
    styleUrls: ['./upload-files.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFilesComponent implements OnInit, OnDestroy {

    public fileToUpload: File | null = null;
    public errorField: number = undefined;
    public overwriteStockManagement: boolean;
    @Input() fileType;
    @Input() downloadUrl: string;
    @Input() errors;
    @Output() onUpload = new EventEmitter();
    @Output() downloadTemplate = new EventEmitter();
    @Output() clearErrors = new EventEmitter();
    @ViewChild('takeInput', { static: false }) InputVar: ElementRef;


    constructor(private changeDetection: ChangeDetectorRef) { }

    ngOnInit(): void { }

    ngOnDestroy() {
        this.dismissFile();
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
        this.clearErrors.emit();
        this.refresh();
    }

    uploadFile() {
        const file = {
            file_name: this.fileToUpload.name.split('.')[0],
            file: this.fileToUpload,
            overwrite_stock_management: this.overwriteStockManagement || false
        }
        this.onUpload.emit(file);
        this.refresh();
        this.errorField = undefined;
    }

    dismissFile() {
        this.fileToUpload = null;
        this.clearErrors.emit();
        this.refresh();
    }

    checkErrorLine(field) {
        if (this.errorField === field) {
            return false;
        }
        this.errorField = field;
        return true;
    }

    resetInputValue() {
        this.InputVar.nativeElement.value = "";
        this.clearErrors.emit();
    }

    refresh() {
        this.changeDetection.detectChanges();
    }

    onUpdateOverwriteStockManagement(e) {
        this.overwriteStockManagement = e.checked ? true : false
    }

}
