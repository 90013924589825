import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { take } from 'rxjs';
import { AccountsService } from 'src/app/modules/accounts/services/accounts.service';
import { AccountsFacade } from 'src/app/modules/accounts/store/accounts.facade';
import { AccountModel } from '../../../models/account.model';

@UntilDestroy()
@Component({
    selector: 'app-accounts-image-tab',
    templateUrl: './accounts-image-tab.component.html',
    styleUrls: ['./accounts-image-tab.component.css']
})
export class AccountsImageTabComponent {
    private _accountsFacade = inject(AccountsFacade);
    private _accountsService = inject(AccountsService);
    public imgCtrl = new FormControl();

    @Input() selectedAccount: AccountModel;
    @Output() onSaveAndClose: EventEmitter<boolean> = new EventEmitter();
    @Output() removeProfilePic: EventEmitter<boolean> = new EventEmitter();
    @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('removeLogoImg', { static: true }) removeLogoImgRef: ElementRef;

    saveAndClose() {
        this.onSaveAndClose.emit(true);
        this.onSubmit();
    }

    onSubmit() {
        if (this.imgCtrl.value) {
            this._accountsService.onSaveSuccess$.pipe(take(1)).subscribe(() => this.removeLogoImgRef.nativeElement.click());
            this._accountsFacade.updatePicture(this.selectedAccount.id, this.imgCtrl.value);
        }
    }

    handleFileInput(files: FileList) {
        this.imgCtrl.setValue(files[0]);
    }

    removeLocalFile(input: HTMLInputElement) {
        input.value = '';
        this.imgCtrl.setValue('');
    }
}
