import { DirectivesModule } from '../../directives/directives.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from '../shared-components.module';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        DirectivesModule,
        SharedComponentsModule
    ],
    declarations: [NavbarComponent],
    exports: [NavbarComponent]
})

export class NavbarModule { }
