import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableDataHeaders, TableHeadersTypeEnum } from 'src/app/shared/models/table-data-headers.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AccountsService } from '../../accounts/services/accounts.service';
import { ExportInvoicesModalComponent } from './components/export-invoices-modal/export-invoices-modal.component';
import { InvoicesService } from './services/invoices.service';
import { InvoicesModalComponent } from './components/invoices-modal/invoices-modal.component';
import { InvoicesFacade } from './store/invoices.facade';

@UntilDestroy()
@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.css']
})

export class InvoicesComponent implements OnInit {

    public PermissionsEnum = PermissionsEnum;
    public list$ = this._invoicesFacade.invoices$;
    public pagination$ = this._invoicesFacade.invoicesPagination$;
    public loading$ = this._invoicesFacade.invoicesLoading$

    public filtersPayload: ApplyFiltersModel = {
        queryParams: {
            page: 1,
            records_number: 10
        },
        parsedFilters: ''
    }


    public filters: TableFilter[] = [
        {
            resourceName: 'invoice_date',
            label: 'InvoiceDate',
            type: TableFilterTypesEnum.DATE_RANGE,
            controls: ['invoice_date_start', 'invoice_date_end'],
            style: 'col-md-3',
        },
        {
            resourceName: 'invoice_series_and_no',
            label: 'InvoiceSeriesAndNo',
            type: TableFilterTypesEnum.TEXT,
            controls: ['invoice_series', 'invoice_no'],
            style: 'col-md-3',
            placeholders: ['Serie', 'Number'],
        }
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'InvoiceDate',
            fieldNames: ['date'],
            cssClasses: 'all text-center',
            cellType: TableCellType.DATE,
        },
        {
            name: 'InvoiceSeriesAndNo',
            fieldNames: ['invoice_series_id', 'number'],
            cssClasses: 'all text-center',
            cellType: TableCellType.DATE_FORMAT_ON_MULTIPLE_ELEMENTS,
            type: TableHeadersTypeEnum.MULTIPLE_ELEMENTS,
        },
        {
            name: 'Owner',
            fieldNames: ['owner_name'],
            cssClasses: 'all text-center',
            type: TableHeadersTypeEnum.MULTIPLE_ELEMENTS,
        },
        {
            name: 'ValueWithVat',
            fieldNames: ['value_with_vat'],
            cssClasses: 'all text-right',
            type: TableHeadersTypeEnum.PRICE,
            cellType: TableCellType.PRICE,
            total: ['value'],
        },
        {
            name: 'ValueWithoutVat',
            fieldNames: ['value_without_vat'],
            cssClasses: 'all text-right',
            type: TableHeadersTypeEnum.PRICE,
            cellType: TableCellType.PRICE,
            total: ['value'],
        },
        {
            name: 'VAT',
            fieldNames: ['vat_percertage'],
            cssClasses: 'all text-right',
            type: TableHeadersTypeEnum.PRICE,
            cellType: TableCellType.PRICE,
            total: ['value'],
        }
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'View',
            type: TableActionTypeEnum.View,
            permission: PermissionsEnum.INVOICES_READ,
            default: true
        },
        {
            name: 'Print',
            type: TableActionTypeEnum.ExportPDF,
        }
    ];

    @ViewChild('table', { static: true }) table: TableFiltersComponent;

    constructor(public invoicesService: InvoicesService,
        private _invoicesFacade: InvoicesFacade,
        public accountsService: AccountsService,
        private _dialogService: DialogService) { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    tableActionClicked(e: any) {
        if (e.type === TableActionTypeEnum.View) {
            this._dialogService.openModal(e.entry, InvoicesModalComponent, DimensionsEnum.AUTO);
        }

        if (e.type === TableActionTypeEnum.ExportPDF) {
            this._invoicesFacade.generateInvoicePDF(e.entry.id)
        }
    }

    export() {
        this._dialogService.openModal(null, ExportInvoicesModalComponent, DimensionsEnum.AUTO);
    }

    updatePage(event: PageEvent) {
        this.filtersPayload.queryParams = {
            records_number: event.pageSize,
            page: event.pageIndex + 1
        };
        this._invoicesFacade.loadList(this.filtersPayload);
    }

    applyFilters(filters?: ApplyFiltersModel) {
        filters && (this.filtersPayload = filters);
        this._invoicesFacade.loadList(this.filtersPayload);
    }

    addEntry() {
        this._dialogService.openModal(null, InvoicesModalComponent, DimensionsEnum.AUTO);
    }
}
