import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MenuItemModel, MenuItemsModel } from '../../models/menu-item.model';

@Component({
  selector: 'app-dropdown-menu-options',
  templateUrl: './dropdown-menu-options.component.html',
  styleUrls: ['./dropdown-menu-options.component.css']
})
export class DropdownMenuOptionsComponent implements OnInit {

  constructor() { }

  @Input() menu: MenuItemModel;
  @Output() triggerOption: EventEmitter<MenuItemsModel> = new EventEmitter<MenuItemsModel>();

  ngOnInit(): void {
  }

  triggerMenuItem(event: MenuItemsModel) {
    this.triggerOption.emit(event);
  }
}