import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-generate-xls',
  templateUrl: './generate-xls.component.html',
  styleUrls: ['./generate-xls.component.css']
})
export class GenerateXlsComponent implements OnInit {

  @Output() downloadXLS: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
