<div class="d-flex flex-column justify-content-center align-items-center">
    <div class="d-flex justify-content-center align-items-center mb-2"><span class="material-icons">done</span><h4 class="mb-0 ml-2" data-cy="upload-succsessfully-message"> {{"FileUploadSuccsessfullyMessage" | translate}}</h4></div>
   <button class="btn btn-primary" (click)="backToUpload()"><span class="material-icons mr-2">navigate_before</span>{{"UploadAnotherFile" | translate}}</button>
</div>
<div class="spacer-3"></div>

<div class="d-flex justify-content-center">
    <div class="col-12 col-md-5 px-0">
        <app-products-status 
        [inventoryProducts]="importedProducts | async"
        [isCentered]="true">
    </app-products-status>
    </div>
</div>
