import { FormGroup, FormControl } from '@angular/forms';
import { RegexValidator } from './regex-validator.utils';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';

export function parseMultiselectFilters(params: any, symbol?: string) {
    const paramsKeys = Object.keys(params);
    let filters = paramsKeys.length ? (symbol ? symbol : '?') : '';

    paramsKeys.forEach((param, i) => {
        if (Array.isArray(param)) {
            params[param].shift();
            const subParam = [...params[param]];
            filters += `filter[${params[param]}]=${[subParam]}`
        }
        params[param] && (filters += `filter[${param}]=${params[param]}${i < paramsKeys.length - 1 ? '&' : ''}`)
    })
    filters.slice(-1) === '&' && (filters = filters.substring(0, filters.length - 1));
    return filters;
}

export function clearEmptyFormFields(form: any): any {
    const clearedForm = {};

    Object.keys(form).forEach(field => {
        if (typeof form[field] === 'number' && form[field]) {
            clearedForm[field] = form[field];
            return;
        }

        form[field]?.length && (clearedForm[field] = form[field]);
    })

    return clearedForm;
}

export function validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.controls[field];
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
        }
    });
}

export function setServerErrorMessages(serverError: HttpErrorResponse, form: FormGroup) {
    const errors = serverError?.error?.errors;
    if (!errors) { return; }
    Object.keys(errors).forEach(prop => {
        const formControl = form.get(prop);
        if (formControl) {
            formControl.setErrors({
                serverError: errors[prop]
            });
        }
    });
}

export function parseDateFormat(object: any) {
    const parsedObject = { ...object };
    for (const key in parsedObject) {
        if (Object.prototype.hasOwnProperty.call(parsedObject, key)) {
            key.includes('date') && parsedObject[key] && (parsedObject[key] = moment(parsedObject[key]).format('YYYY-MM-DD'));
            key.includes('started_at') && parsedObject[key] && (parsedObject[key] = moment(parsedObject[key]).format('YYYY-MM-DD'));
            key.includes('ended_at') && parsedObject[key] && (parsedObject[key] = moment(parsedObject[key]).format('YYYY-MM-DD'));
        }
    }
    return parsedObject;

}

export const ValidationMessages = {
    'name': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.ITEM_NAME.errorMessage },
    ],
    'person_name': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.PERSON_NAME.errorMessage }
    ],
    'cnp': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.CNP.errorMessage }
    ],
    'fiscal_code': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.CUI.errorMessage }
    ],
    'docSeries': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.DOCUMENT_SERIES.errorMessage }
    ],
    'nr_reg_com': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.NR_REG_COM.errorMessage }
    ],
    'email': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.EMAIL.errorMessage }
    ],
    'phone': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.PHONE.errorMessage }
    ],
    'days': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.NUMBER.errorMessage },
    ],
    'hours': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.NUMBER.errorMessage },
    ],
    'minutes': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.NUMBER.errorMessage }
    ],
    'cost': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.FLOAT.errorMessage }
    ],
    'quantity': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.QUANTITY.errorMessage }
    ],
    'remake_cost': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.FLOAT.errorMessage },
    ],
    'special_cost': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.FLOAT.errorMessage }
    ],
    'number': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.NUMBER.errorMessage }
    ],
    'float': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.FLOAT.errorMessage }
    ],
    'value': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.VALUE.errorMessage },
    ],
    'username': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.USERNAME.errorMessage },
    ],
    'linkedin': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.LINKEDIN.errorMessage }
    ],
    'website': [
        { type: 'required', message: RegexValidator.REQUIRED.errorMessage },
        { type: 'pattern', message: RegexValidator.WEBSITE.errorMessage }
    ]
};
