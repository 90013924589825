import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InventoryFacade } from 'src/app/modules/inventories/store/inventory.facade';
import { isMobile } from '../../utils/responsive.utils';
@UntilDestroy()
@Component({
    selector: 'app-products-status',
    templateUrl: './products-status.component.html',
    styleUrls: ['./products-status.component.css']
})
export class ProductsStatusComponent implements OnInit {
    public totalInInventory = 0;
    public totalDisposed = 0;
    public totalCharged = 0;
    public totalNotProcessed = 0;
    public totalNotScanned = 0;
    public totalNotImported = 0;
    public isMobile = isMobile();
    @Input() inventoryProducts;
    @Input() isCentered;
    @Input() tab;

    constructor(private _inventoryFacade: InventoryFacade) { }

    ngOnInit(): void {
        this._inventoryFacade.inventoryProducts$.pipe(untilDestroyed(this)).subscribe(data => {
            this.inventoryProducts = data;
            this.totalInInventory = this.getTotalInInventory(this.inventoryProducts);
            this.totalDisposed = this.getTotalDisposed(this.inventoryProducts);
            this.totalCharged = this.getTotalCharged(this.inventoryProducts);
            this.totalNotProcessed = this.getTotalNotProcessed(this.inventoryProducts);
            this.totalNotScanned = this.getTotalNotScanned(this.inventoryProducts);
            this.totalNotImported = this.getTotalNotImported(this.inventoryProducts);
        });
    }

    getTotalInInventory(inventoryProducts): number {
        return inventoryProducts.filter(entry => entry.status === "in_inventory").length;
    }

    getTotalDisposed(inventoryProducts): number {
        return inventoryProducts.filter(entry => entry.status === "disposed").length;
    }

    getTotalCharged(inventoryProducts): number {
        return inventoryProducts.filter(entry => entry.status === "charged").length;
    }

    getTotalNotProcessed(inventoryProducts): number {
        return inventoryProducts.filter(entry => entry.status === "not_processed").length;
    }

    getTotalNotScanned(inventoryProducts): number {
        return inventoryProducts.filter(entry => entry.scanned_at === null).length;
    }

    getTotalNotImported(inventoryProducts): number {
        return inventoryProducts.filter(entry => entry.imported_at === null).length;
    }
}
