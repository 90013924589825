import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesComponent } from './invoices/invoices.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { ReverseInvoiceModalComponent } from './invoices/components/reverse-invoice-modal/reverse-invoice-modal.component';
import { ExportInvoicesModalComponent } from './invoices/components/export-invoices-modal/export-invoices-modal.component';
import { InvoicesToExportListComponent } from './invoices/components/export-invoices-modal/components/invoices-to-export-list/invoices-to-export-list.component';
import { MdModule } from 'src/app/shared/components/md/md.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { httpTranslateLoader } from 'src/app/app.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { TablesModule } from 'src/app/shared/components/tables/tables.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { InvoicesModalComponent } from './invoices/components/invoices-modal/invoices-modal.component';
import { InvoiceServicesFormComponent } from './invoices/components/invoices-modal/invoice-services-tab/invoice-services-form/invoice-services-form.component';
import { InvoiceServicesListComponent } from './invoices/components/invoices-modal/invoice-services-tab/invoice-services-list/invoice-services-list.component';
import { InvoiceGeneralInfoTabComponent } from './invoices/components/invoices-modal/invoice-general-info-tab/invoice-general-info-tab.component';
import { InvoiceServicesTabComponent } from './invoices/components/invoices-modal/invoice-services-tab/invoice-services-tab.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { InvoicesEffects } from './invoices/store/invoices.effects';
import { invoicesReducer } from './invoices/store/invoices.reducers';
import { InvoicesFacade } from './invoices/store/invoices.facade';

@NgModule({
    imports: [
        CommonModule,
        MdModule,
        HttpClientModule,
        MaterialModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        DirectivesModule,
        MatFormFieldModule,
        PipesModule,
        SharedComponentsModule,
        TablesModule,
        StoreModule.forFeature('invoices', invoicesReducer),
        EffectsModule.forFeature([InvoicesEffects])
    ],
    declarations: [
        InvoicesComponent,
        ReverseInvoiceModalComponent,
        ExportInvoicesModalComponent,
        InvoicesToExportListComponent,
        InvoicesModalComponent,
        InvoiceServicesFormComponent,
        InvoiceServicesListComponent,
        InvoiceGeneralInfoTabComponent,
        InvoiceServicesTabComponent,
    ],
    exports: [],
    providers: [InvoicesFacade]
})
export class FinancialModule { }
