import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";


import { RoleModel } from "src/app/modules/roles/models/role.model";
import * as fromActions from "./roles.actions";
import * as fromSelectors from "./roles.selectors";

@Injectable()
export class RolesFacade {
    constructor(private _store: Store) { }

    // Select the selectors
    get roles$() {
        return this._store.select(fromSelectors.selectAll);
    }

    get rolesLoading$() {
        return this._store.select(fromSelectors.selectLoading);
    }

    get rolesError$() {
        return this._store.select(fromSelectors.selectError);
    }

    get role$() {
        return this._store.select(fromSelectors.selectItem);
    }

    get roleLoading$() {
        return this._store.select(fromSelectors.selectItemLoading);
    }

    get roleError$() {
        return this._store.select(fromSelectors.selectItemError);
    }

    // Dispatch actions
    cleanState() {
        this._store.dispatch(fromActions.CLEAN_STATE());
    }

    cleanItem() {
        this._store.dispatch(fromActions.CLEAN_ITEM());
    }

    loadList() {
        this._store.dispatch(fromActions.LOAD_LIST());
    }

    loadItem(id: number) {
        this._store.dispatch(fromActions.LOAD_ITEM(id));
    }

    createItem(payload: Partial<RoleModel>) {
        this._store.dispatch(fromActions.CREATE(payload));
    }

    updateItem(payload: RoleModel) {
        this._store.dispatch(fromActions.UPDATE(payload));
    }

    deleteItem(id: number) {
        this._store.dispatch(fromActions.DELETE(id));
    }
}