import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AccountModel } from "src/app/modules/accounts/models/account.model";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import * as fromActions from "./accounts.actions";
import * as fromSelectors from "./accounts.selectors";

@Injectable()
export class AccountsFacade {
    constructor(private _store: Store) { }

    // Select the selectors
    get accounts$() {
        return this._store.select(fromSelectors.selectAll);
    }

    get accountsPagination$() {
        return this._store.select(fromSelectors.selectPagination);
    }

    get accountsLoading$() {
        return this._store.select(fromSelectors.selectLoading);
    }

    get accountsError$() {
        return this._store.select(fromSelectors.selectError);
    }

    get accountsFilters$() {
        return this._store.select(fromSelectors.selectFilters);
    }

    get account$() {
        return this._store.select(fromSelectors.selectItem);
    }

    get accountLoading$() {
        return this._store.select(fromSelectors.selectItemLoading);
    }

    get accountError$() {
        return this._store.select(fromSelectors.selectItemError);
    }

    // Dispatch actions
    cleanState() {
        this._store.dispatch(fromActions.CLEAN_STATE());
    }

    cleanItem() {
        this._store.dispatch(fromActions.CLEAN_ITEM());
    }

    loadList(payload: ApplyFiltersModel) {
        this._store.dispatch(fromActions.LOAD_LIST(payload));
    }

    loadItem(id: number) {
        this._store.dispatch(fromActions.LOAD_ITEM(id));
    }

    setItem(payload: AccountModel) {
        this._store.dispatch(fromActions.SET_ITEM(payload));
    }

    createItem(payload: AccountModel) {
        this._store.dispatch(fromActions.CREATE(payload));
    }

    updateItem(payload: AccountModel) {
        this._store.dispatch(fromActions.UPDATE(payload));
    }

    updatePicture(id: number, img: File) {
        this._store.dispatch(fromActions.UPDATE_PICTURE(id, img));
    }

    removePicture(id: number) {
        this._store.dispatch(fromActions.REMOVE_PICTURE(id));
    }
}