import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { RolesEffects } from 'src/app/modules/roles/store/roles.effects';
import { RolesFacade } from 'src/app/modules/roles/store/roles.facade';
import { rolesReducer } from 'src/app/modules/roles/store/roles.reducers';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { TablesModule } from 'src/app/shared/components/tables/tables.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { RolesModalComponent } from './components/roles-modal/roles-modal.component';
import { RolesComponent } from './roles.component';

@NgModule({
    imports: [
        SharedComponentsModule,
        DirectivesModule,
        TablesModule,
        CommonModule,
        PipesModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        StoreModule.forFeature('roles', rolesReducer),
        EffectsModule.forFeature([RolesEffects])
    ],
    declarations: [
        RolesComponent,
        RolesModalComponent,
    ],
    exports: [
        RolesComponent,
        RolesModalComponent,
    ],
    providers: [RolesFacade]
})

export class RolesModule { }