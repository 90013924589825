import { Routes } from '@angular/router';
import { ProductQrScanComponent } from './product-qr-scan/product-qr-scan.component';
import { QrInventoryProductsComponent } from './qr-inventory-products/qr-inventory-products.component';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';

export const QRScanRoutes: Routes = [

    {
        path: '',
        children: [
            {
                path: 'products/:id',
                component: ProductQrScanComponent,
                canActivate: [AuthGuard]

            },
            {
                path: 'inventory-products',
                component: QrInventoryProductsComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
];
