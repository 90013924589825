export class BrandInvoiceProfileModel {
    id?: number;
    is_default_profile?: boolean;
    name: string;
    fiscal_code: string;
    chamber_of_commerce_code: string;
    address: string;
    city: string;
    phone_fax: string;
    mobile1: string;
    mobile2: string;
    email1: string;
    email2: string;
    website: string;
    invoice_series: InvoiceSerieModel;
    invoice_no: number;
    bank: string;
    bank_account: string;
    currency: string;
}

export class InvoiceSerieModel {
    series: string;
    local_currency_series: string;
    id?: number;
    brand_invoice_profile_id: number;
    start_no: number;
    active: boolean;
    invoice_format: number;
    currency: string;
    vat: number;
}