import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { SettingModel } from "src/app/modules/settings/models/settings.model";

export interface SettingsState extends EntityState<SettingModel> {
    error: string | null;
    loading: boolean;

    selectedSetting: {
        item: SettingModel | undefined;
        error: string | null;
        loading: boolean;
        saveAndClose: boolean;
    }
}

export const adapter: EntityAdapter<SettingModel> = createEntityAdapter<SettingModel>();

export const settingsInitialState: SettingsState = adapter.getInitialState({
    ids: [],
    entities: {},
    error: null,
    loading: false,

    selectedSetting: {
        item: null,
        error: null,
        loading: false,
        saveAndClose: false
    }
});