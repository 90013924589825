import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromState from "./app.state";

export const selectAppState = createFeatureSelector<fromState.AppState>('app');

export const isAuthenticated = createSelector(
    selectAppState,
    (state: fromState.AppState) => state.isAuthenticated
);
export const lang = createSelector(
    selectAppState,
    (state: fromState.AppState) => state.lang
);

export const selectLoggedUser = createSelector(
    selectAppState,
    (state: fromState.AppState) => state.loggedUser.account
);

export const selectLoggedUserLoading = createSelector(
    selectAppState,
    (state: fromState.AppState) => state.loggedUser.loading
);

export const selectUserStatuses = createSelector(
    selectAppState,
    (state: fromState.AppState) => state.dropdownValues.userStatuses.list
);

export const selectRolesDropdownValues = createSelector(
    selectAppState,
    (state: fromState.AppState) => state.dropdownValues.roles.list
);

export const selectAccountsDropdownValues = createSelector(
    selectAppState,
    (state: fromState.AppState) => state.dropdownValues.accounts.list
);
export const selectStockManagementsDropdownValues = createSelector(
    selectAppState,
    (state: fromState.AppState) => state.dropdownValues.stockManagements.list
);

export const selectInventoriesDropdownValues = createSelector(
    selectAppState,
    (state: fromState.AppState) => state.dropdownValues.inventories.list
);

export const lastScannedProductId = createSelector(
    selectAppState,
    (state: fromState.AppState) => state.lastScannedProductId
);