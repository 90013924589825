import { Observable } from "rxjs";

export class TableFilter {
    resourceName: string;
    label: string;
    type: TableFilterTypesEnum = TableFilterTypesEnum.TEXT;
    controls: string[];
    style: string;
    cssClasses?: string;
    firstOptionMessage?: string;
    dropdownId?: string;
    optionsList$?: Observable<any>;
    defaultRange?: {
        start: string;
        end: string;
    };
    enumName?: string;
    placeholders?: string[];
    dispatchAction?: Function;
    data_cy?: string;
}

export enum TableFilterTypesEnum {
    SELECT = 'select',
    ENUM_SELECT = 'enum-select',
    BOOLEAN_SELECT = 'boolean-select',
    DATE_RANGE = 'date-range',
    TEXT = 'text',
}

export interface ApplyFiltersModel {
    parsedFilters: string;
    queryParams: any;
}