<div class="main-content mt-3 h-100" [class.mt-5]="!isMobile()">
    <app-dashboard-tabs></app-dashboard-tabs>
    <!-- <div class="container-fluid h-100">
        <div class="row align-items-center h-100"> -->
            <!-- <div class="col-12 d-flex flex-column align-items-center">
                <img src="../../../assets/img/under_construction.gif" width="200px" alt="money">
                <div class="mt-3">Under construction...</div>
            </div> -->
        <!-- </div>
        <div class="spacer-2"></div>
    </div> -->
</div>