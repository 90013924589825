import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: '[datatablesCalculateTotal]'
})

export class DatatablesCalculateTotalDirective implements AfterViewInit {
    private _list: any[];

    @Input() datatablesCalculateTotal: any;
    @Input() set list(value: any[]) {
        this._list = value;
        this.calculateTotal();
    };

    get list(): any[] {
        return this._list;
    }

    constructor(private elementRef: ElementRef) { }

    ngAfterViewInit(): void {
        this.calculateTotal();
    }

    calculateTotal(): void {
        if (this.datatablesCalculateTotal.total) {
            let sums = {};

            this.datatablesCalculateTotal.total.forEach(totalField => {
                sums[totalField] = 0;

                this.list?.forEach(item => {
                    const value = Number(this.splitFields(item, totalField));
                    isFinite(value) && (sums[totalField] += value);
                });
            });

            this.elementRef.nativeElement.innerHTML = this.createTotalsElement(sums);
            this.elementRef.nativeElement.classList.add('d-block', 'text-right', 'font-weight-bold');
        }
    }

    splitFields(row: object, totalField: string) {
        let fields = totalField.split('.');
        let data = row;
        for (let i = 0; i < fields.length; i++) {
            const list = fields[i].includes('[') && fields[i].split('[');
            const listPosition = list && list[1].substring(0, 1);
            data = listPosition ? data[list[listPosition]][i] : data[fields[i]];
            if (!data) { return; }
        }
        return `${data}`;
    }

    createTotalsElement(sums: any): string {
        let element = '';
        let i = 0;
        for (const field in sums) {
            element += `<div class="${i > 0 && 'small'}">${sums[field].toFixed(2)}</div>`;
            i++;
        }
        return element;
    }
}