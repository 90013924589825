import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';

import { parseDateFormat, parseMultiselectFilters } from 'src/app/shared/utils/form.utils';
import { TableFilter, TableFilterTypesEnum } from './models/table-filter.model';
import { isMobile } from 'src/app/shared/utils/responsive.utils';

@UntilDestroy()
@Component({
    selector: 'app-table-filters',
    templateUrl: './table-filters.component.html',
    styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnInit {

    private _queryParams: any;
    private _initialFormValues = {};
    public form: FormGroup;
    public options: any = {};
    public filterTypes = TableFilterTypesEnum;
    public parsedFilters: string;
    public isMobile = isMobile();

    @ViewChild('top') top: ElementRef<any>;

    @Input() filters: TableFilter[];
    @Input() tableId: string;
    @Input() set queryParams(value: any) {
        this._queryParams = value;
        this.form && this.updateLocalStorageTable(parseDateFormat(this.form.value), value);
        this.top?.nativeElement.scrollIntoView(0, 0);
    };
    get queryParams(): any {
        return this._queryParams;
    };
    @Output() applyFilters: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
        this.form = new FormGroup({});

        this.filters.forEach(filter => {
            filter.controls.forEach(control => {
                this.form.addControl(control, new FormControl(''), { emitEvent: false });
                this.setInitialFormValues(filter, control);
            });
            const existingValues = JSON.parse(localStorage.getItem(this.tableId))?.form;
            existingValues && this.form.patchValue(existingValues);

            filter.dispatchAction && filter.dispatchAction();

            filter.optionsList$?.pipe(map(res => res?.data || res), untilDestroyed(this)).subscribe(list => {
                this.options[filter.resourceName] = list;
                const control = this.form.controls[filter.controls[0]];
                control.setValue(control.value || 0, { emitEvent: false });
            });
        });
        setTimeout(() => this.save(true));
    }

    save(fromCloseModal?: boolean) {
        const queryParams = this.getQueryParamsFromLocalStorage();
        this.parsedFilters = parseMultiselectFilters(parseDateFormat(this.form.value), '&');
        !fromCloseModal && (queryParams.page = 1);
        this.updateLocalStorageTable(parseDateFormat(this.form.value), queryParams);
        this.applyFilters.emit({ parsedFilters: this.parsedFilters, queryParams });
    }

    updateLocalStorageTable(form: any, queryParams: any) {
        const payload = { queryParams, form };
        this.tableId && localStorage.setItem(this.tableId, JSON.stringify(payload));
    }

    getQueryParamsFromLocalStorage() {
        const localStorageParams = localStorage.getItem(this.tableId);
        const localStorageQueryParams = JSON.parse(localStorageParams)?.queryParams;
        if (localStorageQueryParams) return localStorageQueryParams;
        return this.queryParams;
    }

    setInitialFormValues(filter: TableFilter, control: string) {
        this._initialFormValues[control] = (filter.type === this.filterTypes.TEXT || filter.type === this.filterTypes.DATE_RANGE) ? '' : 0;
    }

    resetFilters() {
        this.form.reset(this._initialFormValues);
        this.save();
    }
}
