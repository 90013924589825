import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TablesModule } from '../../shared/components/tables/tables.module';
import { ProductsComponent } from './products.component';
import { ProductsFacade } from './store/products.facade';
import { ProductsEffects } from './store/products.effects';
import { productReducer } from './store/products.reducers';
import { ProductModalComponent } from './components/product-modal/product-modal.component';

@NgModule({
    imports: [
        SharedComponentsModule,
        DirectivesModule,
        TablesModule,
        CommonModule,
        PipesModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        StoreModule.forFeature('products', productReducer),
        EffectsModule.forFeature([ProductsEffects])
    ],
    declarations: [
        ProductsComponent,
        ProductModalComponent
    ],
    exports: [
        ProductsComponent
    ],
    providers: [ProductsFacade]
})

export class ProductsModule { }