
import { RestrictPermissionsGuard } from '../../shared/guards/restrict-permissions.guard';
import { RolesComponent } from './../roles/roles.component';
import { Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { AccountsComponent } from '../../modules/accounts/accounts.component';
import { SettingsComponent } from '../settings/settings.component';
import { BrandInvoiceProfileComponent } from '../brand-invoice-profile/brand-invoice-profile.component';
import { BrandProfileComponent } from '../brand-profile/brand-profile.component';
import { InvoicesComponent } from '../financial/invoices/invoices.component';
import { NotAllowedComponent } from 'src/app/shared/components/not-allowed/not-allowed.component';
import { StockManagementsComponent } from '../stock-managements/stock-managements.component';
import { InventoriesComponent } from '../inventories/inventories.component';
import { ProductsComponent } from '../products/products.component';


export const DashboardRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: DashboardComponent,
                canActivate: [RestrictPermissionsGuard],
            }, {
                path: 'brand-profile',
                component: BrandProfileComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'accounts',
                component: AccountsComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'roles',
                component: RolesComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'brand-invoice-profile',
                component: BrandInvoiceProfileComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'settings',
                component: SettingsComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'invoices',
                component: InvoicesComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'stock-management',
                component: StockManagementsComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'inventories',
                component: InventoriesComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'products',
                component: ProductsComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'not-allowed',
                component: NotAllowedComponent
            },
        ]
    },
];
