import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApplyFiltersModel } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { parseFilters } from 'src/app/shared/utils/filters.utils';
import { StockManagementsModel } from '../models/stock-management.model';

@Injectable({
    providedIn: 'root'
})

export class StockManagementService extends BaseService {
    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService) {
        super(_formErrorService);
    }


    getStockManagements$(payload: ApplyFiltersModel): Observable<PaginationModel> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.stockManagements}${parseFilters(payload)}`);
    }

    createStockManagement$(stockManagement: StockManagementsModel): Observable<StockManagementsModel> {
        return this.apiRequestService.submitPostRequest(apiEndpoints.stockManagements, stockManagement).pipe(map(res => ({ ...res.data })));
    }


    getStockManagement$(id: number): Observable<StockManagementsModel> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.stockManagements}/${id}`).pipe(map(res => ({ ...res.data })));
    };


    updateStockManagement$(stockManagement: StockManagementsModel): Observable<StockManagementsModel> {
        return this.apiRequestService.submitPutRequest(apiEndpoints.stockManagements + '/' + stockManagement.id, stockManagement).pipe(map(res => ({ ...res.data })));
    }

    removeStockManagement$(id: number, filters?: any): Observable<null> {
        return this.apiRequestService.submitDeleteRequest(filters ? apiEndpoints.stockManagements + '/' + id + filters : apiEndpoints.stockManagements + '/' + id);
    }
}
