import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountsFacade } from 'src/app/modules/accounts/store/accounts.facade';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableDataHeaders, TableHeadersTypeEnum } from 'src/app/shared/models/table-data-headers.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AppFacade } from 'src/app/store/app.facade';
import { AccountsModalComponent } from './components/accounts-modal/accounts-modal.component';
import { AccountModel } from './models/account.model';
import { isMobile } from 'src/app/shared/utils/responsive.utils';

@UntilDestroy()
@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.css']
})

export class AccountsComponent implements OnInit, OnDestroy {

    public PermissionsEnum = PermissionsEnum;
    public list$ = this._accountsFacade.accounts$;
    public pagination$ = this._accountsFacade.accountsPagination$;
    public loading$ = this._accountsFacade.accountsLoading$;
    public isMobile = isMobile();

    public filtersPayload: ApplyFiltersModel = {
        queryParams: {
            page: 1,
            records_number: 10
        },
        parsedFilters: ''
    }

    public filters: TableFilter[] = [
        {
            resourceName: 'name',
            label: 'Account',
            type: TableFilterTypesEnum.SELECT,
            controls: ['id'],
            style: 'col-md-4',
            firstOptionMessage: 'All',
            dropdownId: 'account',
            optionsList$: this._appFacade.accountsDropdownValues$,
        },
        {
            resourceName: 'role',
            label: 'Role',
            type: TableFilterTypesEnum.SELECT,
            controls: ['roles.id'],
            style: 'col-md-3',
            firstOptionMessage: 'All',
            dropdownId: 'role',
            optionsList$: this._appFacade.rolesDropdownValues$,
        },
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'LastName',
            fieldNames: ['last_name', 'first_name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
            cellType: TableCellType.FULL_NAME,
        },
        {
            name: 'Username',
            fieldNames: ['username'],
            cssClasses: 'all',
            searchable: true,
            sorting: true
        },
        {
            name: 'Phone',
            fieldNames: ['mobile'],
            cssClasses: 'all',
            searchable: true,
            sorting: true
        },
        {
            name: 'Email',
            fieldNames: ['email'],
            cssClasses: 'all',
            searchable: true,
            sorting: true
        },
        {
            name: 'Roles',
            fieldNames: ['roles'],
            cssClasses: 'desktop',
            searchable: true,
            sorting: true
        },
        {
            name: 'Status',
            fieldNames: ['status'],
            cssClasses: 'desktop',
            type: TableHeadersTypeEnum.STATUS,
            searchable: true,
            sorting: true
        },
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.USERS_ADD_AND_EDIT
        }
    ];

    @ViewChild('table', { static: true }) table: TableFiltersComponent;

    constructor(
        private _accountsFacade: AccountsFacade,
        private _appFacade: AppFacade,
        private _dialogService: DialogService,
    ) { }

    ngOnInit(): void { }

    ngOnDestroy(): void {
        this._accountsFacade.cleanState();
    }

    tableActionClicked(e: any) {
        this.openEditModal(e.entry);
    }

    addEntry() {
        this.openEditModal(null);
    }

    private openEditModal(entry: AccountModel | null) {
        this._dialogService
            .openModal(entry, AccountsModalComponent, DimensionsEnum.AUTO, '70vw')
            .afterClosed().pipe(untilDestroyed(this)).subscribe(() => this.applyFilters());
    }

    updatePage(event: PageEvent) {
        let queryParams = {
            page: event.pageIndex + 1,
            records_number: event.pageSize
        }
        this._accountsFacade.loadList({ ...this.filtersPayload, queryParams: queryParams });
    }

    applyFilters(filters?: ApplyFiltersModel) {
        filters && (this.filtersPayload = filters);
        this._accountsFacade.loadList(this.filtersPayload);
    }
}
