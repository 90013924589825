import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AccountsFacade } from 'src/app/modules/accounts/store/accounts.facade';
import { RoleModel } from 'src/app/modules/roles/models/role.model';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { AppFacade } from 'src/app/store/app.facade';
import { AccountModel } from '../../../models/account.model';

@UntilDestroy()
@Component({
    selector: 'app-accounts-general-info-tab',
    templateUrl: './accounts-general-info-tab.component.html',
    styleUrls: ['./accounts-general-info-tab.component.css']
})
export class AccountsGeneralInfoTabComponent implements OnInit {
    public form: FormGroup;
    public PermissionsEnum = PermissionsEnum;
    public error$ = this._accountsFacade.accountError$;
    public rolesDropdownValues$ = this._appFacade.rolesDropdownValues$;
    public userStatuses$ = this._appFacade.userStatuses$;

    @Input() selectedAccount: AccountModel;
    @Input() rolesDropdownValues: RoleModel[];
    @Output() onSaveAndClose: EventEmitter<boolean> = new EventEmitter();
    @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private _accountsFacade: AccountsFacade,
        private _appFacade: AppFacade,
    ) { }

    ngOnInit(): void { }

    saveAndClose() {
        this.onSaveAndClose.emit(true);
        this.onSubmit();
    }

    onSubmit() {
        const user = {
            ...this.form.value,
            id: this.selectedAccount?.id,
            roles_ids: [this.form.value.roles_ids || this.rolesDropdownValues[0]?.id]
        };

        !this.form.value.password && delete user.password;
        !this.form.value.password_confirmation && delete user.password_confirmation;

        if (!this.selectedAccount) {
            this._accountsFacade.createItem(user);
        } else {
            this.selectedAccount.self_update ? this._appFacade.updateLoggedUser(user) : this._accountsFacade.updateItem(user);
        }
    }

    updateUserForm(form: FormGroup) {
        this.form = form;
    }
}
