import { InventoryProductModel } from "../../inventories/models/inventory.model";

export class ProductModel {
    id: number;
    stock_management_id?: number;
    entry_inventory_id?: number;
    external_number?: string | number;
    internal_number?: string | number;
    name: string;
    measurement_unit?: string;
    price?: number | string;
    entry_inventory?: {
        id?: number;
        end_date?: string | null;
        start_date?: string;
    }
    inventory_product?: InventoryProductModel;
    inventory_products?: any[];
    product_id?: number;
}