import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SettingsModalComponent } from 'src/app/modules/settings/components/settings-modal/settings-modal.component';
import { SettingsFacade } from 'src/app/modules/settings/store/settings.facade';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SettingModel } from './models/settings.model';

@UntilDestroy()
@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
    public settings$ = this._settingsFacade.settings$;
    public loading$ = this._settingsFacade.settingsLoading$;
    public PermissionsEnum = PermissionsEnum;

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Name',
            fieldNames: ['name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true
        },
        {
            name: 'Value',
            fieldNames: ['value'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
        }
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.SETTINGS_ADD_AND_EDIT
        }
    ];

    constructor(
        public _settingsFacade: SettingsFacade,
        private _dialogService: DialogService,
    ) { }

    ngOnInit(): void {
        this._settingsFacade.loadList();
    }

    tableActionClicked(e: any) {
        this.openEditModal(e.entry);
    }

    addEntry() {
        this.openEditModal(null);
    }

    private openEditModal(entry: SettingModel | null) {
        this._dialogService.openModal(entry, SettingsModalComponent, DimensionsEnum.AUTO, '70vw');
    }
}
