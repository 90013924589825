import { HttpErrorResponse } from "@angular/common/http";
import { createAction } from "@ngrx/store";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import { InventoryModel, InventoryProductModel, ProductsUploadFormat } from "../models/inventory.model";

export const CLEAN_STATE = createAction('[Inventory] Clean state');
export const CLEAN_ITEM = createAction('[Inventory] Clean item');

export const LOAD_LIST = createAction('[Inventory] Load list', (payload: ApplyFiltersModel) => ({ payload }));
export const LOAD_LIST_SUCCESS = createAction('[Inventory] Load list success', (payload: PaginationModel) => ({ payload }));
export const LOAD_LIST_FAILURE = createAction('[Inventory] Load list fail', (error: HttpErrorResponse) => ({ error }));

export const SET_ITEM = createAction('[Inventory] Set item', (payload: InventoryModel) => ({ payload }));
export const LOAD_ITEM = createAction('[Inventory] Load item', (id: number) => ({ id }));
export const LOAD_ITEM_SUCCESS = createAction('[Inventory] Load item success', (payload: InventoryModel) => ({ payload }));
export const LOAD_ITEM_FAILURE = createAction('[Inventory] Load item fail', (error: HttpErrorResponse) => ({ error }));

export const CREATE = createAction('[Inventory] Create item', (payload: InventoryModel) => ({ payload }));
export const CREATE_SUCCESS = createAction('[Inventory] Create item success', (payload: InventoryModel) => ({ payload }));
export const CREATE_FAILURE = createAction('[Inventory] Create item fail', (error: HttpErrorResponse) => ({ error }));

export const UPDATE = createAction('[Inventory] Update item', (payload: InventoryModel) => ({ payload }));
export const UPDATE_SUCCESS = createAction('[Inventory] Update item success', (payload: InventoryModel) => ({ payload }));
export const UPDATE_FAILURE = createAction('[Inventory] Update item fail', (error: HttpErrorResponse) => ({ error }));

export const DELETE = createAction('[Inventory] Delete item', (id: number) => ({ id }));
export const DELETE_SUCCESS = createAction('[Inventory] Delete item success', (id: number) => ({ id }));
export const DELETE_FAILURE = createAction('[Inventory] Delete item fail', (error: HttpErrorResponse) => ({ error }));

export const CLOSE = createAction('[Inventory] Close item', (id: number) => ({ id }));
export const CLOSE_SUCCESS = createAction('[Inventory] Close item success', (payload: InventoryModel) => ({ payload }));
export const CLOSE_FAILURE = createAction('[Inventory] Close item fail', (error: HttpErrorResponse) => ({ error }));

export const IMPORT_FORMAT = createAction('[Inventory] Import format item', () => null);
export const IMPORT_FORMAT_SUCCESS = createAction('[Inventory] Import format item success', (response) => response);
export const IMPORT_FORMAT_FAILURE = createAction('[Inventory] Import format item fail', (error: HttpErrorResponse) => ({ error }));

export const IMPORT_PRODUCTS = createAction('[Inventory] Import products item', (payload: ProductsUploadFormat) => payload);
export const IMPORT_PRODUCTS_SUCCESS = createAction('[Inventory] Import products success', (response: any) => response);
export const IMPORT_PRODUCTS_FAILURE = createAction('[Inventory] Import products fail', (error: HttpErrorResponse) => ({ error }));

export const SET_INVENTORY_PRODUCTS = createAction('[Inventory] Set inventory products', (payload: InventoryProductModel[]) => ({ payload }));
export const LOAD_INVENTORY_PRODUCTS = createAction('[Inventory] Load inventory products', (payload: InventoryProductModel) => ({ payload }));
export const LOAD_INVENTORY_PRODUCTS_SUCCESS = createAction('[Inventory] Load inventory products success', (payload: InventoryProductModel) => ({ payload }));
export const LOAD_INVENTORY_PRODUCTS_FAILURE = createAction('[Inventory] Load inventory products fail', (error: HttpErrorResponse) => ({ error }));

export const UPDATE_INVENTORY_PRODUCT = createAction('[Inventory] Update inventory product', (id: number, status: string, filters?: string) => ({ id, status, filters }));
export const UPDATE_INVENTORY_PRODUCT_SUCCESS = createAction('[Inventory] Update inventory product success', (payload) => payload);
export const UPDATE_INVENTORY_PRODUCT_FAILURE = createAction('[Inventory] Update inventory product fail', (error: HttpErrorResponse) => ({ error }));

export const DISPLAY_QR = createAction('[Inventory] Display QR', (id: number, params?: string) => ({ id, params }));
export const DISPLAY_QR_SUCCESS = createAction('[Inventory] Display QR success', response => response);
export const DISPLAY_QR_FAILURE = createAction('[Inventory] Display QR fail', (error: HttpErrorResponse) => ({ error }));

export const SCAN = createAction('[Products] Scan item', (id: number, status: string) => ({ id, status }));
export const SCAN_SUCCESS = createAction('[Products] Scan item success', (payload: InventoryProductModel) => ({ payload }));
export const SCAN_FAILURE = createAction('[Products] Scan item fail', (error: HttpErrorResponse) => ({ error }));

export const MASS_UPDATE = createAction('[Inventory] Mass update', (products_ids, status, inventory_id, filters) => ({ products_ids, status, inventory_id, filters }));
export const MASS_UPDATE_SUCCESS = createAction('[Inventory] Mass update success', (response) => ({ response }));
export const MASS_UPDATE_FAILURE = createAction('[Inventory] Mass update fail', (error: HttpErrorResponse) => ({ error }));

export const UPDATE_PRODUCTS_STOCK_MANAGEMENT = createAction('[Inventory] Update products stock management', (payload: any, inventory_id: number, filters?: string) => ({ payload, inventory_id, filters }));
export const UPDATE_PRODUCTS_STOCK_MANAGEMENT_SUCCESS = createAction('[Inventory] Update products stock management success', (response) => response);
export const UPDATE_PRODUCTS_STOCK_MANAGEMENT_FAILURE = createAction('[Inventory] Update products stock management fail', (error: HttpErrorResponse) => ({ error }));

export const SETCLOSETOFALSE = createAction('[Inventory] Delete item fail', () => null);
export const SET_MASS_UPDATE_TO_FALSE = createAction('[Inventory] Mass update to false', () => null);
export const SET_UPLOAD_SUCCESS_TO_FALSE = createAction('[Inventory] Upload success to false', () => null);


export const PRODUCT = createAction('[Inventory] Product', (id: number) => ({ id }));
export const PRODUCT_SUCCESS = createAction('[Inventory] Product success', (payload: InventoryProductModel) => ({ payload }));
export const PRODUCT_FAILURE = createAction('[Inventory] Product fail', (error: HttpErrorResponse) => ({ error }));
