import { ExportFormatsEnum } from "../enums/export-formats.enum";

export const fileFormatTypes: { [key in ExportFormatsEnum]: any } = {
    JSON: {
        responseType: 'text',
        contentType: 'text/json',
        extension: 'json'
    },
    SAGA: {
        responseType: 'text',
        contentType: 'text/xml',
        extension: 'xml'
    },
    WinMENTOR: {
        responseType: 'text',
        contentType: 'text/xml',
        extension: 'txt'
    }
}