import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, of, switchMap, tap } from "rxjs";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import * as fromActions from "./inventory.actions";
import { InventoryModel } from "../models/inventory.model";
import { InventoryService } from "../services/inventory.service";
import { Router } from "@angular/router";

@Injectable()
export class InventoryEffects {

    constructor(private _actions$: Actions, private _inventoryService: InventoryService, private _router: Router) { }

    public loadList$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_LIST),
        switchMap(action =>
            this._inventoryService.getInventories$(action.payload).pipe(
                map((res: PaginationModel) => fromActions.LOAD_LIST_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_LIST_FAILURE(error)))
            )
        )
    ));

    public loadItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_ITEM),
        switchMap(action =>
            this._inventoryService.getInventory$(action.id).pipe(
                map((res: InventoryModel) => fromActions.LOAD_ITEM_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_ITEM_FAILURE(error)))
            )
        )
    ));

    public loadProduct$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.PRODUCT),
        switchMap(action =>
            this._inventoryService.getProduct$(action.id).pipe(
                map((res: any) => fromActions.PRODUCT_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.PRODUCT_FAILURE(error)))
            )
        )
    ));

    public createItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.CREATE),
        switchMap(action =>
            this._inventoryService.createInventory$(action.payload).pipe(
                map((res) => fromActions.CREATE_SUCCESS(res)),
                tap(() => this._inventoryService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on create inventory', error);
                    return of(fromActions.CREATE_FAILURE(error));
                })
            )
        )
    ));

    public updateItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.UPDATE),
        switchMap(action =>
            this._inventoryService.updateInventory$(action.payload).pipe(
                map((res) => fromActions.UPDATE_SUCCESS(res)),
                tap(() => this._inventoryService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on update inventory', error);
                    return of(fromActions.UPDATE_FAILURE(error));
                })
            )
        )
    ));

    public removeItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.DELETE),
        switchMap(action =>
            this._inventoryService.removeInventory$(action.id).pipe(
                map((res) => fromActions.DELETE_SUCCESS(res)),
                tap(() => this._inventoryService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on remove inventory', error);
                    return of(fromActions.DELETE_FAILURE(error));
                })
            )
        )
    ));

    public closeInventory$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.CLOSE),
        switchMap(action =>
            this._inventoryService.closeInventory$(action.id).pipe(
                map((res) => fromActions.CLOSE_SUCCESS(res)),
                tap(() => this._inventoryService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on close inventory', error);
                    return of(fromActions.CLOSE_FAILURE(error));
                })
            )
        )
    ));

    public importFormat$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.IMPORT_FORMAT),
        switchMap(action =>
            this._inventoryService.importFormat$().pipe(
                map((res) => fromActions.IMPORT_FORMAT_SUCCESS(res)),
                tap((res) => {
                    this.downloadFile(res.body, this.getFileNameFromHeaders(res.headers))
                }),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on import format', error);
                    return of(fromActions.IMPORT_FORMAT_FAILURE(error));
                })
            )
        )
    ));

    public importProducts$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.IMPORT_PRODUCTS),
        switchMap(action => {
            const formData: FormData = new FormData();
            formData.append('file', action.file.file, action.file.filename);
            formData.append('overwrite_stock_management', action.file.overwrite_stock_management);
            return this._inventoryService.importProducts$(formData, action.id).pipe(
                map((res) => fromActions.IMPORT_PRODUCTS_SUCCESS(res)),
                tap((res) => {
                    this._inventoryService.onSaveSuccess$.next(true);

                }),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on import format', error);
                    return of(fromActions.IMPORT_PRODUCTS_FAILURE(error));
                })
            )
        })
    ));

    public inportProductsSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(fromActions.IMPORT_PRODUCTS_SUCCESS),
            exhaustMap((action) => {
                return this._inventoryService.getInventoryProducts$(action.data.id).pipe(
                    map((res) => fromActions.LOAD_INVENTORY_PRODUCTS_SUCCESS(res)),
                    tap((res) => this._inventoryService.onSaveSuccess$.next(true)),
                    catchError((error: HttpErrorResponse) => {
                        this._inventoryService.reportError('Error on get inventory products', error);
                        return of(fromActions.LOAD_INVENTORY_PRODUCTS_FAILURE(error));
                    })
                )
            }),
        )
    }
    );

    public loadInventoryProducts$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_INVENTORY_PRODUCTS),
        switchMap(action => {
            return this._inventoryService.getInventoryProducts$(action.payload.id, action.payload.filters).pipe(
                map((res) => fromActions.LOAD_INVENTORY_PRODUCTS_SUCCESS(res)),
                tap((res) => {
                    this._inventoryService.onSaveSuccess$.next(true);

                }),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on get inventory products', error);
                    return of(fromActions.LOAD_INVENTORY_PRODUCTS_FAILURE(error));
                })
            )
        })
    ));

    public updateInventoryProduct$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.UPDATE_INVENTORY_PRODUCT),
        switchMap(action => {
            return this._inventoryService.updateInventoryProduct$(action.id, action.status, action.filters).pipe(
                map((res) => fromActions.UPDATE_INVENTORY_PRODUCT_SUCCESS(res)),
                tap((res) => {
                    this._inventoryService.onSaveSuccess$.next(true);
                }),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on updates inventory product', error);
                    return of(fromActions.UPDATE_INVENTORY_PRODUCT_FAILURE(error));
                })
            )
        })
    ));

    public updateProductStockManagement$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.UPDATE_PRODUCTS_STOCK_MANAGEMENT),
        switchMap(action => {
            return this._inventoryService.massUpdateProductStockManagement$(action.payload, action.inventory_id, action.filters).pipe(
                map((res) => fromActions.UPDATE_PRODUCTS_STOCK_MANAGEMENT_SUCCESS(res)),
                tap((res) => this._inventoryService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on updates products stock management', error);
                    return of(fromActions.UPDATE_PRODUCTS_STOCK_MANAGEMENT_FAILURE(error));
                })
            )
        })
    ));

    public updateProductStockManagementSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(fromActions.UPDATE_PRODUCTS_STOCK_MANAGEMENT_SUCCESS),
            exhaustMap((action) => {
                return this._inventoryService.getInventoryProducts$(action.inventory_id, action.filters).pipe(
                    map((res) => fromActions.LOAD_INVENTORY_PRODUCTS_SUCCESS(res)),
                    tap((res) => this._inventoryService.onSaveSuccess$.next(true)),
                    catchError((error: HttpErrorResponse) => {
                        this._inventoryService.reportError('Error on get inventory products', error);
                        return of(fromActions.LOAD_INVENTORY_PRODUCTS_FAILURE(error));
                    })
                )
            }),
        )
    }
    );

    public getQRCodes$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.DISPLAY_QR),
        switchMap(action => {
            return this._inventoryService.displayQRs$(action.id, action.params).pipe(
                map((res) => fromActions.DISPLAY_QR_SUCCESS(res)),
                tap((res) => {
                    this._inventoryService.onSaveSuccess$.next(true);
                    this.downloadFile(res.body, this.getFileNameFromHeaders(res.headers))
                }),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on get QR codes', error);
                    return of(fromActions.DISPLAY_QR_FAILURE(error));
                })
            )
        })
    ));

    public updateInventoryProductSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(fromActions.UPDATE_INVENTORY_PRODUCT_SUCCESS),
            exhaustMap((action) => {
                return this._inventoryService.getInventoryProducts$(action.inventory_id, action.filters).pipe(
                    map((res) => fromActions.LOAD_INVENTORY_PRODUCTS_SUCCESS(res)),
                    tap((res) => this._inventoryService.onSaveSuccess$.next(true)),
                    catchError((error: HttpErrorResponse) => {
                        this._inventoryService.reportError('Error on get inventory products', error);
                        return of(fromActions.LOAD_INVENTORY_PRODUCTS_FAILURE(error));
                    })
                )
            }),
        )
    }
    );

    public scanProduct$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.SCAN),
        switchMap(action => {
            return this._inventoryService.scanProduct$(action.id, action.status).pipe(
                map((res) => fromActions.SCAN_SUCCESS(res)),
                tap((res) => {
                    this._inventoryService.onSaveSuccess$.next(true);
                }),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on scan products', error);
                    return of(fromActions.SCAN_FAILURE(error));
                })
            )
        })
    ));

    public massUpdate$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.MASS_UPDATE),
        switchMap(action => {
            return this._inventoryService.massUpdate$(action.products_ids, action.status, action.inventory_id, action.filters).pipe(
                map((res) => fromActions.MASS_UPDATE_SUCCESS(res)),
                tap((res) => this._inventoryService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._inventoryService.reportError('Error on mass update', error);
                    return of(fromActions.MASS_UPDATE_FAILURE(error));
                })
            )
        })
    ));

    public massUpdateSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(fromActions.MASS_UPDATE_SUCCESS),
            exhaustMap((action) => {
                return this._inventoryService.getInventoryProducts$(action.response.inventory_id, action.response.filters).pipe(
                    map((res) => fromActions.LOAD_INVENTORY_PRODUCTS_SUCCESS(res)),
                    tap((res) => this._inventoryService.onSaveSuccess$.next(true)),
                    catchError((error: HttpErrorResponse) => {
                        this._inventoryService.reportError('Error on get inventory products', error);
                        return of(fromActions.LOAD_INVENTORY_PRODUCTS_FAILURE(error));
                    })
                )
            }),
        )
    }
    );

    getFileNameFromHeaders(headers: HttpHeaders): string {
        let name = headers.get('Content-Disposition')?.split(';')[1].split('=')[1].replace(/\"/g, '')
        return name ? name : 'Import Format';
    }

    downloadFile(file: any, fileName: string) {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    }
}