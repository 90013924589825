import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { AccountModel } from 'src/app/modules/accounts/models/account.model';
import { AccountsFacade } from 'src/app/modules/accounts/store/accounts.facade';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { SETTINGS } from 'src/app/shared/constants';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { createFileName } from 'src/app/shared/utils/export.utils';
import { parseMultiselectFilters } from 'src/app/shared/utils/form.utils';
import { AppFacade } from 'src/app/store/app.facade';
import { InvoiceModel } from '../../models/invoice.model';
import { ExportFormatsService } from './services/export-format.service';


@UntilDestroy()
@Component({
    selector: 'app-export-invoices-modal',
    templateUrl: './export-invoices-modal.component.html',
    styleUrls: ['./export-invoices-modal.component.css']
})
export class ExportInvoicesModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

    public form: FormGroup;
    public SETTINGS = SETTINGS;
    public filters: string;
    public invoicesList: InvoiceModel[];
    public PermissionsEnum = PermissionsEnum;
    public accounts$ = this._accountsFacade.accounts$;
    public accountsDropdownValues$ = this._appFacade.accountsDropdownValues$;
    public currentUser: AccountModel;
    public isAdmin = false;
    public showList = false;
    public isLoading = true;
    public formatExportCtrl = new FormControl;

    constructor(public dialogRef: MatDialogRef<ExportInvoicesModalComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _accountsFacade: AccountsFacade,
        private _appFacade: AppFacade,
        private _translateService: TranslateService,
        private _fb: FormBuilder,
        private _exportFormatsSerivce: ExportFormatsService) {
        super(dialogRef, _exportFormatsSerivce);
    }

    ngOnInit(): void {
        this._accountsFacade.loadList(null);

        this.form = this._fb.group({
            start_date: moment().format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
            user_id: 0
        });

        this.form.valueChanges.subscribe(formValues => {
            const { user_id, ...rest } = formValues;
            const payload = {
                user_id: user_id
            };

            for (const prop in rest) {
                payload[prop] = moment(rest[prop]).format('YYYY-MM-DD');
            }

            this._exportFormatsSerivce.exportInvoices(parseMultiselectFilters(payload));
        })

        this._exportFormatsSerivce.invoicesToExport$.pipe(filter(invoices => !!invoices), untilDestroyed(this)).subscribe(invoices => this.invoicesList = invoices);

        // this.accounts$.pipe(filter(users => !!users), untilDestroyed(this)).subscribe(users => this.usersList = users);

        this._appFacade.loggedUser$.pipe(filter(user => !!user), untilDestroyed(this)).subscribe(user => {
            this.currentUser = user;
            this.currentUser.roles[0] === 'admin' && (this.isAdmin = true);
            this.form.controls.user_id.setValue(this.isAdmin ? 0 : user.id);
        });
        this._exportFormatsSerivce.isLoading$.pipe(untilDestroyed(this)).subscribe(isLoading => this.isLoading = isLoading);
    }

    ngOnDestroy(): void {
        this._exportFormatsSerivce.invoicesToExport$.next(undefined);
    }

    exportInvoices(): void {
        const exportFormat = this.formatExportCtrl.value;
        const title = this._translateService.instant('ExportInvoices');
        const fileName = createFileName(title, this.form, this.accounts$);
        const queryParams = `&format_as=${exportFormat}&export_as=file`;
        this._exportFormatsSerivce.exportInvoices(parseMultiselectFilters(this.form.value), queryParams, exportFormat, fileName);
    }
}
