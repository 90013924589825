<div class="row mt-5">
    <div class="col-md-6 text-center d-flex flex-column">
        <h5 class="font-weight-bold">{{ "Imagine" | translate }}</h5>
        <div class="fileinput fileinput-new {{selectedAccount?.profile_pic && 'd-flex flex-column align-items-center h-100'}}">
            <div class="image-wrapper mb-2 text-center">
                <div class="fileinput-new thumbnail">
                    <img src="{{ selectedAccount?.profile_pic || './assets/img/image_placeholder.jpg'}}"
                         onerror='this.onerror = null; this.src="./assets/img/image_placeholder.jpg"'
                         class="max-height"
                         alt="Profile picture">
                </div>
            </div>
            <div *ngIf="selectedAccount?.profile_pic"
                 class="d-flex justify-content-center">
                <button class="btn btn-danger btn-round"
                        (click)="removeProfilePic.emit()">
                    {{ "Delete" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="col-md-6 d-flex flex-column align-items-center justify-content-between">
        <h5>{{ "RecommendedDimensions" | translate }} imagine (350 x 350px)</h5>
        <div class="fileinput fileinput-new"
             data-provides="fileinput">
            <div class="image-wrapper mb-2 text-center">
                <div class="fileinput-new thumbnail">
                    <img src="./assets/img/image_placeholder.jpg"
                         alt="...">
                </div>
                <div class="fileinput-preview fileinput-exists thumbnail image-wrapper"></div>
            </div>
            <div class="d-flex justify-content-center">
                <span class="btn btn-primary btn-round btn-file">
                    <span class="fileinput-new">{{"SelectImage" | translate}}</span>
                    <span class="fileinput-exists">{{"Change" | translate}}</span>
                    <input #fileInputToUpload
                           type="file"
                           name="logo"
                           (change)="handleFileInput($event.target.files)" />
                </span>
                <button #removeLogoImg
                        class="btn btn-danger btn-round fileinput-exists"
                        data-dismiss="fileinput"
                        (click)="removeLocalFile(fileInputToUpload)">
                    {{"Delete" | translate}}
                </button>
            </div>
        </div>
    </div>

</div>

<app-dialog-footer-buttons class="w-100"
                           [disable]="!imgCtrl.value"
                           (close)="closeModal.emit()"
                           (saveAndClose)="saveAndClose()"
                           (save)="onSubmit()">
</app-dialog-footer-buttons>