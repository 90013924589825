import { NgModule } from "@angular/core";

import { VarDirective } from "./ng-var.directive";
import { ValueOrActionDirective } from './value-or-action.directive';
import { RestrictPermissionsDirective } from './restrict-permissions.directive';
import { CustomPermissionsDirective } from './custom-permissions.directive';
import { TransformToIconsDirective } from './transform-to-icons.directive';
import { CreateTooltipDirective } from './create-tooltip.directive';
import { CreateBadgeDirective } from './create-badge.directive';
import { FormValidatorDirective } from "./form-validator.directive";
import { StrippedTableDirective } from "./stripped-table.directive";
import { InitDirective } from "./init.directive";
import { FormatCreatedAtDirective } from "./format-created-at.directive";
import { UpdateDateRangeValueDirective } from "./update-date-range-value.directive";
import { RerenderDirective } from "./rerender.directive";




@NgModule({
    imports: [],
    declarations: [
        RestrictPermissionsDirective,
        CustomPermissionsDirective,
        VarDirective,
        TransformToIconsDirective,
        ValueOrActionDirective,
        CreateTooltipDirective,
        CreateBadgeDirective,
        FormValidatorDirective,
        StrippedTableDirective,
        InitDirective,
        FormatCreatedAtDirective,
        UpdateDateRangeValueDirective,
        RerenderDirective
    ],
    exports: [
        RestrictPermissionsDirective,
        CustomPermissionsDirective,
        VarDirective,
        TransformToIconsDirective,
        ValueOrActionDirective,
        CreateTooltipDirective,
        CreateBadgeDirective,
        FormValidatorDirective,
        StrippedTableDirective,
        InitDirective,
        FormatCreatedAtDirective,
        UpdateDateRangeValueDirective,
        RerenderDirective
    ]
})

export class DirectivesModule { }