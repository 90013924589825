import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { UserStatusesModel } from 'src/app/modules/accounts/models/account-statuses.model';
import { AccountModel } from 'src/app/modules/accounts/models/account.model';
import { RoleModel } from 'src/app/modules/roles/models/role.model';
import { ApiRequestService, apiEndpoints } from './api-request.service';
import { StockManagementsModel } from 'src/app/modules/stock-managements/models/stock-management.model';
import { InventoryModel } from 'src/app/modules/inventories/models/inventory.model';

@Injectable({
    providedIn: 'root'
})
export class AppInitResourcesService {

    constructor(private _apiRequestService: ApiRequestService) { }

    getUserStatuses$(): Observable<UserStatusesModel[]> {
        return this._apiRequestService.submitGetRequest(apiEndpoints.userStatuses).pipe(map(res => ([...res.data])));
    }

    getRolesDropdownValues$(): Observable<Partial<RoleModel[]>> {
        return this._apiRequestService.submitGetRequest(`${apiEndpoints.roles}/dropdown-values`);
    }

    getAccountsDropdownValues$(): Observable<Partial<AccountModel[]>> {
        return this._apiRequestService.submitGetRequest(`${apiEndpoints.accounts}/dropdown-values`).pipe(map(res => ([...res.data])));
    }

    getStockManagementDropdownValues$(): Observable<Partial<StockManagementsModel[]>> {
        return this._apiRequestService.submitGetRequest(`${apiEndpoints.stockManagements}/dropdown-values`).pipe(map(res => ([...res.data])));
    }
    getInventoryDropdownValues$(filters?: string): Observable<Partial<InventoryModel[]>> {
        return this._apiRequestService.submitGetRequest(filters ? `${apiEndpoints.inventories}/dropdown-values?${filters}` : `${apiEndpoints.inventories}/dropdown-values`).pipe(map(res => ([...res.data])));
    }
}
