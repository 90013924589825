import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { isMobile } from 'src/app/shared/utils/responsive.utils';
import { StockManagementsFacade } from './store/stock-management.facade';
import { AppFacade } from 'src/app/store/app.facade';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { StockManagementsModel } from './models/stock-management.model';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PageEvent } from '@angular/material/paginator';
import { StockManagementModalComponent } from './components/stock-management-modal/stock-management-modal.component';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { filter } from 'rxjs';
@UntilDestroy()
@Component({
    selector: 'app-stock-managements',
    templateUrl: './stock-managements.component.html',
    styleUrls: ['./stock-managements.component.css']
})
export class StockManagementsComponent implements OnInit, OnDestroy {

    public PermissionsEnum = PermissionsEnum;
    public list$ = this._stockManagementFacade.stockManagements$;
    public pagination$ = this._stockManagementFacade.stockManagementsPagination$;
    public loading$ = this._stockManagementFacade.stockManagementLoading$;
    public isMobile = isMobile();

    public filtersPayload: ApplyFiltersModel = {
        queryParams: {
            page: 1,
            records_number: 10
        },
        parsedFilters: ''
    }

    public filters: TableFilter[] = [
        {
            resourceName: 'name',
            label: 'StockManagement',
            type: TableFilterTypesEnum.TEXT,
            controls: ['name'],
            style: 'col-md-3',
            placeholders: [''],
            data_cy: 'name-filter'
        },
        {
            resourceName: 'code',
            label: 'Code',
            type: TableFilterTypesEnum.TEXT,
            controls: ['code'],
            style: 'col-md-3',
            placeholders: [''],
            data_cy: 'code-filter'
        }
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Name',
            fieldNames: ['name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
            cellType: TableCellType.FULL_NAME,
        },
        {
            name: 'Code',
            fieldNames: ['code'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
            hideOnMobile: true
        },
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.STOCK_MANAGEMENTS_ADD_AND_EDIT
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.STOCK_MANAGEMENTS_DELETE
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.STOCK_MANAGEMENTS_DELETE
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.STOCK_MANAGEMENTS_DELETE
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.STOCK_MANAGEMENTS_DELETE
        }
    ];

    @ViewChild('table', { static: true }) table: TableFiltersComponent;

    constructor(
        private _stockManagementFacade: StockManagementsFacade,
        private _dialogService: DialogService,
        private _sweetAlertService: SweetAlertService,
        private _appFacade: AppFacade,
    ) { }

    ngOnInit(): void {
        this._stockManagementFacade.stockManagementsFilters$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.filtersPayload = data);
        this._appFacade.loadStockManagementsDropdownValues();
    }

    ngOnDestroy(): void {
        this._stockManagementFacade.cleanState();
    }

    tableActionClicked(e: any) {
        if (e.type === TableActionTypeEnum.Edit) {
            this.openEditModal(e.entry);
        }
        if (e.type === TableActionTypeEnum.Delete) {
            this._sweetAlertService.showSwal('warning-message', () => this.deleteEntry(e.entry), 'StockManagement');
        }
    }

    addEntry() {
        this.openEditModal(null);
    }

    deleteEntry(entry) {
        this._stockManagementFacade.deleteItem(entry.id, this.filtersPayload);
    }

    private openEditModal(entry: StockManagementsModel | null) {
        this._dialogService
            .openModal(entry, StockManagementModalComponent, DimensionsEnum.AUTO, '70vw')
            .afterClosed().pipe(untilDestroyed(this)).subscribe(() => this.applyFilters());
    }

    updatePage(event: PageEvent) {
        let queryParams = {
            page: event.pageIndex + 1,
            records_number: event.pageSize
        }
        this._stockManagementFacade.loadList({ ...this.filtersPayload, queryParams: queryParams });
    }

    applyFilters(filters?: ApplyFiltersModel) {
        filters && (this.filtersPayload = filters);
        this._stockManagementFacade.loadList(this.filtersPayload);
    }
}
