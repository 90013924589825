import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import * as fromActions from './inventory.actions';
import * as fromState from './inventory.state';

export const inventoryReducer = createReducer(
    fromState.inventoryInitialState,
    on(
        fromActions.CLEAN_STATE,
        () => fromState.adapter.getInitialState(fromState.inventoryInitialState)
    ),

    on(
        fromActions.CLEAN_ITEM,
        (state) => produce(state, (draft) => {
            draft.selectedInventory = produce(fromState.inventoryInitialState.selectedInventory, () => { });
        })
    ),

    on(
        fromActions.LOAD_LIST,
        (state, action) => produce(state, (draft) => {
            draft.loading = true;
            draft.error = undefined;
            draft.filters = action.payload;
        })
    ),

    on(
        fromActions.LOAD_LIST_SUCCESS,
        (state, action) => fromState.adapter.setAll(
            action.payload.data,
            produce(state, (draft) => {
                const { data, ...rest } = action.payload;
                draft.pagination = rest
                draft.loading = false;
                draft.error = undefined;
            })
        )
    ),

    on(
        fromActions.LOAD_LIST_FAILURE,
        (state, action) => fromState.adapter.removeAll(
            produce(state, (draft) => {
                draft.loading = false;
                draft.error = action.error.error;
            })
        )
    ),

    on(
        fromActions.SET_ITEM,
        (state, action) => produce(state, (draft) => {
            draft.selectedInventory.inventory = action.payload;
        })
    ),

    on(
        fromActions.LOAD_ITEM,
        (state) => produce(state, (draft) => {
            draft.selectedInventory.inventory = undefined;
            draft.selectedInventory.loading = true;
            draft.selectedInventory.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_ITEM_SUCCESS,
        (state, action) => produce(state, (draft) => {
            draft.selectedInventory.inventory = action.payload;
            draft.selectedInventory.loading = false;
            draft.selectedInventory.error = undefined;
        })
    ),

    on(
        fromActions.LOAD_ITEM_FAILURE,
        (state, action) => produce(state, (draft) => {
            draft.selectedInventory.loading = false;
            draft.selectedInventory.error = action.error.error;
        })
    ),

    on(
        fromActions.CREATE,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedInventory.loading = true;
                draft.selectedInventory.error = undefined;
            });
        }
    ),
    on(
        fromActions.CREATE_SUCCESS,
        (state, action) => {
            return fromState.adapter.addOne(action.payload,
                produce(state, (draft) => {
                    draft.selectedInventory.inventory = produce(state.selectedInventory.inventory, () => action.payload);
                    draft.selectedInventory.loading = false;
                    draft.selectedInventory.error = undefined;
                    draft.selectedInventory.saveAndClose = true;
                })
            )
        }
    ),

    on(
        fromActions.CREATE_FAILURE,
        (state, action) => {
            return produce(state, (draft) => {
                draft.selectedInventory.loading = false;
                draft.selectedInventory.error = action.error.error;
                draft.selectedInventory.saveAndClose = false;
            })
        }
    ),

    on(
        fromActions.UPDATE,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedInventory.loading = true;
                draft.selectedInventory.error = undefined;

            });
        }
    ),

    on(
        fromActions.UPDATE_SUCCESS,
        (state, action) => fromState.adapter.updateOne({ id: action.payload.id, changes: action.payload },
            produce(state, (draft) => {
                draft.selectedInventory.inventory = produce(state.selectedInventory.inventory, () => action.payload);
                draft.selectedInventory.loading = false;
                draft.selectedInventory.error = undefined;
                draft.selectedInventory.saveAndClose = true;

            })
        )
    ),

    on(
        fromActions.UPDATE_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.loading = false;
                draft.selectedInventory.error = action.error.error;
                draft.selectedInventory.saveAndClose = false;
            })
    ),
    on(
        fromActions.CLOSE,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedInventory.loading = true;
                draft.selectedInventory.error = undefined;

            });
        }
    ),

    on(
        fromActions.CLOSE_SUCCESS,
        (state, action) => fromState.adapter.updateOne({ id: action.payload.id, changes: action.payload },
            produce(state, (draft) => {
                draft.selectedInventory.inventory = produce(state.selectedInventory.inventory, () => action.payload);
                draft.selectedInventory.loading = false;
                draft.selectedInventory.error = undefined;
            })
        )
    ),

    on(
        fromActions.CLOSE_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.loading = false;
                draft.selectedInventory.error = action.error.error;
                draft.selectedInventory.saveAndClose = false;
            })
    ),


    on(
        fromActions.SETCLOSETOFALSE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.saveAndClose = false;
            })
    ),


    on(
        fromActions.IMPORT_FORMAT,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedInventory.loading = true;
                draft.selectedInventory.error = undefined;

            });
        }
    ),

    on(
        fromActions.IMPORT_FORMAT_SUCCESS,
        (state, action) => {
            return produce(state, (draft) => {
                draft.selectedInventory.importFormat = produce(state.selectedInventory.importFormat, () => action.payload);
                draft.selectedInventory.loading = false;
                draft.selectedInventory.error = undefined;
            })
        }
    ),

    on(
        fromActions.IMPORT_FORMAT_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.loading = false;
                draft.selectedInventory.error = action.error.error;
            })
    ),

    on(
        fromActions.IMPORT_PRODUCTS,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedInventory.loading = true;
                draft.selectedInventory.products.error = undefined;

            });
        }
    ),

    on(
        fromActions.IMPORT_PRODUCTS_SUCCESS,
        (state, action) => {
            return produce(state, (draft) => {
                draft.selectedInventory.products.import = produce(state.selectedInventory.products.import, () => action.data);
                draft.selectedInventory.loading = false;
                draft.selectedInventory.products.error = undefined;
                draft.selectedInventory.products.importSuccess = true;
            })
        }
    ),

    on(
        fromActions.IMPORT_PRODUCTS_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.loading = false;
                draft.selectedInventory.products.error = action.error.error;
                draft.selectedInventory.products.importSuccess = false;
            })
    ),


    on(
        fromActions.LOAD_INVENTORY_PRODUCTS,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedInventory.products.loading = true;
                draft.selectedInventory.products.error = undefined;

            });
        }
    ),

    on(
        fromActions.LOAD_INVENTORY_PRODUCTS_SUCCESS,
        (state, action) => {
            return produce(state, (draft) => {
                draft.selectedInventory.products.products = produce(state.selectedInventory.products.products, () => action.payload);
                draft.selectedInventory.products.loading = false;
                draft.selectedInventory.products.error = undefined;
            })
        }
    ),

    on(
        fromActions.LOAD_INVENTORY_PRODUCTS_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.products.loading = false;
                draft.selectedInventory.products.error = action.error.error;
            })
    ),
    on(
        fromActions.DISPLAY_QR,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedInventory.products.loading = true;
                draft.selectedInventory.products.error = undefined;

            });
        }
    ),

    on(
        fromActions.DISPLAY_QR_SUCCESS,
        (state, action) => {
            return produce(state, (draft) => {
                draft.selectedInventory.qrCodes = produce(state.selectedInventory.qrCodes, () => action.id);
                draft.selectedInventory.loading = false;
                draft.selectedInventory.error = undefined;
            })
        }
    ),

    on(
        fromActions.DISPLAY_QR_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.loading = false;
                draft.selectedInventory.error = action.error.error;
            })
    ),

    on(
        fromActions.UPDATE_INVENTORY_PRODUCT,
        (state) => {
            return produce(state, (draft) => {
                draft.selectedInventory.selectedInventoryProduct.loading = true;
                draft.selectedInventory.selectedInventoryProduct.error = undefined;

            });
        }
    ),

    on(
        fromActions.UPDATE_INVENTORY_PRODUCT_SUCCESS,
        (state, action) => {
            return produce(state, (draft) => {
                draft.selectedInventory.selectedInventoryProduct.loading = false;
                draft.selectedInventory.selectedInventoryProduct.error = undefined;
            })
        }
    ),

    on(
        fromActions.UPDATE_INVENTORY_PRODUCT_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.selectedInventoryProduct.loading = false;
                draft.selectedInventory.selectedInventoryProduct.error = action.error.error;
            })
    ),


    on(
        fromActions.SCAN,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.selectedInventoryProduct.loading = true;
                draft.selectedInventory.selectedInventoryProduct.error = undefined;
            })
    ),

    on(
        fromActions.SCAN_SUCCESS,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.selectedInventoryProduct.product = produce(state.selectedInventory.selectedInventoryProduct.product, () => action.payload);
                draft.selectedInventory.selectedInventoryProduct.loading = false;
                draft.selectedInventory.selectedInventoryProduct.error = undefined;
            })
    ),
    on(
        fromActions.PRODUCT_SUCCESS,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.selectedInventoryProduct.product = produce(state.selectedInventory.selectedInventoryProduct.product, () => action.payload);
                draft.selectedInventory.selectedInventoryProduct.loading = false;
                draft.selectedInventory.selectedInventoryProduct.error = undefined;
            })
    ),


    on(
        fromActions.SCAN_FAILURE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.selectedInventoryProduct.loading = false;
                draft.selectedInventory.selectedInventoryProduct.error = action.error.error;
            })
    ),
    on(
        fromActions.MASS_UPDATE_SUCCESS,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.massUpdateSuccessfull = true;
            })
    ),


    on(
        fromActions.UPDATE_PRODUCTS_STOCK_MANAGEMENT_SUCCESS,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.massUpdateSuccessfull = true;
            })
    ),
    on(
        fromActions.SET_MASS_UPDATE_TO_FALSE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.massUpdateSuccessfull = false;
            })
    ),
    on(
        fromActions.SET_UPLOAD_SUCCESS_TO_FALSE,
        (state, action) =>
            produce(state, (draft) => {
                draft.selectedInventory.products.importSuccess = false;
            })
    ),
);