import { Injectable } from "@angular/core";
import { ActionsSubject, Store } from "@ngrx/store";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import * as fromActions from "./invoices.actions";
import * as fromSelectors from "./invoices.selectors";

@Injectable()
export class InvoicesFacade {

    constructor(private _store: Store, private _actions$: ActionsSubject) { }

    // Select the selectors
    get invoices$() {
        return this._store.select(fromSelectors.selectAll);
    }

    get invoicesPagination$() {
        return this._store.select(fromSelectors.selectPagination);
    }

    get invoicesLoading$() {
        return this._store.select(fromSelectors.selectLoading);
    }

    get invoicesError$() {
        return this._store.select(fromSelectors.selectError);
    }

    get invoicesFilters$() {
        return this._store.select(fromSelectors.selectError);
    }
    get invoice$() {
        return this._store.select(fromSelectors.selectItem)
    }

    get invoiceLoading$() {
        return this._store.select(fromSelectors.selectItemLoading)
    }

    get invoiceError$() {
        return this._store.select(fromSelectors.selectItemError)
    }


    // Dispatch actions
    cleanState() {
        this._store.dispatch(fromActions.CLEAN_STATE());
    }

    cleanItem() {
        this._store.dispatch(fromActions.CLEAN_ITEM());
    }

    loadList(payload: ApplyFiltersModel) {
        this._store.dispatch(fromActions.LOAD_LIST(payload));
    }

    loadItem(id: number) {
        this._store.dispatch(fromActions.LOAD_ITEM(id));
    }

    generateInvoicePDF(id: number, print?: boolean) {
        this._store.dispatch(fromActions.GENERATE_PDF(id, print));
    }

    reverseInvoice(id: number, payload: any) {
        this._store.dispatch(fromActions.REVERSE_INVOICE(id, payload));
    }

}