import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CURRENCY } from 'src/app/shared/constants';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { InvoiceModel, InvoiceServiceModel } from '../../models/invoice.model';
import { InvoicesFacade } from '../../store/invoices.facade';


@Component({
    selector: 'app-reverse-invoice-modal',
    templateUrl: './reverse-invoice-modal.component.html',
    styleUrls: ['./reverse-invoice-modal.component.css']
})
export class ReverseInvoiceModalComponent implements OnInit, OnDestroy {
    public invoiceServicesList: InvoiceServiceModel[];
    public isLoading = this._invoiceFacade.invoiceLoading$;
    public selectedInvoice = this._invoiceFacade.invoice$;
    public reverseStatus: boolean;
    public currency = CURRENCY;
    public undistributedValue: number;
    public parseFloat = parseFloat;

    constructor(public dialogRef: MatDialogRef<ReverseInvoiceModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        private _data: InvoiceModel,
        private _sweetAlertService: SweetAlertService,
        private _invoiceFacade: InvoicesFacade) {
    }

    ngOnInit(): void {
        this._invoiceFacade.loadItem(this._data.id);
    }

    ngOnDestroy() {
        this._invoiceFacade.cleanItem();
    }

    reverseInvoice(): void {
        this._sweetAlertService.showSwal("warning-message-send-reverse", () => {
            this._invoiceFacade.reverseInvoice(this._data.id, this.invoiceServicesList);
            this.dialogRef.close();
        })
    }
}
