<div mat-dialog-actions
     class="mb-0">
    <div class="w-100 modal-footer border-0 p-0">
        <button type="button"
                data-cy="close-modal-btn"
                class="btn btn-secondary pull-right"
                (click)="close.emit()">
            {{ 'Close' | translate }}
        </button>
        <button type="button"
                class="btn btn-primary pull-right"
                [class.disabled-input]="disable"
                *ngIf="!view"
                data-cy="save-and-close-modal-btn"
                (click)="saveAndClose.emit()">
            {{ 'SaveAndClose' | translate }}
        </button>
        <button type="button"
                class="btn btn-primary pull-right"
                [class.disabled-input]="disable"
                *ngIf="!view"
                data-cy="save-modal-btn"
                (click)="save.emit()">
            {{ 'Save' | translate }}
        </button>
    </div>
</div>