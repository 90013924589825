import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'DashSepareFieldsPipe' })

export class DashSepareFieldsPipe implements PipeTransform {

    transform(row: any, field: string, cell: any) {
        if (field.indexOf(cell) === 0) {
            return row[cell] ? row[cell] : 0
        }
        return '- ' + (row[cell] ? row[cell] : 0);
    }
}