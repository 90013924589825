import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import { AccountsFacade } from 'src/app/modules/accounts/store/accounts.facade';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { isNullOrUndefined } from 'src/app/shared/utils/isNullOrUndefined.utils';
import { AppFacade } from 'src/app/store/app.facade';
import { AccountModel } from '../../models/account.model';
import { AccountsService } from '../../services/accounts.service';

@UntilDestroy()
@Component({
    selector: 'app-accounts-modal',
    templateUrl: './accounts-modal.component.html',
    styleUrls: ['./accounts-modal.component.css']
})
export class AccountsModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public isEdit = false;
    public selectedAccount$ = this._accountsFacade.account$;
    public loading$ = this._accountsFacade.accountLoading$;
    public loadingLoggedUser$ = this._appFacade.loggedUserLoading$;

    constructor(
        public dialogRef: MatDialogRef<AccountsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AccountModel,
        private _sweetAlertService: SweetAlertService,
        private _accountsFacade: AccountsFacade,
        private _appFacade: AppFacade,
        private _accountsService: AccountsService) {
        super(dialogRef, _accountsService);
        this.isEdit = !isNullOrUndefined(data?.id);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.isEdit && this._accountsFacade.loadItem(this.data.id);

        this.selectedAccount$.pipe(filter(user => !!user), take(1)).subscribe(user => {
            const updatedAccount = this.data.self_update ? { self_update: this.data.self_update, ...user } : user;
            this._accountsFacade.setItem(updatedAccount);
        });
    }

    ngOnDestroy(): void {
        this._accountsFacade.cleanItem();
    }

    removeProfilePic() {
        this._sweetAlertService.showSwal('warning-message', () => this._accountsFacade.removePicture(this.data.id), 'TheProfilePicure');
    }
}
