import { Component } from '@angular/core';

@Component({
    selector: 'app-qr-scan',
    templateUrl: './qr-scan.component.html',
    styleUrls: ['./qr-scan.component.css']
})
export class QrScanComponent {
    public currentBrand;
}
