import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import { ProductModel } from "../models/product.model";

export interface ProductState extends EntityState<ProductModel> {
    error: string | null;
    loading: boolean;
    pagination: Omit<PaginationModel, 'data'>;
    filters: ApplyFiltersModel;

    selectedProduct: {
        product: ProductModel | undefined;
        error: string | null;
        loading: boolean;
        saveAndClose: boolean;
        importFormat: any;
    }
}

export const adapter: EntityAdapter<ProductModel> = createEntityAdapter<ProductModel>();

export const productInitialState: ProductState = adapter.getInitialState({
    ids: [],
    entities: {},
    pagination: null,
    error: null,
    loading: false,
    filters: undefined,

    selectedProduct: {
        product: undefined,
        error: null,
        loading: false,
        saveAndClose: false,
        importFormat: null,
    }
});