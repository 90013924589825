import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import * as fromActions from "./stock-management.actions";
import * as fromSelectors from "./stock-management.selectors";

@Injectable()
export class StockManagementsFacade {
    constructor(private _store: Store) { }

    // Select the selectors
    get stockManagements$() {
        return this._store.select(fromSelectors.selectAll);
    }

    get stockManagementsPagination$() {
        return this._store.select(fromSelectors.selectPagination);
    }

    get stockManagementsLoading$() {
        return this._store.select(fromSelectors.selectLoading);
    }

    get stockManagementsError$() {
        return this._store.select(fromSelectors.selectError);
    }

    get stockManagementsFilters$() {
        return this._store.select(fromSelectors.selectFilters);
    }

    get stockManagement$() {
        return this._store.select(fromSelectors.selectItem);
    }

    get stockManagementLoading$() {
        return this._store.select(fromSelectors.selectItemLoading);
    }

    get stockManagementError$() {
        return this._store.select(fromSelectors.selectItemError);
    }
    get stockManagementSuccessResponse$() {
        return this._store.select(fromSelectors.selectItemSuccessResponse);
    }

    // Dispatch actions
    cleanState() {
        this._store.dispatch(fromActions.CLEAN_STATE());
    }

    cleanItem() {
        this._store.dispatch(fromActions.CLEAN_ITEM());
    }

    loadList(payload: ApplyFiltersModel) {
        this._store.dispatch(fromActions.LOAD_LIST(payload));
    }

    loadItem(id: number) {
        this._store.dispatch(fromActions.LOAD_ITEM(id));
    }

    setItem(payload: any) {
        this._store.dispatch(fromActions.SET_ITEM(payload));
    }

    createItem(payload: any) {
        this._store.dispatch(fromActions.CREATE(payload));
    }

    updateItem(payload: any) {
        this._store.dispatch(fromActions.UPDATE(payload));
    }

    deleteItem(id: number, filters: any) {
        this._store.dispatch(fromActions.DELETE(id, filters));
    }

    setSaveAndCloseToFalse() {
        this._store.dispatch(fromActions.SETCLOSETOFALSE());
    }

}