import { Component } from '@angular/core';
import { BuildDetailsService } from '../../services/build-details.service';

@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent {
    public test: Date = new Date();
    public currentBuildNumber: string;

    constructor(private _buildDetailsService: BuildDetailsService) {
        this.currentBuildNumber = this._buildDetailsService.buildDetails.buildNumber;
    }
}
