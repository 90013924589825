import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { interval, Observable, Subscription, timer } from 'rxjs';
import { map, skip } from 'rxjs/operators';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent {
  public secondsTillNow: number;
  public timer$ = timer(0, 1000).pipe(
    map(count => moment.utc((this.secondsTillNow + count) * 1000).format('HH:mm:ss'))
  );

  @Input() set startDate(value: string) {
    this.secondsTillNow = moment().unix() - moment(value).unix();
  }
}
