import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-up-to-date-',
    templateUrl: './up-to-date.component.html',
    styleUrls: ['./up-to-date.component.scss']
})
export class UpToDateComponent {

    constructor(public dialogRef: MatDialogRef<UpToDateComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any) {
    }

    reloadPage() {
        window.location.reload();
    }
}
