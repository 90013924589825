<section class="table-list-section child-dashboard-section">
    <div class="container-fluid px-5">
        <div class="row">
            <div class="col-12 {{!isMobile ? 'd-flex': ''}} justify-content-between">
                <span>{{ 'Inventories' | translate | uppercase }}</span>
                <div class="{{isMobile ? 'd-flex justify-content-end mt-1': ''}}">
                    <button mat-raised-button
                            class="btn btn-white text-dark {{isMobile ? 'btn-sm': ''}}"
                            (click)="addEntry()" data-cy="open-inventory-btn"
                            [appRestrictPermissions]="PermissionsEnum.INVENTORIES_ADD_AND_EDIT">
                        <i class="material-icons mr-1">add</i>
                        {{'Add' | translate }}</button>
                </div>
            </div>
            <div class="col-12 mt-4">
                <app-table-filters *ngIf="table"
                                   [filters]="filters"
                                   [tableId]="table.tableId"
                                   [queryParams]="filtersPayload.queryParams"
                                   (applyFilters)="applyFilters($event)">
                </app-table-filters>
            </div>
            <div class="col-12">
                <app-regular-table #table
                                [deletedItem]="'stock_management.deleted_at'"
                                [headerRow]="tableHeaders"
                                   [dataList]="list$ | async"
                                   [rowPossibleActions]="rowPossibleActions"
                                   [pagination]="pagination$ | async"
                                   [loading]="loading$ | async"
                                   [tableId]="'inventories-records-tbl'"
                                   (onPageChanged)="updatePage($event)"
                                   (actionType)="tableActionClicked($event)">
                </app-regular-table>
            </div>
        </div>
    </div>
</section>