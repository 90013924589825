<section class="table-list-section child-dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <span>{{ 'Invoices' | translate | uppercase }}</span>
                <!-- <button class="btn btn-white text-dark" (click)="export()">
                    <span class="material-icons">exit_to_app</span>
                    {{ "Export" | translate }}
                </button> -->
            </div>
            <div class="col-12 mt-3">
                <app-table-filters *ngIf="table"
                                   [filters]="filters"
                                   [tableId]="table.tableId"
                                   [queryParams]="filtersPayload.queryParams"
                                   (applyFilters)="applyFilters($event)"></app-table-filters>
            </div>
            <div class="col-12">
                <app-regular-table #table
                                   [headerRow]="tableHeaders"
                                   [dataList]="list$ | async"
                                   [rowPossibleActions]="rowPossibleActions"
                                   [pagination]="pagination$ | async"
                                   [tableId]="'invoices-records-tbl'"
                                   [loading]="loading$ | async"
                                   (onPageChanged)="updatePage($event)"
                                   (actionType)="tableActionClicked($event)">
                </app-regular-table>
            </div>
        </div>
    </div>
</section>