<form [formGroup]="form" appFormValidator>
    <div class="spacer-2"></div>
    <app-gradient-border-title>{{ "Invoicing" | translate }}</app-gradient-border-title>
    <div class="spacer-2"></div>        
    <div class="row align-items-end">
        <div class="col-md col-sm-12">
            <app-dropdown-with-search class="d-block" [label]="'InvoiceSerie'"
                [control]="form.controls.invoice_series_id"
                [itemsList]="invoiceSeriesList"
                [firstOptionMessage]="'SelectInvoicingSeries'"
                [cypressSelector]="'invoiceSeries'">
            </app-dropdown-with-search>
            <mat-error>
                <app-form-field-error [control]="form.controls.invoice_series_id"></app-form-field-error>
            </mat-error>
        </div>
        <button class="btn btn-outline" (click)="onSendToInvoice()" [class.orange-btn]="QRScan">
            <span class="material-icons">arrow_forward</span>
            {{ "SendToInvoice" | translate }}
        </button>
    </div>
</form>