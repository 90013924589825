import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AppFacade } from 'src/app/store/app.facade';
import { PermissionsEnum } from '../enums/Permissions.enum';

@Injectable({
    providedIn: 'root'
})

export class RestrictPermissionsGuard {
    public permission$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

    constructor(private _router: Router, private _appFacade: AppFacade) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        switch (state.url) {
            case '/':
                this._router.navigate(['/dashboard']);
                return;
            case '/dashboard':
                return this.matchPermissions(PermissionsEnum.SELF_PROFILE_READ);
            case '/dashboard/brand-profile':
                return this.matchPermissions(PermissionsEnum.BRAND_PROFILE_READ);
            case '/dashboard/accounts':
                return this.matchPermissions(PermissionsEnum.USERS_READ);
            case '/dashboard/roles':
                return this.matchPermissions(PermissionsEnum.ROLES_READ);
            case '/dashboard/brand-invoice-profile':
                return this.matchPermissions(PermissionsEnum.BRAND_INVOICE_PROFILE_READ);
            case '/dashboard/invoices':
                return this.matchPermissions(PermissionsEnum.VIEW_INVOICES_LIST);
            case '/dashboard/settings':
                return this.matchPermissions(PermissionsEnum.SETTINGS_READ);
            case '/dashboard/services':
                return this.matchPermissions(PermissionsEnum.VIEW_SERVICES_LIST);
            case '/dashboard/stock-management':
                return this.matchPermissions(PermissionsEnum.STOCK_MANAGEMENTS_READ);
            case '/dashboard/inventories':
                return this.matchPermissions(PermissionsEnum.INVENTORIES_READ);
            case '/dashboard/products':
                return this.matchPermissions(PermissionsEnum.PRODUCTS_READ);
            default:
                this._router.navigate(['/dashboard/not-allowed']);
                return of(false);
        }
    }

    matchPermissions(routePermission): Observable<boolean> {
        this._appFacade.loggedUser$.pipe(filter(user => !!user), take(1)).subscribe(user => {
            const hasPermission = !!user.permissions.find(permission => routePermission === permission);
            if (!hasPermission) {
                this._router.navigate(['/dashboard/not-allowed']);
            }
            this.permission$.next(hasPermission);
        });
        return this.permission$.asObservable();
    }
}
