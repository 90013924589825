import { Component, Input, OnInit } from '@angular/core';
import { InvoiceModel } from '../../../models/invoice.model';
import { CURRENCY } from 'src/app/shared/constants';
import { calculateTotal } from 'src/app/shared/utils/calculations.utils';

@Component({
    selector: 'app-invoice-general-info-tab',
    templateUrl: './invoice-general-info-tab.component.html',
    styleUrls: ['./invoice-general-info-tab.component.css']
})
export class InvoiceGeneralInfoTabComponent implements OnInit {

    public currency = CURRENCY;
    public totalValue: string;

    @Input() selectedInvoice: InvoiceModel;

    constructor() { }

    ngOnInit(): void {
        this.totalValue = calculateTotal(this.selectedInvoice.invoice_services, null, 'service_value_including_vat');
    }
}
