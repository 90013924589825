import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApplyFiltersModel } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { parseFilters } from 'src/app/shared/utils/filters.utils';
import { AccountModel } from '../models/account.model';

@Injectable({
    providedIn: 'root'
})

export class AccountsService extends BaseService {
    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService) {
        super(_formErrorService);
    }

    getLoggedUser$(): Observable<AccountModel> {
        return this.apiRequestService.submitGetRequest(apiEndpoints.userProfile).pipe(map(user => ({ ...user.data })));
    }

    updateLoggedUser$(user: AccountModel): Observable<AccountModel> {
        return this.apiRequestService.submitPutRequest(apiEndpoints.userProfile, user).pipe(map(user => ({ ...user.data })));
    }

    getAccounts$(payload: ApplyFiltersModel): Observable<PaginationModel> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.accounts}${parseFilters(payload)}`);
    }

    getAccount$(id: number): Observable<AccountModel> {
        return this.apiRequestService.submitGetRequest(`${apiEndpoints.accounts}/${id}`).pipe(map(res => ({ ...res.data })));
    };

    createAccount$(account: AccountModel): Observable<AccountModel> {
        return this.apiRequestService.submitPostRequest(apiEndpoints.accounts, account).pipe(map(res => ({ ...res.data })));
    }

    updateAccount$(account: AccountModel): Observable<AccountModel> {
        return this.apiRequestService.submitPutRequest(apiEndpoints.accounts + '/' + account.id, account).pipe(map(res => ({ ...res.data })));
    }

    updateAccountProfilePicture$(accountId: number, img: File): Observable<AccountModel> {
        const formData: FormData = new FormData();
        typeof img !== 'string' && !!img && formData.append('picture', img, img.name);
        return this.apiRequestService.submitPostRequest(apiEndpoints.userProfilePicture + `/${accountId}`, formData).pipe(map(res => ({ ...res.data })));;
    }

    removeAccountProfilePicture$(id: number): Observable<null> {
        return this.apiRequestService.submitDeleteRequest(apiEndpoints.userProfilePicture + '/' + id);
    }
}
