import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TablesModule } from '../../shared/components/tables/tables.module';
import { StockManagementsComponent } from './stock-managements.component';
import { StockManagementEffects } from './store/stock-management.effects';
import { StockManagementsFacade } from './store/stock-management.facade';
import { StockManagementModalComponent } from './components/stock-management-modal/stock-management-modal.component';
import { stockManagementsReducer } from './store/stock-management.reducers';

@NgModule({
    imports: [
        SharedComponentsModule,
        DirectivesModule,
        TablesModule,
        CommonModule,
        PipesModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        StoreModule.forFeature('stock-management', stockManagementsReducer),
        EffectsModule.forFeature([StockManagementEffects])
    ],
    declarations: [
        StockManagementsComponent,
        StockManagementModalComponent,
    ],
    exports: [
        StockManagementsComponent,
        StockManagementModalComponent,
    ],
    providers: [StockManagementsFacade]
})

export class StockManagementsModule { }