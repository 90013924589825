
export function getErrors(error): string[] {
    const errorsList = [];
    if(error?.errors) {
        for(const errorMessage in error?.errors) {
            errorsList.push(error?.errors[errorMessage]);   
        }
        return errorsList;
    }

    errorsList.push(error?.message);
    return errorsList;
}