export class InventoryModel {
    id?: number;
    start_date: string;
    end_date: string | null;
}
export class ProductsUploadFormat {
    id: number;
    file: any;
    overwrite_stock_management: boolean;
}

export class InventoryProductModel {
    id?: number;
    filters?: string;
    scanned_at?: string;
    inventory_product?: {
        id: number;
        inventory_id: number;
    }
    status?: string;
}



