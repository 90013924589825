import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromState from "./roles.state";

export const selectRolesState = createFeatureSelector<fromState.RolesState>('roles');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = fromState.adapter.getSelectors(selectRolesState);

// List selectors
export const selectLoading = createSelector(
    selectRolesState,
    (state: fromState.RolesState) => state.loading
);

export const selectError = createSelector(
    selectRolesState,
    (state: fromState.RolesState) => state.error
);

// Item selectors

export const selectItem = createSelector(
    selectRolesState,
    (state: fromState.RolesState) => state.selectedRole.item
);

export const selectItemLoading = createSelector(
    selectRolesState,
    (state: fromState.RolesState) => state.selectedRole.loading
);

export const selectItemError = createSelector(
    selectRolesState,
    (state: fromState.RolesState) => state.selectedRole.error
);