import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SETTINGS } from 'src/app/shared/constants';
import { BrandInvoiceProfileModel, InvoiceSerieModel } from '../../../models/brand-invoice-profile.model';
import { BrandInvoiceProfileService } from '../../../services/brand-invoice-profile.service';

@Component({
    selector: 'app-invoice-series-tab',
    templateUrl: './invoice-series-tab.component.html',
    styleUrls: ['./invoice-series-tab.component.css']
})
export class InvoiceSeriesTabComponent implements OnInit {

    public form: FormGroup;
    public statusActive = [
        {
            name: 'Yes',
            id: 1,
            value: true
        },
        {
            name: 'No',
            id: 2,
            value: false
        }
    ];

    @Input() data: BrandInvoiceProfileModel;
    @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

    constructor(private _formBuilder: FormBuilder, private _brandInvoiceProfileService: BrandInvoiceProfileService) { }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            series: '',
            brand_invoice_profile_id: this.data.id,
            start_no: 1,
            active: 0,
            currency: '',
            vat: SETTINGS.DEFAULT_VAT_VALUE || 0
        });
    }

    onNoClick(): void {
        this.closeModal.emit()
    }

    save() {
        this.addInvoiceSerie();
    }

    addInvoiceSerie() {
        const currentStatus = this.statusActive.find(status => status.id === this.form.controls.active.value);

        const payload: InvoiceSerieModel = {
            ...this.form.value,
            active: currentStatus?.value,
        };

        this._brandInvoiceProfileService.createInvoiceSerie(payload, this.data.id);
    }

    changeStatusActive(statusId: number) {
        this.form.controls.active.setValue(statusId);
    }
}
