import { ORSEnum } from '../enums/OrderReportsStatuses.enum';

import { Router } from '@angular/router';
import { Pipe, PipeTransform, ComponentFactoryResolver, ViewContainerRef, EmbeddedViewRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TooltipComponent } from '../components/tooltip/tooltip.component';

@Pipe({ name: 'ModelWithSubPropertiesPipe' })

export class ModelWithSubPropertiesPipe implements PipeTransform {

    constructor(
        private _translateService: TranslateService,
        private _router: Router,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _viewContainerRef: ViewContainerRef
    ) { }

    transform(row: object, field: any, cell?: any, span?: any) {
        // Display hour on trial stages
        if (field === 'time' && !row[field]) {
            this.insertContent(cell, 'AllDay');
            return;
        }
        // Add NotEmitted badge for invoice series 
        if (field === 'invoice.invoice_series') {
            if (!row['invoice']?.['invoice_series']) {
                this.insertContent(cell, 'NotEmitted');
                cell.classList.add('badge', 'badge-danger')
                cell.classList.remove('invoice\.invoice_series');
                return;
            }

            cell.innerHTML = row['invoice']?.['invoice_series'];
            cell.classList.remove('badge', 'badge-danger')
            cell.classList.add('invoice\.invoice_series');
        }

        // Add icons for order report statuses
        if (this._router.url === '/dashboard/orders-report' && cell.name === 'Status') {
            const newField = field.includes('.') && this.splitFields(row, field);

            field === 'status' && this.createIconComponent(row, field);
            (field === 'order_payment_summary.to_pay' && cell.name === 'Status') && this.createIconComponent(row, field, newField);
            field === 'order_payment_summary.has_invoice' && this.createIconComponent(row, field, newField);
        }

        // Transform default invoicing boolean into Yes or No in additional prices
        if (this._router.url === '/dashboard/additional-prices' && field === 'default_invoicing') {
            parseInt(row[field]) ?
                cell.innerHTML = this._translateService.instant('Yes') :
                cell.innerHTML = this._translateService.instant('No');
        }

        // Simple field
        if (field.indexOf('.') < 0) {
            return field === 'time' ? row[field].slice(0, 5) : row[field];
        }

        // Field containing an object
        if (field.includes('.') && cell.name !== 'Status') {
            return this.splitFields(row, field);
        }
    }

    splitFields(row: object, field: string) {
        let fields = field.split('.');
        let data = row;
        for (let i = 0; i < fields.length; i++) {
            const list = fields[i].includes('[') && fields[i].split('[');
            const listPosition = list && list[1].substring(0, 1);
            data = listPosition ? data[list[listPosition]][i] : data[fields[i]];
            if (!data) { return; }
        }
        return `${data} `;
    }

    insertContent(cell, content) {
        cell.innerHTML = "";
        cell.insertAdjacentHTML('beforeend', `<div>${this._translateService.instant(content)}</div>`);
    }


    createIconComponent(row: any, field: string, newField?: string) {
        this._viewContainerRef.element.nativeElement.remove();
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(TooltipComponent);
        const component = this._viewContainerRef.createComponent(componentFactory);
        const htmlElement = (component.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        htmlElement.parentElement.classList.add('text-right');

        component.instance.position = 'above';

        switch (field) {
            case 'status':
                row[field] === 'complete' ?
                    this.addDataToTooltip(component, ORSEnum.DONE_ICON, ORSEnum.FINISHED, ORSEnum.SUCCESS) : this.addDataToTooltip(component, ORSEnum.DONE_ICON, ORSEnum.WORKING, ORSEnum.DANGER);
                break;
            case 'order_payment_summary.to_pay':
                !parseInt(newField) ?
                    this.addDataToTooltip(component, ORSEnum.PAID_ICON, ORSEnum.PAID, ORSEnum.SUCCESS) : this.addDataToTooltip(component, ORSEnum.PAID_ICON, ORSEnum.UNPAID, ORSEnum.DANGER);
                break;
            case 'order_payment_summary.has_invoice':
                !!newField ?
                    this.addDataToTooltip(component, ORSEnum.INVOICE_ICON, ORSEnum.WITH_INVOICE, ORSEnum.SUCCESS) : this.addDataToTooltip(component, ORSEnum.INVOICE_ICON, ORSEnum.WITHOUT_INVOICE, ORSEnum.DANGER);
                break;
            default:
                break;
        }
    }

    addDataToTooltip(component: any, icon?: string, message?: string, iconColor?: string) {
        icon && (component.instance.icon = icon);
        message && (component.instance.message = message);
        iconColor && (component.instance.iconColor = iconColor);
    }
}
