import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { TablesModule } from 'src/app/shared/components/tables/tables.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { QrScanComponent } from './qr-scan.component';
import { ProductQrScanComponent } from './product-qr-scan/product-qr-scan.component';
import { QRScanRoutes } from './qr-scan.routing';
import { httpTranslateLoader } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { NavbarModule } from 'src/app/shared/components/navbar/navbar.module';
import { SidebarModule } from 'src/app/shared/components/sidebar/sidebar.module';
import { QrInventoryProductsComponent } from './qr-inventory-products/qr-inventory-products.component';


@NgModule({
    imports: [
        SharedComponentsModule,
        RouterModule.forChild(QRScanRoutes),
        DirectivesModule,
        TablesModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        RouterModule,
        PipesModule,
        NavbarModule,
        SidebarModule
    ],

    declarations: [
        QrScanComponent,
        ProductQrScanComponent,
        QrInventoryProductsComponent,
    ],
})
export class QRScanModule { }
