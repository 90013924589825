import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { SettingModel } from "src/app/modules/settings/models/settings.model";
import { SettingsService } from "src/app/modules/settings/services/settings.service";
import { setSettings } from "src/app/shared/constants";
import * as fromActions from "./settings.actions";

@Injectable()
export class SettingsEffects {
    constructor(private _actions$: Actions, private _settingsService: SettingsService) {

    }
    public loadList$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_LIST),
        switchMap(() =>
            this._settingsService.getSettings$().pipe(
                map((res: SettingModel[]) => {
                    setSettings(res);
                    return fromActions.LOAD_LIST_SUCCESS(res)
                }),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_LIST_FAILURE(error)))
            )
        )
    ));

    public loadItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_ITEM),
        switchMap(action =>
            this._settingsService.getSetting$(action.id).pipe(
                map((res: SettingModel) => fromActions.LOAD_ITEM_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_ITEM_FAILURE(error)))
            )
        )
    ));

    public updateItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.UPDATE),
        switchMap(action =>
            this._settingsService.updateSetting$(action.payload).pipe(
                map((res: SettingModel) => {
                    this._settingsService.onSaveSuccess$.next(true);
                    return fromActions.UPDATE_SUCCESS(res);
                }),
                catchError((error: HttpErrorResponse) => {
                    this._settingsService.reportError('Error on update setting', error);
                    return of(fromActions.UPDATE_FAILURE(error));
                })
            )
        )
    ));
}