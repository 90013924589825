import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { InvoiceModel } from '../../../models/invoice.model';
import { fileFormatTypes } from '../constants/file-format-types.constant';

@Injectable({
    providedIn: 'root'
})
export class ExportFormatsService extends BaseService {

    invoicesToExport$: BehaviorSubject<InvoiceModel[]> = new BehaviorSubject(undefined);

    constructor(private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService) {
        super(_formErrorService);
    }

    exportInvoices(filters: string, queryParams?: string, exportFormat?: string, fileName?: string) {
        this.isLoading$.next(true);
        const formatType = fileFormatTypes[exportFormat];
        this.apiRequestService.submitGetRequest(apiEndpoints.invoices + '/export' + (filters ? `${filters}` : '') + (queryParams ? queryParams : ''), exportFormat && { responseType: formatType.responseType }).subscribe({
            next: apiResponse => {
                !queryParams && this.invoicesToExport$.next(apiResponse.data);
                queryParams && this.downloadFile(apiResponse, formatType, fileName);
                this.isLoading$.next(false);
            },
            error: error => this.reportError('Error on export invoices', error)
        })
    }


    downloadFile(file: any, formatType: any, fileName: string) {
        var parsedFile = formatType.responseType === 'json' ? JSON.stringify(file) : file;
        var a = document.createElement('a');
        a.href = `data:${formatType.contentType};charset=UTF-8,` + encodeURIComponent(parsedFile);
        a.download = `${fileName}.${formatType.extension}`;
        a.click();
    }
}
