import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertSecondsToDhms' })

export class ConvertSecondsToDhmsPipe implements PipeTransform {

  transform(totalSeconds: number) {
    const hours = Math.floor(totalSeconds / 3600);
    let result = `${hours.toString().padStart(1, '0')}h : `;

    const minutes = Math.floor((totalSeconds % 3600) / 60);
    result += `${minutes.toString().padStart(2, '0')}m : `;

    const seconds = totalSeconds % 60;
    result += `${seconds.toString().padStart(2, '0')}s`;

    return result;
  }
}
