import { HttpErrorResponse } from "@angular/common/http";
import { createAction } from "@ngrx/store";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import { StockManagementsModel } from "../models/stock-management.model";

export const CLEAN_STATE = createAction('[Stock Management] Clean state');
export const CLEAN_ITEM = createAction('[Stock Management] Clean item');

export const LOAD_LIST = createAction('[Stock Management] Load list', (payload: ApplyFiltersModel) => ({ payload }));
export const LOAD_LIST_SUCCESS = createAction('[Stock Management] Load list success', (payload: PaginationModel) => ({ payload }));
export const LOAD_LIST_FAILURE = createAction('[Stock Management] Load list fail', (error: HttpErrorResponse) => ({ error }));

export const SET_ITEM = createAction('[Stock Management] Set item', (payload: StockManagementsModel) => ({ payload }));
export const LOAD_ITEM = createAction('[Stock Management] Load item', (id: number) => ({ id }));
export const LOAD_ITEM_SUCCESS = createAction('[Stock Management] Load item success', (payload: StockManagementsModel) => ({ payload }));
export const LOAD_ITEM_FAILURE = createAction('[Stock Management] Load item fail', (error: HttpErrorResponse) => ({ error }));

export const CREATE = createAction('[Stock Management] Create item', (payload: StockManagementsModel) => ({ payload }));
export const CREATE_SUCCESS = createAction('[Stock Management] Create item success', (payload: StockManagementsModel) => ({ payload }));
export const CREATE_FAILURE = createAction('[Stock Management] Create item fail', (error: HttpErrorResponse) => ({ error }));

export const UPDATE = createAction('[Stock Management] Update item', (payload: StockManagementsModel) => ({ payload }));
export const UPDATE_SUCCESS = createAction('[Stock Management] Update item success', (payload: StockManagementsModel) => ({ payload }));
export const UPDATE_FAILURE = createAction('[Stock Management] Update item fail', (error: HttpErrorResponse) => ({ error }));

export const DELETE = createAction('[Stock Management] Delete item', (id: number, filters?: ApplyFiltersModel) => ({ id, filters }));
export const DELETE_SUCCESS = createAction('[Stock Management] Delete item success', (id: number, filters?: ApplyFiltersModel) => ({ id, filters }));
export const DELETE_FAILURE = createAction('[Stock Management] Delete item fail', (error: HttpErrorResponse) => ({ error }));

export const SETCLOSETOFALSE = createAction('[Stock Management] Delete item fail', () => null);
