import { Subscription, filter } from 'rxjs';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrandProfileModel } from '../../models/brand-profile.model';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { BrandProfileService } from '../../services/brand-profile.service';

@Component({
    selector: 'app-brand-profile-modal',
    templateUrl: './brand-profile-modal.component.html',
    styleUrls: ['./brand-profile-modal.component.css']
})
export class BrandProfileModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[];
    public currentBrandProfile: BrandProfileModel;

    constructor(
        public dialogRef: MatDialogRef<BrandProfileModalComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: BrandProfileModel,
        protected _brandProfileService: BrandProfileService) {
        super(dialogRef, _brandProfileService);
        this.currentBrandProfile = _data;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._subscriptions = [
            this._brandProfileService.currentBrandProfile$
                .pipe(filter(currentBrand => !!currentBrand))
                .subscribe(currentBrand => this.currentBrandProfile = currentBrand)
        ];
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    closeModal() {
        this.dialogRef.close();
    }

    saveAndClose(event: boolean) {
        this._saveAndClose = event;
    }
}
