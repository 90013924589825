import { Component, Input, OnInit } from '@angular/core';
import { GoToLinkModel } from 'src/app/shared/models/go-to-link.model';
import { ReportService } from 'src/app/shared/services/report.service';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  public isLoading: boolean;


  @Input() title: string;
  @Input() goToLink: GoToLinkModel;

  constructor(private _reportService: ReportService) { }

  ngOnInit(): void {
    this._reportService.isLoading$.subscribe(loading => this.isLoading = loading);
  }
}
