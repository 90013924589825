
import { Injectable } from '@angular/core';
import { Observable, map, of, switchMap } from 'rxjs';
import { ApiRequestService, apiEndpoints } from 'src/app/shared/services/api-request.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { RoleModel } from '../models/role.model';

@Injectable({
    providedIn: 'root'
})

export class RolesService extends BaseService {
    // reportError$: BehaviorSubject<HttpErrorResponse> = new BehaviorSubject(undefined);

    constructor(
        private _apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService) {
        super(_formErrorService);
    }

    getRoles$(): Observable<RoleModel[]> {
        return this._apiRequestService.submitGetRequest(apiEndpoints.roles).pipe(map(res => ([...res.data])));
    }

    getRole$(id: number): Observable<RoleModel> {
        return this._apiRequestService.submitGetRequest(`${apiEndpoints.roles}/${id}`).pipe(map(res => ({ ...res.data })));
    }

    createRole$(role: Partial<RoleModel>): Observable<RoleModel> {
        return this._apiRequestService.submitPostRequest(apiEndpoints.roles, role).pipe(map(res => ({ ...res.data })));
    }

    updateRole$(role: RoleModel): Observable<RoleModel> {
        return this._apiRequestService.submitPutRequest(`${apiEndpoints.roles}/${role.id}`, role).pipe(map(res => ({ ...res.data })));
    }

    deleteRole$(id: number): Observable<number> {
        return this._apiRequestService.submitDeleteRequest(`${apiEndpoints.roles}/${id}`).pipe(switchMap(() => of(id)));
    };
}

