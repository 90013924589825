<div class="logo d-flex align-items-center">
    <a class="simple-text logo-mini">
        <div class="logo-img">
            <img src="{{ currentBrand?.logo }}"
                 class="img"
                 *ngIf="currentBrand?.logo; else fallbackCover">
            <ng-template #fallbackCover>
                <img src="../../assets/img/company_logo.png"
                     alt="img">
            </ng-template>
        </div>
    </a>
    <a href="javascript:void(0)"
       class="simple-text logo-normal">INVENTORY APP </a>
</div>

<div class="sidebar-wrapper" [class.pt-3]="isScanView">
    <div *ngIf="!isScanView; else scanView">

    <div class="user" *ngIf="loggedUser$ | async as user">
        <div class="photo">
            <img src="{{ user?.profile_pic }}"
                 class="img"
                 *ngIf="user?.profile_pic; else fallbackLogo">
            <ng-template #fallbackLogo>
                <img src="../../assets/img/default-avatar.png"
                     alt="img">
            </ng-template>
        </div>

        <div class="user-info">
            <a class="collapsed">
                <span class="d-inline">
                    {{ user?.first_name }} {{ user?.last_name }}
                </span>
                <span class="material-icons edit-user-profile-icon" *ngIf="!isMobileMenu()" [appRestrictPermissions]="PermissionsEnum.SELF_PROFILE_UPDATE" (click)="editUser(user)">edit</span>
            </a>
        </div>
    </div>

    <ul class="nav">
        <li #parentMenuItem
            routerLinkActive="active"
            *ngFor="let menuitem of menuItems"
            class="nav-item d-none"
            (click)="toggleActive($event)"
            [appRestrictPermissions]="menuitem.permission"
            [routerLinkActiveOptions]="{ exact: true }">
            <!--If is a single link-->
            <a [routerLink]="[menuitem.path]"
               [queryParams]="menuitem.queryParams"
               *ngIf="menuitem.type === 'link'"
               class="nav-link" [attr.data-cy]="menuitem.data_cy">
                <i class="material-icons">{{menuitem.icontype}}</i>
                <p>{{menuitem.title | translate}}</p>
            </a>
            <!--If it have a submenu-->
            <a data-toggle="collapse"
               href="#{{menuitem.collapse}}"
               *ngIf="menuitem.type === 'sub'"
               (click)="updatePS()"
               class="nav-link">
                <i class="material-icons">{{menuitem.icontype}}</i>
                <p>{{menuitem.title | translate}}<b class="caret"></b></p>
            </a>

            <!--Display the submenu items-->
            <div id="{{menuitem.collapse}}"
                 class="collapse primary-border"
                 *ngIf="menuitem.type === 'sub'">
                <ul class="nav">
                    <li routerLinkActive="active"
                        *ngFor="let childitem of menuitem.children"
                        class="nav-item"
                        [appRestrictPermissions]="childitem.permission">
                        <a [routerLink]="[menuitem.path, childitem.path]"
                           class="nav-link child-link">
                            <span class="sidebar-mini ml-2"><i class="material-icons mr-3">{{childitem.ab}}</i></span>
                            <span class="sidebar-normal">{{childitem.title | translate}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <li class="nav-item"
            *ngIf="isMobileMenu()">
            <a class="nav-link"
               (click)="logout()">
                <i class="material-icons mr-3">power_settings_new</i>
                <p>{{ 'Logout' | translate}}</p>
            </a>
        </li>
    </ul>

    <div *ngIf="isMobileMenu()"
         class="mt-4">
        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="nav-item dropdown">
                <a class="nav-link"
                   href="javascript:void(0)"
                   id="brand-sidebar-dropdown"
                   data-toggle="dropdown"
                   aria-haspopup="true"
                   aria-expanded="false">
                    <i class="material-icons">menu</i>
                    <p>
                        <span class="d-lg-none d-md-block">{{ "NavbarMenu" | translate }}</span>
                    </p>
                </a>
                <div class="dropdown-menu dropdown-menu-right"
                     aria-labelledby="brand-sidebar-dropdown">
                    <div class="dropdown-menu-section">
                        <a class="dropdown-item"
                           href="/#/dashboard/settings"
                           [appRestrictPermissions]="PermissionsEnum.SETTINGS_READ"><i class="material-icons mr-3">settings</i> {{ 'Settings' | translate }}</a>
                        <a class="dropdown-item"
                           href="/#/dashboard/accounts"
                           [appRestrictPermissions]="PermissionsEnum.USERS_READ"><i class="material-icons mr-3">group</i> {{ 'Accounts' | translate }}</a>
                        <a class="dropdown-item"
                           href="/#/dashboard/roles"
                           [appRestrictPermissions]="PermissionsEnum.ROLES_READ"><i class="material-icons mr-3">task</i> {{ 'Roles' | translate }}</a>
                    </div>
                    <div class="dropdown-menu-section">
                        <a class="dropdown-item"
                           href="/#/dashboard/brand-invoice-profile"
                           [appRestrictPermissions]="PermissionsEnum.BRAND_INVOICE_PROFILE_READ"><i class="material-icons mr-3">note</i>
                            {{ 'BrandInvoiceProfile' | translate }}
                        </a>
                        <a class="dropdown-item"
                            href="/#/dashboard/settings"
                            [appRestrictPermissions]="PermissionsEnum.SETTINGS_READ"><i class="material-icons mr-3">settings</i> {{ 'Settings' | translate }}</a>
                        <a class="dropdown-item"
                            href="/#/dashboard/accounts"
                            [appRestrictPermissions]="PermissionsEnum.USERS_READ"><i class="material-icons mr-3">group</i> {{ 'Accounts' | translate }}</a>
                        <a class="dropdown-item">
                            <div class="">
                                <div>
                                    <i class="material-icons">language</i>
                                    <p>{{ "Language" | translate}}</p>
                                </div>
                                <div id="lang"
                                     class="primary-border">
                                    <ul class="nav">
                                        <li routerLinkActive="active"
                                            *ngFor="let language of translateService.getLangs()"
                                            class="nav-item">
                                            <a href="javascript:void(0)"
                                               class="nav-link child-link"
                                               (click)="langService.setLang(language)">
                                                <span class="sidebar-normal">{{ language | translate | uppercase }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

</div>
<ng-template #scanView>
        <ul class="nav d-flex flex-column mt-0">
            <li class="nav-item" routerLinkActive="active" *ngIf="(lastScannedProductId$ | async) as lastScannedProductId" (click)="toggleActive($event)">
                <a href="/#/qr-scan/products/{{lastScannedProductId}}" class="nav-link">
                    <i class="material-icons mr-3">qr_code_scanner</i>
                    <p>{{ 'LastScannedProduct' | translate}}</p>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active" (click)="toggleActive($event)">
                <a href="/#/qr-scan/inventory-products" class="nav-link">
                    <i class="material-icons mr-3">view_list</i>
                    <p>{{ 'InventoryProducts' | translate}}</p>
                </a>
            </li>
            <li #parentMenuItem routerLinkActive="active" *ngFor="let menuitem of scanMenuItems" class="nav-item d-none"  >
                <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()" class="nav-link">
                    <i class="material-icons">{{menuitem.icontype}}</i>
                    <p>{{menuitem.title | translate}}<b class="caret"></b></p>
                </a>
                <div id="{{menuitem.collapse}}" class="collapse pl-2 ml-4" *ngIf="menuitem.type === 'sub'">
                    <ul class="nav mt-0">
                        <li *ngFor="let childitem of menuitem.children" class="nav-item" >
                            <a class="nav-link child-link p-0 mt-0" (click)="langService.setLang(childitem.value)">
                                <span class="sidebar-normal pl-3 py-2 " [class.submenu-active]='childitem?.value === lang'>{{childitem.title | translate}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="nav-item mt-auto">
                <a class="nav-link" (click)="logout()">
                    <i class="material-icons mr-3">power_settings_new</i>
                    <p>{{ 'Logout' | translate}}</p>
                </a>
            </li>
        </ul>
</ng-template>