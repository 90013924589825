<div class="wrapper">
    <div class="sidebar" data-color="accent" data-background-color="black" data-image="./assets/img/sidebar1.jpg">
        <app-sidebar-cmp></app-sidebar-cmp>
        <div class="sidebar-background" style="background-image: url(assets/img/sidebar1.jpg)"></div>
    </div>
    <div class="main-panel d-flex flex-column" [class.h-100]="!isMobile">
        <app-navbar-cmp class="d-flex"></app-navbar-cmp>
        <router-outlet></router-outlet>
        <div class="mt-auto">
            <app-footer-cmp></app-footer-cmp>
        </div>
    </div>
</div>