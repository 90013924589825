import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { AccountModel } from "src/app/modules/accounts/models/account.model";
import { AccountsService } from "src/app/modules/accounts/services/accounts.service";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import * as fromActions from "./accounts.actions";

@Injectable()
export class AccountsEffects {

    constructor(private _actions$: Actions, private _accountsService: AccountsService) { }

    public loadList$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_LIST),
        switchMap(action =>
            this._accountsService.getAccounts$(action.payload).pipe(
                map((res: PaginationModel) => fromActions.LOAD_LIST_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_LIST_FAILURE(error)))
            )
        )
    ));

    public loadItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_ITEM),
        switchMap(action =>
            this._accountsService.getAccount$(action.id).pipe(
                map((res: AccountModel) => fromActions.LOAD_ITEM_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_ITEM_FAILURE(error)))
            )
        )
    ));

    public createItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.CREATE),
        switchMap(action =>
            this._accountsService.createAccount$(action.payload).pipe(
                map((res) => fromActions.CREATE_SUCCESS(res)),
                tap(() => this._accountsService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._accountsService.reportError('Error on create account', error);
                    return of(fromActions.CREATE_FAILURE(error));
                })
            )
        )
    ));

    public updateItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.UPDATE),
        switchMap(action =>
            this._accountsService.updateAccount$(action.payload).pipe(
                map((res) => fromActions.UPDATE_SUCCESS(res)),
                tap(() => this._accountsService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._accountsService.reportError('Error on update account', error);
                    return of(fromActions.UPDATE_FAILURE(error));
                })
            )
        )
    ));

    public updatePicture$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.UPDATE_PICTURE),
        switchMap(action =>
            this._accountsService.updateAccountProfilePicture$(action.id, action.img).pipe(
                map((res) => fromActions.UPDATE_PICTURE_SUCCESS(res)),
                tap(() => this._accountsService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._accountsService.reportError('Error on update account profile picture', error);
                    return of(fromActions.UPDATE_PICTURE_FAILURE(error));
                })
            )
        )
    ));

    public removePicture$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.REMOVE_PICTURE),
        switchMap(action =>
            this._accountsService.removeAccountProfilePicture$(action.id).pipe(
                map((res) => fromActions.REMOVE_PICTURE_SUCCESS(res)),
                tap(() => this._accountsService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._accountsService.reportError('Error on remove account profile picture', error);
                    return of(fromActions.REMOVE_PICTURE_FAILURE(error));
                })
            )
        )
    ));
}