import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import * as fromActions from "./inventory.actions";
import * as fromSelectors from "./inventory.selectors";
import { InventoryProductModel, ProductsUploadFormat } from "../models/inventory.model";

@Injectable()
export class InventoryFacade {
    constructor(private _store: Store) { }

    // Select the selectors
    get inventories$() {
        return this._store.select(fromSelectors.selectAll);
    }

    get inventoriesPagination$() {
        return this._store.select(fromSelectors.selectPagination);
    }

    get inventoriesLoading$() {
        return this._store.select(fromSelectors.selectLoading);
    }

    get inventoriesError$() {
        return this._store.select(fromSelectors.selectError);
    }

    get inventoriesFilters$() {
        return this._store.select(fromSelectors.selectFilters);
    }

    get inventory$() {
        return this._store.select(fromSelectors.selectItem);
    }

    get inventoryLoading$() {
        return this._store.select(fromSelectors.selectItemLoading);
    }

    get inventoryError$() {
        return this._store.select(fromSelectors.selectItemError);
    }
    get inventorySuccessResponse$() {
        return this._store.select(fromSelectors.selectItemSuccessResponse);
    }

    get inventoryProducts$() {
        return this._store.select(fromSelectors.selectInventoryProducts);
    }

    get inventoryProductsLoading$() {
        return this._store.select(fromSelectors.selectInventoryProductsLoading);
    }

    get inventoryProductsError$() {
        return this._store.select(fromSelectors.selectInventoryProductsError);
    }

    get inventoryProduct$() {
        return this._store.select(fromSelectors.selectInventoryProduct);
    }

    get inventoryProductLoading$() {
        return this._store.select(fromSelectors.selectInventoryProductLoading);
    }

    get inventoryProductError$() {
        return this._store.select(fromSelectors.selectInventoryProductError);
    }
    get massUpdateSuccessfull$() {
        return this._store.select(fromSelectors.massUpdateSuccessfull);
    }
    get uploadFileSuccessfull$() {
        return this._store.select(fromSelectors.importProductsSuccess);
    }
    get importedProducts$() {
        return this._store.select(fromSelectors.importedProducts);
    }

    // Dispatch actions
    cleanState() {
        this._store.dispatch(fromActions.CLEAN_STATE());
    }

    cleanItem() {
        this._store.dispatch(fromActions.CLEAN_ITEM());
    }

    loadList(payload: ApplyFiltersModel) {
        this._store.dispatch(fromActions.LOAD_LIST(payload));
    }

    loadItem(id: number) {
        this._store.dispatch(fromActions.LOAD_ITEM(id));
    }

    setItem(payload: any) {
        this._store.dispatch(fromActions.SET_ITEM(payload));
    }

    createItem(payload: any) {
        this._store.dispatch(fromActions.CREATE(payload));
    }

    updateItem(payload: any) {
        this._store.dispatch(fromActions.UPDATE(payload));
    }

    deleteItem(id: number) {
        this._store.dispatch(fromActions.DELETE(id));
    }

    closeInventory(id: number) {
        this._store.dispatch(fromActions.CLOSE(id));
    }

    setSaveAndCloseToFalse() {
        this._store.dispatch(fromActions.SETCLOSETOFALSE());
    }

    loadImportFormat() {
        this._store.dispatch(fromActions.IMPORT_FORMAT());
    }

    uploadProducts(payload: ProductsUploadFormat) {
        this._store.dispatch(fromActions.IMPORT_PRODUCTS(payload));
    }

    getInventoryProducts(payload: InventoryProductModel) {
        this._store.dispatch(fromActions.LOAD_INVENTORY_PRODUCTS(payload));
    }

    updateInventoryProduct(id: number, status: string, filters?: string) {
        this._store.dispatch(fromActions.UPDATE_INVENTORY_PRODUCT(id, status, filters));
    }

    displayQRs(id: number, params?: string) {
        this._store.dispatch(fromActions.DISPLAY_QR(id, params));
    }

    scanProduct(id: number, status: string) {
        this._store.dispatch(fromActions.SCAN(id, status));
    }

    massUpdate(products_ids: number[], status: string, inventory_id: number, filters?: string) {
        this._store.dispatch(fromActions.MASS_UPDATE(products_ids, status, inventory_id, filters));
    }

    updateProductStockManagement(payload: any, inventory_id: number, filters?: string) {
        this._store.dispatch(fromActions.UPDATE_PRODUCTS_STOCK_MANAGEMENT(payload, inventory_id, filters));
    }
    setMassUpdateToFalse() {
        this._store.dispatch(fromActions.SET_MASS_UPDATE_TO_FALSE());
    }

    setImportProductsSuccessToFalse() {
        this._store.dispatch(fromActions.SET_UPLOAD_SUCCESS_TO_FALSE());
    }
    loadProduct(id: number) {
        this._store.dispatch(fromActions.PRODUCT(id));
    }

}