import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { AppModel } from "src/app/app.model";
import { UserStatusesModel } from "src/app/modules/accounts/models/account-statuses.model";
import { AccountModel } from "src/app/modules/accounts/models/account.model";
import { RoleModel } from "src/app/modules/roles/models/role.model";
import { StockManagementsModel } from "../modules/stock-managements/models/stock-management.model";

export interface AppState extends EntityState<AppModel> {
    isAuthenticated: boolean | undefined;
    lang: string;
    loggedUser: {
        account: AccountModel | undefined;
        error: string | null;
        loading: boolean;
    },
    lastScannedProductId: number;
    dropdownValues: {
        roles: {
            list: Partial<RoleModel[]>;
            error: string | null;
        };
        accounts: {
            list: Partial<AccountModel[]>;
            error: string | null;
        };
        userStatuses: {
            list: UserStatusesModel[];
            error: string | null;
        };
        stockManagements: {
            list: StockManagementsModel[];
            error: string | null;
        };
        inventories: {
            list: StockManagementsModel[];
            error: string | null;
        };
    }
}

export const adapter: EntityAdapter<AppModel> = createEntityAdapter<AppModel>();

export const appInitialState: AppState = adapter.getInitialState({
    isAuthenticated: undefined,
    lang: "ro",
    loggedUser: {
        account: null,
        error: null,
        loading: false,
    },
    lastScannedProductId: null,
    dropdownValues: {
        roles: {
            list: null,
            error: null
        },
        accounts: {
            list: null,
            error: null
        },
        userStatuses: {
            list: undefined,
            error: null,
        },
        stockManagements: {
            list: undefined,
            error: null,
        },
        inventories: {
            list: undefined,
            error: null,
        },
    }
});