import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { CURRENCY } from 'src/app/shared/constants';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { InvoiceTabsEnum } from '../../enums/invoice-tabs.enum';
import { InvoicesService } from '../../services/invoices.service';
import { InvoicesFacade } from '../../store/invoices.facade';

@Component({
    selector: 'app-invoices-modal',
    templateUrl: './invoices-modal.component.html',
    styleUrls: ['./invoices-modal.component.css']
})
export class InvoicesModalComponent extends BaseModalComponent implements OnInit {

    public InvoiceTabsEnum = InvoiceTabsEnum;
    public selectedTab = InvoiceTabsEnum.INVOICE_GENERAL_INFO;
    public selectedInvoice = this._invoiceFacade.invoice$;
    public PermissionsEnum = PermissionsEnum;
    public currency = CURRENCY;

    constructor(
        public dialogRef: MatDialogRef<InvoicesModalComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public invoicesService: InvoicesService,
        private _invoiceFacade: InvoicesFacade
    ) {
        super(dialogRef, invoicesService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._data.id && this._invoiceFacade.loadItem(this._data.id)
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this._invoiceFacade.cleanItem();
    }

    selectTab(selected: InvoiceTabsEnum) {
        this.selectedTab = selected;
    }
}
