import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { RequestMethodsEnum } from 'src/app/shared/enums/RequestMethods.enum';
import { ApiRequestService, apiEndpoints } from '../../../shared/services/api-request.service';
import { BaseService } from '../../../shared/services/base.service';
import { FormErrorService } from '../../../shared/services/form-error.service';
import { BrandInvoiceProfileModel, InvoiceSerieModel } from '../models/brand-invoice-profile.model';

@Injectable({
    providedIn: 'root'
})

export class BrandInvoiceProfileService extends BaseService {

    itemsList$: BehaviorSubject<BrandInvoiceProfileModel[]> = new BehaviorSubject(undefined);
    selectedItem$: BehaviorSubject<BrandInvoiceProfileModel> = new BehaviorSubject(undefined);
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    invoiceSeriesList$: BehaviorSubject<InvoiceSerieModel[]> = new BehaviorSubject(undefined);
    defaultBrandInvoiceProfile$: Subject<BrandInvoiceProfileModel> = new Subject();

    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService) {
        super(_formErrorService);
    }

    createBrandInvoiceProfile(brandInvoiceProfile: BrandInvoiceProfileModel) {
        this.apiRequestService.submitPostRequest(apiEndpoints.brandInvoiceProfiles, brandInvoiceProfile).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data)
                this.refreshListAfterChange(apiResponse.data, RequestMethodsEnum.Create, this.itemsList$);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on create brand invoice profile', error)
        })
    }

    getBrandInvoiceProfiles() {
        this.apiRequestService.submitGetRequest(apiEndpoints.brandInvoiceProfiles).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data);
                this.defaultBrandInvoiceProfile$.next(apiResponse.data.find(invoiceProfile => invoiceProfile.is_default_profile));
            },
            error: error => this.reportError('Error on get brand invoice profiles', error)
        })
    }

    getBrandInvoiceProfile(id: number) {
        this.apiRequestService.submitGetRequest(apiEndpoints.brandInvoiceProfiles + `/${id}`).subscribe({
            next: apiResponse => this.selectedItem$.next(apiResponse.data),
            error: error => this.reportError('Error on get brand invoice profile', error)
        })
    }

    updateBrandInvoiceProfile(brandInvoiceProfile: BrandInvoiceProfileModel) {
        this.apiRequestService.submitPutRequest(apiEndpoints.brandInvoiceProfiles + '/' + brandInvoiceProfile.id, brandInvoiceProfile).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data)
                this.refreshListAfterChange(apiResponse.data, RequestMethodsEnum.Update, this.itemsList$);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on update brand invoice profile', error)
        })
    }

    deleteBrandInvoiceProfile(brandInvoiceProfile: BrandInvoiceProfileModel) {
        this.apiRequestService.submitDeleteRequest(apiEndpoints.brandInvoiceProfiles + `/${brandInvoiceProfile.id}`).subscribe({
            next: () => this.refreshListAfterChange(brandInvoiceProfile, RequestMethodsEnum.Delete, this.itemsList$),
            error: error => this.reportError('Error on delete brand invoice profile', error)
        })
    }

    setDefaultBrandInvoiceProfile(brandInvoiceProfile: BrandInvoiceProfileModel) {
        this.apiRequestService.submitPutRequest(apiEndpoints.defaultBrandInvoiceProfiles + `/${brandInvoiceProfile.id}`, null).subscribe({
            next: (apiResponse) => {
                this.updateDefaultInvoiceProfile(apiResponse.data.brand_invoice_profile_id);
                this.refreshListAfterChange(apiResponse.data, RequestMethodsEnum.Update, this.itemsList$);
            },
            error: error => this.reportError('Error on set default brand invoice profile', error)
        })
    }

    // ===================== Invoice Series ===========================

    createInvoiceSerie(addInvoiceSerie: InvoiceSerieModel, invoiceProfileId: number) {
        this.isLoading$.next(true);
        this.apiRequestService.submitPostRequest(apiEndpoints.invoiceSeries, addInvoiceSerie).subscribe({
            next: apiResponse => {
                this.getInvoiceSeries(invoiceProfileId);
            },
            error: error => this.reportError('Error on create invoice serie', error)
        })
    }

    getInvoiceSeries(invoiceProfileId?: number) {
        this.apiRequestService.submitGetRequest(invoiceProfileId ? apiEndpoints.invoiceSeries + `/${invoiceProfileId}` : apiEndpoints.invoiceSeries).subscribe({
            next: apiResponse => {
                this.invoiceSeriesList$.next(apiResponse.data);
                this.isLoading$.next(false);
            },
            error: error => this.reportError('Error on get invoice series list', error)
        });
    }

    activateInvoiceSerie(invoiceSerieId: number, invoiceProfileId: number) {
        this.isLoading$.next(true);
        this.apiRequestService.submitPutRequest(apiEndpoints.invoiceSeries + '/activate/' + invoiceSerieId, null).subscribe({
            next: apiResponse => {
                this.getInvoiceSeries(invoiceProfileId);
            },
            error: error => this.reportError('Error on get invoice series list', error)
        });
    }



    refreshListAfterChange(serverResponse: any, type: RequestMethodsEnum, list$: BehaviorSubject<any>) {
        const newList = [...list$.value];
        const index = newList.findIndex(item => item.id === serverResponse?.id);

        switch (type) {
            case RequestMethodsEnum.Create:
                newList.push(serverResponse);
                break;

            case RequestMethodsEnum.Update:
                newList[index] = serverResponse;
                break;

            case RequestMethodsEnum.Delete:
                newList.splice(index, 1);
                break;
        }

        list$.next(newList);
    }

    updateDefaultInvoiceProfile(brandInvoiceProfileId: number) {
        const invoiceProfilesList = [...this.itemsList$.value];
        const index = invoiceProfilesList.findIndex(invoiceProfile => invoiceProfile.id === brandInvoiceProfileId);

        invoiceProfilesList.forEach(profile => profile.is_default_profile = false);
        invoiceProfilesList[index].is_default_profile = true;
    }
}
