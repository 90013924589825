import { TranslateService } from '@ngx-translate/core';
import { UserStatusesModel } from '../../modules/accounts/models/account-statuses.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'UserStatusesPipe' })

export class UserStatusesPipe implements PipeTransform {
    constructor(private _translateService: TranslateService) { }

    transform(value: string, element: HTMLElement, userStatuses: UserStatusesModel[], row: any) {

        const currentUserStatus = userStatuses?.find(userStatus => userStatus.value === value);

        if (currentUserStatus || (value === 'locked' && row.username)) {
            const toRemoveClasses = [];

            element.classList.forEach(item => {
                item.includes('user') && toRemoveClasses.push(item);
            });

            toRemoveClasses.forEach(item => element.classList.remove(item));

            element.classList.add('badge', 'user-statuses-badge', `${currentUserStatus?.value || value}-user`);
            if (element.parentElement) {
                element.parentElement.style.textAlign = 'center';
            }

            return currentUserStatus?.title || this._translateService.instant(value);
        }
        return value;
    }
}
