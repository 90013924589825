import { Component, OnInit } from '@angular/core';
import { InventoryFacade } from '../../store/inventory.facade';
@Component({
    selector: 'app-products-upload-successfull',
    templateUrl: './products-upload-successfull.component.html',
    styleUrls: ['./products-upload-successfull.component.css']
})
export class ProductsUploadSuccessfullComponent implements OnInit {
    public importedProducts = this._inventoryFacade.importedProducts$;
    constructor(private _inventoryFacade: InventoryFacade) { }

    ngOnInit() { }

    backToUpload() {
        this._inventoryFacade.setImportProductsSuccessToFalse();
    }
}
