
export function calculateTotal(list: any, type: string, property?: string): string {
    let total = 0;
    const filteredList = property ? list?.filter(item => item[property]) : list;

    filteredList?.forEach(item => {
        if (property && type) {
            total += isNumber(item[property][type]) ? item[property][type] : parseFloat(item[property][type]?.replace(/,/g, ''));
        } else if (property) {
            total += isNumber(item[property]) ? item[property] : parseFloat(item[property]?.replace(/,/g, ''));
        } else {
            total += isNumber(item.value) ? item[type] : parseFloat(item.value?.replace(/,/g, ''));
        }
    });

    return total.toFixed(2);
}


export function isNumber(value): boolean {
    return typeof value === 'number' && isFinite(value);
}
