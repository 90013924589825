import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { isMobile } from '../utils/responsive.utils';
import { ApiResponse } from './api-request.service';


@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(private _dialog: MatDialog) { }

    public openModal(entry: any | null, modalComponent: ComponentType<any>, height?: string, width?: string) {
        return this._dialog.open(modalComponent, {
            width: width ? width : (isMobile() ? '100vw' : '80vw'),
            maxWidth: isMobile() ? '100vw' : '80vw',
            height: height ? height : '100vh',
            data: entry ? entry : null,
            disableClose: false
        });
    }

    public closeModal() {
        return this._dialog.closeAll()
    }

    public closeModalOnSuccess(response: any) {
        console.log(response);
    }
}
