<div class="row">
    <div *ngFor="let permission of permissions" class="col-md-12">
        <ng-container>
            <mat-checkbox id="{{permission.name}}" [checked]="permission.allowed" (change)="changePermission(permission)">{{ permission.shortName }}</mat-checkbox>
        </ng-container>
    </div>
    <div class="col-md-12">
        <mat-checkbox (change)="checkAll()" [checked]="areAllChecked">
            <span *ngIf="areAllChecked; else unchecked" class="select-all-checkbox">{{ "DeselectAll" | translate }}</span>
            <ng-template #unchecked>
                <span class="select-all-checkbox">{{ "SelectAll" | translate }}</span>
            </ng-template>
        </mat-checkbox>
    </div>
</div>