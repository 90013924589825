export enum ORSEnum {
    FINISHED = 'Finished(f)',
    WORKING = 'Working',

    PAID = 'Paid',
    UNPAID = 'Unpaid',

    WITH_INVOICE = 'WithInvoice',
    WITHOUT_INVOICE = 'WithoutInvoice',

    DONE_ICON = 'done',
    PAID_ICON = 'paid',
    INVOICE_ICON = 'receipt_long',

    SUCCESS = '#438e46',
    DANGER = '#da271a',
}