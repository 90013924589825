import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppFacade } from 'src/app/store/app.facade';
import { ApiLoginResponseModel } from '../models/login-response.model';
import { ResetPasswordModel } from '../models/reset-password.model';
import { ApiRequestService, ErrorResponse, apiEndpoints } from './api-request.service';
import { CleanupService } from './cleanup.service';
import { AUTH_TOKEN, CURRENT_USER, LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public errorHandler$ = new Subject<string>();

    constructor(private _apiRequestService: ApiRequestService,
        private _localStorageService: LocalStorageService,
        private _cleanupService: CleanupService,
        private _appFacade: AppFacade,
        private _router: Router, private _route: ActivatedRoute) { }

    login(username: string, password: string) {
        this._apiRequestService.submitPostRequest(apiEndpoints.login, { username, password }).subscribe(
            {
                next: response => this._handleLoginResponse(<ApiLoginResponseModel>response),
                error: error => {
                    this._reportError(<ErrorResponse>error.error);
                }
            });
    }

    sendEmailResetLink(email: string) {
        this._apiRequestService.submitPostRequest(apiEndpoints.emailResetLink, { email }).subscribe(
            {
                next: () => { },
                error: error => this._reportError(<ErrorResponse>error.error)
            });
    }

    resetPassword(payload: ResetPasswordModel) {
        this._apiRequestService.submitPostRequest(apiEndpoints.resetPassword, payload).subscribe(
            {
                next: () => { },
                error: error => this._reportError(<ErrorResponse>error.error)
            });
    }

    logout() {
        this._apiRequestService.submitPostRequest(apiEndpoints.logout, null).subscribe(
            {
                next: () => this._handleLogoutResponse(),
                error: error => this._reportError(error.error)
            });
    }

    private _handleLoginResponse(response: ApiLoginResponseModel) {
        this.errorHandler$.next(undefined);
        this._appFacade.setLoggedUser(response.data.user);

        this._localStorageService.setValue(AUTH_TOKEN, response.data.token);
        this._localStorageService.setValue(CURRENT_USER, JSON.stringify(response.data.user));

        const lastVisitedUrl: string = localStorage.getItem('last_visited_url');
        this._localStorageService.deleteValue('last_visited_url');
        if (lastVisitedUrl) {
            this._router.navigateByUrl(lastVisitedUrl);
        } else {
            this._router.navigate(['/dashboard']);
        }
    }

    private _handleLogoutResponse() {
        this._appFacade.logout();
        this._localStorageService.deleteValue(AUTH_TOKEN);
        this._localStorageService.deleteValue(CURRENT_USER);
        localStorage.removeItem('last_visited_url');
        this._cleanupService.resetAllSubjects();
        this._router.navigate(['/login']);
    }

    private _reportError(response: ErrorResponse) {
        this.errorHandler$.next(response.message);
    }
}
