import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { AccountModel } from "src/app/modules/accounts/models/account.model";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";

export interface AccountsState extends EntityState<AccountModel> {
    error: string | null;
    loading: boolean;
    pagination: Omit<PaginationModel, 'data'>;
    filters: ApplyFiltersModel;

    selectedAccount: {
        account: AccountModel | undefined;
        error: string | null;
        loading: boolean;
        saveAndClose: boolean;
    }
}

export const adapter: EntityAdapter<AccountModel> = createEntityAdapter<AccountModel>();

export const accountsInitialState: AccountsState = adapter.getInitialState({
    ids: [],
    entities: {},
    pagination: null,
    error: null,
    loading: false,
    filters: undefined,

    selectedAccount: {
        account: null,
        error: null,
        loading: false,
        saveAndClose: false
    }
});