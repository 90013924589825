import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionsModel } from '../../models/permissions.model';

@Component({
  selector: 'app-roles-check-all',
  templateUrl: './roles-check-all.component.html',
  styleUrls: ['./roles-check-all.component.css']
})
export class RolesCheckAllComponent implements OnInit {

  public areAllChecked: boolean = true;

  @Input() permissions: any;
  @Output() permissionsChange: EventEmitter<PermissionsModel[]> = new EventEmitter<PermissionsModel[]>();

  constructor() { }

  ngOnInit(): void {
    this.checkIfIsAllPermissionsChecked();
  }

  checkIfIsAllPermissionsChecked() {
    this.areAllChecked = true;
    this.permissions.forEach(permission => {
      if (!permission.allowed) {
        this.areAllChecked = false;
      }
    });
  }

  checkAll() {
    this.areAllChecked = !this.areAllChecked;
    this.permissions.forEach(permission => {
      permission.allowed = this.areAllChecked;
    });
    this.permissionsChange.emit(this.permissions);
  }

  changePermission(permission) {
    permission.allowed = !permission.allowed;
    this.checkIfIsAllPermissionsChecked();
    this.permissionsChange.emit(this.permissions);
  }
}
