import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LS_KEY_LANG, LocalStorageService } from './local-storage.service';
import { AppFacade } from 'src/app/store/app.facade';

@Injectable({
    providedIn: 'root'
})
export class LangService {


    constructor(
        private translate: TranslateService,
        private _localStorageService: LocalStorageService,
        private _appFacade: AppFacade
    ) {
        this.translate.addLangs(['ro', 'en']);
        let lang = this._localStorageService.getValue(LS_KEY_LANG);
        lang && this._appFacade.setLang(lang);
        let lang$ = _appFacade.langValue$;
        lang$.subscribe(l => lang = l)

        this.translate.setDefaultLang(lang);
    }


    setLang(lang: string) {
        const avlbLangs = this.translate.getLangs();
        if (avlbLangs.indexOf(lang) === -1) {
            console.error('Unavailable language:' + lang);
            return;
        }

        this.translate.use(lang);
        this._localStorageService.setValue(LS_KEY_LANG, lang);
        setTimeout(() => {
            this._appFacade.setLang(lang);
        }, 50)
    }
}
