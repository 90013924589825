import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, filter, map, of, switchMap, take, tap } from "rxjs";
import { UserStatusesModel } from "src/app/modules/accounts/models/account-statuses.model";
import { AccountModel } from "src/app/modules/accounts/models/account.model";
import { AccountsService } from "src/app/modules/accounts/services/accounts.service";
import { AccountsFacade } from "src/app/modules/accounts/store/accounts.facade";
import { RoleModel } from "src/app/modules/roles/models/role.model";
import { AppInitResourcesService } from "src/app/shared/services/app-init-resources.service";
import * as fromActions from "./app.actions";
import { StockManagementsModel } from "../modules/stock-managements/models/stock-management.model";
import { InventoryModel } from "../modules/inventories/models/inventory.model";

@Injectable()
export class AppEffects {
    constructor(
        private _actions$: Actions,
        private _accountsService: AccountsService,
        private _accountsFacade: AccountsFacade,
        private _appInitResourcesService: AppInitResourcesService,
    ) { }

    public loadLoggedUser$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_LOGGED_USER),
        switchMap(() =>
            this._accountsService.getLoggedUser$().pipe(
                map((res: AccountModel) => fromActions.LOAD_LOGGED_USER_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_LOGGED_USER_FAILURE(error)))
            )
        )
    ));

    public updateLoggedUser$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.UPDATE_LOGGED_USER),
        switchMap(action =>
            this._accountsService.updateLoggedUser$(action.payload).pipe(
                map((res) => fromActions.UPDATE_LOGGED_USER_SUCCESS(res)),
                tap(() => {
                    this._accountsService.onSaveSuccess$.next(true);
                    this._accountsFacade.accountsFilters$
                        .pipe(filter(filters => !!filters), take(1))
                        .subscribe(filters => this._accountsFacade.loadList(filters));
                }),
                catchError((error: HttpErrorResponse) => {
                    this._accountsService.reportError('Error on update logged user', error);
                    return of(fromActions.UPDATE_LOGGED_USER_FAILURE(error));
                })
            )
        )
    ));

    public loadUserStatuses$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_USER_STATUSES),
        switchMap(() =>
            this._appInitResourcesService.getUserStatuses$().pipe(
                map((res: UserStatusesModel[]) => fromActions.LOAD_USER_STATUSES_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_USER_STATUSES_FAILURE(error)))
            )
        )
    ));

    public loadRolesDropdownValues$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_ROLES_DROPDOWN_VALUES),
        switchMap(() =>
            this._appInitResourcesService.getRolesDropdownValues$().pipe(
                map((res: Partial<RoleModel[]>) => fromActions.LOAD_ROLES_DROPDOWN_VALUES_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_ROLES_DROPDOWN_VALUES_FAILURE(error)))
            )
        )
    ));

    public loadAccountDropdownValues$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_ACCOUNTS_DROPDOWN_VALUES),
        switchMap(() =>
            this._appInitResourcesService.getAccountsDropdownValues$().pipe(
                map((res: Partial<AccountModel[]>) => fromActions.LOAD_ACCOUNTS_DROPDOWN_VALUES_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_ACCOUNTS_DROPDOWN_VALUES_FAILURE(error)))
            )
        )
    ));

    public loadStockManagementsDropdownValues$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_STOCK_MANAGEMENTS_DROPDOWN_VALUES),
        switchMap(() =>
            this._appInitResourcesService.getStockManagementDropdownValues$().pipe(
                map((res: Partial<StockManagementsModel[]>) => fromActions.LOAD_STOCK_MANAGEMENTS_DROPDOWN_VALUES_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_STOCK_MANAGEMENTS_DROPDOWN_VALUES_FAILURE(error)))
            )
        )
    ));
    public loadInventoriesDropdownValues$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_INVENTORIES_DROPDOWN_VALUES),
        switchMap((action) =>
            this._appInitResourcesService.getInventoryDropdownValues$(action.filters).pipe(
                map((res: Partial<InventoryModel[]>) => fromActions.LOAD_INVENTORIES_DROPDOWN_VALUES_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_INVENTORIES_DROPDOWN_VALUES_FAILURE(error)))
            )
        )
    ));
}