import { Directive, Input, ViewContainerRef, ElementRef, ComponentRef } from '@angular/core';
import { BadgeComponent } from '../components/badge/badge.component';

@Directive({
    selector: '[appCreateBadge]'
})
export class CreateBadgeDirective {
    private _componentRef: ComponentRef<BadgeComponent>;
    public possibleFields = ['is_default_profile'];

    @Input() appCreateBadge: any;
    @Input() fieldName: string;
    @Input() message: string;
    @Input() textColor: string;
    @Input() badgeColor: string;
    @Input() set badgeValue(value: string) {
        this.badgeSetup(value);
    }
    constructor(
        private _viewContainerRef: ViewContainerRef,
        private _elementRef: ElementRef<HTMLElement>) { }

    badgeSetup(value: string) {
        const foundField = !!this.possibleFields.find(field => field === this.fieldName);

        if (!value) {
            this._componentRef?.destroy();
            return;
        }
        foundField ? this.createComponent() : this.dispayInfoText(value);
    }

    createComponent() {
        this._componentRef = this._viewContainerRef.createComponent(BadgeComponent);
        this._componentRef.instance.message = 'Default';
        this._componentRef.instance.textColor = 'app-text-color';
        this._componentRef.instance.badgeColor = 'primary';
    }

    dispayInfoText(value: string) {
        this._elementRef.nativeElement.innerHTML = value;
        this._elementRef.nativeElement.style.marginRight = '.5rem';
        this._elementRef.nativeElement.parentElement.classList.add('d-flex', 'align-items-center');
    }
}
