import { FormGroup } from "@angular/forms";
import * as moment from "moment";
import { Observable, filter, take } from "rxjs";
import { AccountModel } from "src/app/modules/accounts/models/account.model";

export function createFileName(title: string, form: FormGroup, usersList: Observable<AccountModel[]>): string {
    const exportName = title;
    let foundUser: AccountModel;
    usersList.pipe(filter(list => !!list), take(1)).subscribe(list => foundUser = list.find(u => u.id === form.controls.user_id.value));

    const userName = foundUser ? ` ${foundUser?.last_name} ${foundUser?.first_name}` : '';
    const interval = ` (${moment(form.controls.start_date.value).format('DD.MM.YYYY')} - ${moment(form.controls.end_date.value).format('DD.MM.YYYY')})`;
    return `${exportName}${userName}${interval}`;
}