import { Injectable, signal } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { AppFacade } from 'src/app/store/app.facade';

@Injectable({
    providedIn: 'root'
})
@UntilDestroy()
export class AuthGuard {
    public lastRoute = signal(undefined);
    constructor(private _appFacade: AppFacade, private _router: Router) {
        this._router.events
            .pipe(
                filter((evt: any) => evt instanceof NavigationEnd),
                pairwise(),
                untilDestroyed(this)
            )
            .subscribe((events: NavigationEnd[]) => {
                if (events[0].urlAfterRedirects.includes('qr-scan')) {
                    this.lastRoute.set(events[0].urlAfterRedirects)
                    // this.lastRoute.next(events[0].urlAfterRedirects);
                }
            });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._appFacade.isAuthenticated$;
    }
}

