<div class="dropdown" *ngIf="availableActionsCounter; else noActions">
    <button href="#"
            class="{{ icon ? 'no-bg px-0' : 'btn btn-drop-custom' }}"
            data-toggle="dropdown"
            data-cy="dropdown-actions-btn"
            aria-expanded="true">
        <i class="material-icons">{{ icon ? icon : 'arrow_drop_down' }}</i>
    </button>
    <ul class="dropdown-menu dropdown-menu-right">
        <ng-container *ngFor="let action of rowPossibleActions">
            <li *ngIf="checkPermissionFromResponse(action.permissionFromResponse) || !action.permissionFromResponse"
                class="d-none" [attr.data-cy]="'cy-list-item-' + action.name.toLowerCase()"
                [class.d-block]="rowAvailableActions[action.permission] === true">
                <a (click)="tableActionClicked(rowIndex, action.type)">{{ action.name | translate }}</a>
            </li>
        </ng-container>
    </ul>
</div>

<ng-template #noActions><div class="actions-placeholder"></div></ng-template>
