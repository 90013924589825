import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, of, switchMap, tap } from "rxjs";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import * as fromActions from "./stock-management.actions";
import { StockManagementService } from "../services/stock-management.service";
import { StockManagementsModel } from "../models/stock-management.model";

@Injectable()
export class StockManagementEffects {

    constructor(private _actions$: Actions, private _stockManagementService: StockManagementService) { }

    public loadList$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_LIST),
        switchMap(action =>
            this._stockManagementService.getStockManagements$(action.payload).pipe(
                map((res: PaginationModel) => fromActions.LOAD_LIST_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_LIST_FAILURE(error)))
            )
        )
    ));

    public loadItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.LOAD_ITEM),
        switchMap(action =>
            this._stockManagementService.getStockManagement$(action.id).pipe(
                map((res: StockManagementsModel) => fromActions.LOAD_ITEM_SUCCESS(res)),
                catchError((error: HttpErrorResponse) => of(fromActions.LOAD_ITEM_FAILURE(error)))
            )
        )
    ));

    public createItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.CREATE),
        switchMap(action =>
            this._stockManagementService.createStockManagement$(action.payload).pipe(
                map((res) => fromActions.CREATE_SUCCESS(res)),
                tap(() => this._stockManagementService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._stockManagementService.reportError('Error on create stock management', error);
                    return of(fromActions.CREATE_FAILURE(error));
                })
            )
        )
    ));

    public updateItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.UPDATE),
        switchMap(action =>
            this._stockManagementService.updateStockManagement$(action.payload).pipe(
                map((res) => fromActions.UPDATE_SUCCESS(res)),
                tap(() => this._stockManagementService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._stockManagementService.reportError('Error on update stock management', error);
                    return of(fromActions.UPDATE_FAILURE(error));
                })
            )
        )
    ));

    public removeItem$ = createEffect(() => this._actions$.pipe(
        ofType(fromActions.DELETE),
        switchMap(action =>
            this._stockManagementService.removeStockManagement$(action.id).pipe(
                map((res) => fromActions.DELETE_SUCCESS(res, action.filters)),
                tap(() => this._stockManagementService.onSaveSuccess$.next(true)),
                catchError((error: HttpErrorResponse) => {
                    this._stockManagementService.reportError('Error on remove stock management picture', error);
                    return of(fromActions.DELETE_FAILURE(error));
                })
            )
        )
    ));

    public removeItemSuccessSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(fromActions.DELETE_SUCCESS),
            exhaustMap((action) => {
                return this._stockManagementService.getStockManagements$(action.filters).pipe(
                    map((res) => fromActions.LOAD_LIST_SUCCESS(res)),
                    // tap((res) => this._stockManagementService.onSaveSuccess$.next(true)),
                    catchError((error: HttpErrorResponse) => {
                        this._stockManagementService.reportError('Error on get stock managements', error);
                        return of(fromActions.LOAD_ITEM_FAILURE(error));
                    })
                )
            }),
        )
    }
    );
}