import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-dialog-footer-buttons',
    templateUrl: './dialog-footer-buttons.component.html',
    styleUrls: ['./dialog-footer-buttons.component.css']
})
export class DialogFooterButtonsComponent implements OnInit {

    @Input() disable: boolean;
    @Input() view: boolean;
    @Output() close: EventEmitter<null> = new EventEmitter();
    @Output() save: EventEmitter<null> = new EventEmitter();
    @Output() saveAndClose: EventEmitter<null> = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

}
