import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { ProductsService } from '../../services/products.service';
import { ProductsFacade } from '../../store/products.facade';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductModel } from '../../models/product.model';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppFacade } from 'src/app/store/app.facade';
import { booleanOptions$ } from 'src/app/shared/constants';
@UntilDestroy()
@Component({
    selector: 'app-product-modal',
    templateUrl: './product-modal.component.html',
    styleUrls: ['./product-modal.component.css']
})
export class ProductModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public selectedProduct$ = this._productsFacade.product$;
    public loading$ = this._productsFacade.productLoading$;
    public stockManagementList$ = this._appFacade.stockManagementsDropdownValues$;
    public booleanOptions;

    constructor(
        public dialogRef: MatDialogRef<ProductModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProductModel,
        private _productsService: ProductsService,
        private _appFacade: AppFacade,
        private _productsFacade: ProductsFacade,
        private _formBuilder: FormBuilder,) {
        super(dialogRef, _productsService);
    }

    ngOnInit(): void {
        booleanOptions$(null, null, true, false).pipe(untilDestroyed(this)).subscribe(value => this.booleanOptions = value.data);
        super.ngOnInit();
        this._appFacade.loadStockManagementsDropdownValues();
        this._productsFacade.loadItem(this.data.id);

        this.form = this._formBuilder.group({
            stock_management_id: this.data.stock_management_id,
            name: this.data.name,
            external_number: this.data.external_number,
            measurement_unit: this.data.measurement_unit || '',
            price: this.data.price || 0,
            id: this.data.id
        });
        this.selectedProduct$.pipe(filter(sm => !!sm), untilDestroyed(this)).subscribe((product: ProductModel) => {
            this.form.patchValue(product);
        });
    }

    ngOnDestroy(): void {
        this._productsFacade.cleanItem();
    }

    saveAndClose() {
        if (this.form.valid) {
            this.onSubmit();
            this._productsFacade.productSuccessResponse$.pipe(filter(res => !!res)).subscribe(data => {
                this.dialogRef.close();
                this._productsFacade.setSaveAndCloseToFalse();
            })
        } else {
            this.form.markAllAsTouched();
        }
    }
    onSubmit() {
        this._productsFacade.updateItem(this.form.value);
    }
}
