import { Directive, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { BaseService } from '../../services/base.service';


@UntilDestroy()
@Directive()
export class BaseModalComponent implements OnInit, OnDestroy {

    protected _saveAndClose = false;
    private _sub: Subscription;

    constructor(
        public dialogRef: MatDialogRef<any>,
        public baseService: BaseService
    ) { }

    ngOnInit(): void {
        this._sub = this.baseService.onSaveSuccess$.pipe(untilDestroyed(this)).subscribe(response => {
            if (!response) {
                this._saveAndClose = false;
                return;
            }
            if (this._saveAndClose === response) {
                this.dialogRef.close();
            }
        });
    }

    ngOnDestroy(): void {
        this._sub?.unsubscribe();
        this.baseService.onSaveSuccess$.next(undefined);
    }
}