import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { CURRENT_USER } from 'src/app/shared/services/local-storage.service';
import { AppFacade } from 'src/app/store/app.facade';

@UntilDestroy()
@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
    constructor(
        private _router: Router,
        private _appFacade: AppFacade,
    ) { }

    ngOnInit() {
        this._appFacade.loggedUser$.pipe(filter(user => !!user), untilDestroyed(this))
            .subscribe(user => localStorage.setItem(CURRENT_USER, JSON.stringify(user)));

        this._router.events.pipe(filter(event => event instanceof NavigationEnd), untilDestroyed(this)).subscribe((event: NavigationEnd) => {
            const body = document.getElementsByTagName('body')[0];
            const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
            if (body.classList.contains('modal-open')) {
                body.classList.remove('modal-open');
                modalBackdrop.remove();
            }
        });
    }
}