import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ApplyFiltersModel } from "src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model";
import { PaginationModel } from "src/app/shared/components/tables/regular-table/models/pagination.model";
import { InventoryModel, InventoryProductModel } from "../models/inventory.model";

export interface InventoryState extends EntityState<InventoryModel> {
    error: string | null;
    loading: boolean;
    pagination: Omit<PaginationModel, 'data'>;
    filters: ApplyFiltersModel;

    selectedInventory: {
        inventory: InventoryModel | undefined;
        error: string | null;
        loading: boolean;
        saveAndClose: boolean;
        importFormat: any;
        products: {
            products: InventoryProductModel[],
            loading: boolean,
            error: string | null;
            import: any;
            importSuccess: boolean
        }
        qrCodes: any;
        selectedInventoryProduct: {
            product: InventoryProductModel;
            loading: boolean;
            error: string | null;
        },
        massUpdateSuccessfull: boolean;
    }
}

export const adapter: EntityAdapter<InventoryModel> = createEntityAdapter<InventoryModel>();

export const inventoryInitialState: InventoryState = adapter.getInitialState({
    ids: [],
    entities: {},
    pagination: null,
    error: null,
    loading: false,
    filters: undefined,

    selectedInventory: {
        inventory: undefined,
        error: null,
        loading: false,
        saveAndClose: false,
        importFormat: null,
        products: {
            products: [],
            error: null,
            loading: false,
            import: null,
            importSuccess: false
        },
        qrCodes: undefined,
        selectedInventoryProduct: {
            loading: false,
            error: null,
            product: undefined
        },
        massUpdateSuccessfull: false,

    }
});