import { ConvertEmptyContentToLinePipe } from './convert-empty-content-to-line.pipe';
import { ModelWithSubPropertiesPipe } from './model-with-sub-properties.pipe';
import { UserStatusesPipe } from './user-statuses.pipe';
import { FormatDatePipe } from './format-date.pipe';

import { ConvertSecondsToDhmsPipe } from './convert-seconds-to-dhms.pipe';
import { DisplaySymbolPipe } from './display-symbols.pipe';
import { NgModule } from '@angular/core';
import { LabelPipe } from './label.pipe';
import { SumPipe } from './sum.pipe';
import { NoCommaPipe } from './no-comma.pipe';
import { PriceFormatingPipe } from './price-formating.pipe';
import { SplitFieldsPipe } from './split-fields.pipe';
import { DashSepareFieldsPipe } from './dash-separe-fields.pipe';
import { CustomSheetsNumberLabel } from './custom-sheets-number-label.pipe';


@NgModule({
    imports: [],

    declarations: [
        UserStatusesPipe,
        ConvertSecondsToDhmsPipe,
        FormatDatePipe,
        DisplaySymbolPipe,
        ModelWithSubPropertiesPipe,
        ConvertEmptyContentToLinePipe,
        LabelPipe,
        SumPipe,
        NoCommaPipe,
        PriceFormatingPipe,
        SplitFieldsPipe,
        DashSepareFieldsPipe,
        CustomSheetsNumberLabel
    ],
    exports: [
        UserStatusesPipe,
        ConvertSecondsToDhmsPipe,
        FormatDatePipe,
        DisplaySymbolPipe,
        ModelWithSubPropertiesPipe,
        ConvertEmptyContentToLinePipe,
        LabelPipe,
        SumPipe,
        NoCommaPipe,
        PriceFormatingPipe,
        SplitFieldsPipe,
        DashSepareFieldsPipe,
        CustomSheetsNumberLabel
    ]
})
export class PipesModule { }