export function getValueFromObject(object: any, fieldName: string): any {
const fieldsList = fieldName.split('.');
    if(object[fieldsList[0]]) {
        return fieldsList.reduce((acc, val) => acc[val], object);
    }
    return 0;
}


export function clearObject(obj): any {
    const clearedObject = {};

    for (const field in obj) {
        if (obj.hasOwnProperty(field)) {
            obj[field] && (clearedObject[field] = obj[field]);
        }
    }

    return clearedObject;
};