import { Directive, OnInit, OnDestroy } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormErrorService } from '../services/form-error.service';

@Directive({
    selector: '[appFormValidator]'
})
@UntilDestroy()
export class FormValidatorDirective implements OnInit, OnDestroy {
    private get _form() {
        return this._formGroupDirective.form;
    }

    constructor(
        private _formGroupDirective: FormGroupDirective,
        private _formErrorService: FormErrorService) { }

    ngOnInit(): void {
        this._formErrorService.reportError$.pipe(untilDestroyed(this), filter(errors => !!errors)).subscribe(errors => {
            this._formErrorService.renderServerErrors(this._form, errors);
        });
        this._formGroupDirective.ngSubmit.pipe(untilDestroyed(this)).subscribe(() => this._form.markAllAsTouched());
    }

    ngOnDestroy(): void {
        this._formErrorService.reportError$.next(undefined);
    }
}
