import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { InvoiceServiceModel, InvoiceServiceResponse } from '../models/invoice.model';

@Injectable({
    providedIn: 'root'
})

export class InvoiceServicesService extends BaseService {

    itemsList$: BehaviorSubject<InvoiceServiceResponse> = new BehaviorSubject(undefined);
    selectedInvoiceService$: BehaviorSubject<InvoiceServiceModel> = new BehaviorSubject(undefined);

    constructor(
        private apiRequestService: ApiRequestService,
        public _formErrorService: FormErrorService) {
        super(_formErrorService);
    }

    createInvoiceService(service: InvoiceServiceModel): void {
        this.isLoading$.next(true);
        this.apiRequestService.submitPostRequest(apiEndpoints.invoiceServices, service).subscribe({
            next: apiResponse => {
                this.getInvoiceServices(apiResponse.data.invoice_id);
                this.selectedInvoiceService$.next(apiResponse.data);
                this.isLoading$.next(false);
            },
            error: error => this.reportError('Error on create invoice service', error)
        });
    }

    getInvoiceServices(invoiceId: number): void {
        this.isLoading$.next(true);
        this.apiRequestService.submitGetRequest(`${apiEndpoints.invoices}/${invoiceId}/services`).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data);
                this.isLoading$.next(false);
            },
            error: error => this.reportError('Error on get invoice services list', error)
        });
    }

    updateInvoiceService(invoiceService: InvoiceServiceModel, payload: any): void {
        this.isLoading$.next(true);
        this.apiRequestService.submitPutRequest(apiEndpoints.invoiceServices + '/' + invoiceService.id, payload).subscribe({
            next: () => {
                this.getInvoiceServices(invoiceService.invoice_id);
                this.isLoading$.next(false);
            },
            error: error => this.reportError('Error on update invoice service', error)
        });
    }

    deleteInvoiceService(service: InvoiceServiceModel): void {
        this.isLoading$.next(true);
        this.apiRequestService.submitDeleteRequest(apiEndpoints.invoiceServices + '/' + service.id).subscribe({
            next: () => {
                this.getInvoiceServices(service.invoice_id);
                this.isLoading$.next(false);
            },
            error: error => this.reportError('Error on delete invoice service', error)
        });
    }
}
