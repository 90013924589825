import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-report-widget',
    templateUrl: './report-widget.component.html',
    styleUrls: ['./report-widget.component.css']
})
export class ReportWidgetComponent implements OnInit {

    @Input() title: string;
    @Input() color: string;
    @Input() service: any;
    @Input() filters: any;

    @ViewChild('reportContent') reportContent: ElementRef;

    constructor() { }

    ngOnInit(): void { }

}
