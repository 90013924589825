import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsEffects } from 'src/app/modules/settings/store/settings.effects';
import { SettingsFacade } from 'src/app/modules/settings/store/settings.facade';
import { settingsReducer } from 'src/app/modules/settings/store/settings.reducers';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { TablesModule } from 'src/app/shared/components/tables/tables.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SettingsModalComponent } from './components/settings-modal/settings-modal.component';
import { SettingsComponent } from './settings.component';

@NgModule({
    imports: [
        SharedComponentsModule,
        DirectivesModule,
        TablesModule,
        CommonModule,
        PipesModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        StoreModule.forFeature('settings', settingsReducer),
        EffectsModule.forFeature([SettingsEffects])
    ],
    declarations: [
        SettingsComponent,
        SettingsModalComponent,
    ],
    exports: [
        SettingsComponent,
        SettingsModalComponent,
    ],
    providers: [SettingsFacade]
})

export class SettingsModule { }