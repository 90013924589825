import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromState from "./invoices.state";

export const selectInvoicesState = createFeatureSelector<fromState.InvoiceState>('invoices');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = fromState.adapter.getSelectors(selectInvoicesState);

// List selectors
export const selectPagination = createSelector(
    selectInvoicesState,
    (state: fromState.InvoiceState) => state.pagination
);

export const selectLoading = createSelector(
    selectInvoicesState,
    (state: fromState.InvoiceState) => state.loading
);

export const selectError = createSelector(
    selectInvoicesState,
    (state: fromState.InvoiceState) => state.error
);

export const selectFilters = createSelector(
    selectInvoicesState,
    (state: fromState.InvoiceState) => state.filters
);

// Item selectors

export const selectItem = createSelector(
    selectInvoicesState,
    (state: fromState.InvoiceState) => state.selectedInvoice.invoice
);

export const selectItemLoading = createSelector(
    selectInvoicesState,
    (state: fromState.InvoiceState) => state.selectedInvoice.loading
);

export const selectItemError = createSelector(
    selectInvoicesState,
    (state: fromState.InvoiceState) => state.selectedInvoice.error
);
